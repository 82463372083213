import React, {useState, useEffect} from 'react';
import {Card, Button} from 'react-bootstrap';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {BASE_URL} from "../../BaseUrl";
import {serviceRankUpdate, deleteService, fetchServiceWithCategory} from "../../reducers/CategorySlice";
// import {serviceRankUpdate} from "../../reducers/ServiceSlice";
import {useDispatch} from "react-redux";
import SuccessComponent from "../../modal/SuccessComponent";
import {getZipStatus} from '../../utility/GetStatusName';
 

const   CardViewForServiceComponent=({each, editUrl, viewUrl, handleService, openArea}) => {
    const [showCardNavigation, setShowCardNavigation] = useState(false);
    // let location = useLocation();
 
     const {item,categoryId=item} = useParams();
    const dispatch = useDispatch()
    const [successModal, setSuccessModal] = useState(false);
    const navigate = useNavigate();
    
    const handleServiceRankUpdate = (serviceTextId, rank) => {
        // console.log('nur: ', serviceTextId, rank)
        if (rank && serviceTextId && item) {
            const res=dispatch(serviceRankUpdate({'attributeGroupTextId':item,'serviceTextId': serviceTextId, "rank": rank}))
 
            res.then((results) => {   
            if(!results.error){
                  dispatch(fetchServiceWithCategory(`${item}/${categoryId}`))
                
            }
        }).catch((error) => {
            console.error('Promise rejected:', error);
        });

        }
    }
    // const handleStatus = (id, status) => {
    //     console.log(id, status)
    //     setShowCardNavigation(false)
    //     // dispatch(itemRankUpdateApi({textId: id, status: status}))
    //     setSuccessModal(true)

    // }
    const closeSuccessModal = (url) => {
        setSuccessModal(false)
    };

    console.log('item : ', item, each.serviceTextId)

    return (
        <>

            <div className="card">
                <span className='link' onClick={()=>{
                    handleService(each);
                }}>
                    <img src={each.serviceImage && `${BASE_URL}${each.serviceImage}`} className='icon' alt={each.serviceTitle}/> {each.serviceTitle}
                </span> 

                <span className="fa-solid fa-ellipsis-vertical status-action-btn"
                      onClick={() => setShowCardNavigation(!showCardNavigation)}></span>
                <hr/>
                <div className={`status-text ${each.status}`}>{getZipStatus(each.areaAddStatus)}</div>
 

                <input type='number' className='qty'
                onBlur={(e) =>  handleServiceRankUpdate(each.serviceTextId, e.target.value)}
                defaultValue={each.rank}/>

                {showCardNavigation && <>
                    <div className="card-nav">

                        <div className="item edit" onClick={() => {
                            navigate(viewUrl , { state: item })
                        }}><i className="fa-solid fa-pen"></i> <strong>View</strong></div>

                        {/* <div className="item edit" onClick={() => {
                            navigate(editUrl , { state: item })
                        }}><i className="fa-solid fa-pen"></i> <strong>Edit</strong></div> */}

                        {/* /service-area/:item/zone/:id */}
                      

                        {each.areaAddStatus==="No"&&<div className="item edit" 
                        onClick={() =>  openArea(each)}
                        ><i className="fa-solid fa-pen"></i> <strong>Add Service Area</strong></div>}

                        {/* {each.areaAddStatus==="No"&&<div className="item edit" onClick={() => {
                            navigate(`/service-area/${item}/zone/${each.serviceTextId}#view` , { state: each })
                        }}><i className="fa-solid fa-pen"></i> <strong>Add Service Area</strong></div>} */}

                        {each.areaAddStatus==="Yes"&&<div className="item edit" onClick={() => {
                            navigate(`/service-area/${item}/zone/${each.serviceTextId}#view` , { state: each })
                        }}><i className="fa-solid fa-pen"></i> <strong>Edit Service Area</strong></div>}

                        <div className="item unpause" 
                            onClick={() => {
                            const approval = window.confirm("Are you sure you want to delete?")
                            if (approval === true){
                                const res =dispatch(deleteService(each.serviceTextId));
                                 res.then(results => {
                                    // window.location.reload()
                                     if(results.error){
                                        
                                     }
                                }).catch(error => {
                                    alert('error ---- false')
                                });
                                
                                                             
                            }
                            }}><i className="fa fa-trash" aria-hidden="true"></i> <strong>Delete Item </strong>
                        </div>


                        {/* <div className="item edit" onClick={() => {
                            navigate(editUrl , { state: each })
                        }}><i className="fa-solid fa-pen"></i> <strong>View Service Area</strong></div> */}
                        
                        {/* <div className="item draft" onClick={() => handleStatus(each.textId, 'draft')}><i
                            className="fa-sharp fa-solid fa-envelope-open"></i>
                            <strong>Draft</strong></div> */}
                        {/* <div className="item pause" onClick={() => handleStatus(each.textId, 'pause')}><i
                            className="fa-solid fa-pause"></i> <strong>Pause</strong></div> */}
                        {/* <div className="item unpause" onClick={() => handleStatus(each.textId, 'unpause')}><i
                            className="fa-solid fa-pause"></i> <strong>Unpause</strong>
                        </div> */}
                    </div>

                </>
                }
            </div>

            {showCardNavigation &&
                <div className="modal-bg" onClick={() => setShowCardNavigation(!showCardNavigation)}></div>}
            {successModal && <SuccessComponent close={closeSuccessModal}/>}
        </>

    );
};

export default CardViewForServiceComponent;
