import React, { useState} from 'react'
import {BASE_URL} from "../../BaseUrl";
import OrderTrackingComponent from "./OrderTrackingComponent";
import CustomerCardComponent from "../card/CustomerCardComponent";
import ProdiverCardComponent from "../card/ProdiverCardComponent";
// import {useDispatch, useSelector} from "react-redux";
// import {useParams} from "react-router-dom";
const OrderDetailsComponent = ({ order_details}) => {
    // const [activeTab, setActiveTab] = useState('profile')
    const [orderView, setOrderView] = useState(false)
    // const dispatch = useDispatch()
    // const {item} = useParams()
    // const handleClick = (e) => setActiveTab(e);
    // const handleNext = (e) => setActiveTab('category');
    // const closeOrderVIew = () => setOrderView(false);

    const workerMap = {};

    const order_data = order_details.order_data;
    const workerData = order_details.workerData;
    const payment_summary = order_details.payment_summary;
    if (workerData) {
        workerData.map((item) => workerMap[item.workerTextId] = item);
    }
    console.log(orderView)
    return (
        <>
            {order_data&&<div className="section-two-column order-details">
                {/*<div className="left-block">*/}

                <div className="left-block  flex-col justify-start items-start gap-6 inline-flex">
                    <div
                        className="w-[100%]  p-3  bg-white rounded-xl flex-col justify-start items-start inline-flex">
                        <table className="table order-table">
                            <thead>
                            <tr className='border-botton'>
                                <th colSpan="2">Service Items <span
                                    className="px-1 py-0 bg-gray-200 rounded justify-start items-center gap-2.5 text-center text-black text-xs font-normal font-['Inter']">{order_data && workerData.length}</span>
                                </th>
                                <th className="w-[160px]">Schedule</th>
                                <th>Status</th>
                                <th>Price</th>
                                {/*<th>Created At</th>*/}
                                {/*<th>Action</th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {order_data && workerData.map((order, index) => (
                                <tr key={order_data.textId} className='border-botton'>
                                    <td className="w-[150px]"><img className="rounded-lg mr-4"
                                                                  src={`${BASE_URL}${order.serviceImage}`} alt={order.serviceTitle}/></td>
                                    <td>
                                        <div
                                            className="w-[100%] gap-3">
                                            <div className="w-[100%] text-black text-sm font-medium capitalize font-['Inter'] mb-[5px]">
                                                {order.serviceTitle}
                                            </div>
                                            <div
                                                className="px-3 py-[4.50px] mb-[12px] primary-color rounded justify-center items-center gap-2.5 inline-flex">
                                                <div
                                                    className="text-white text-xs font-medium font-['Inter']">
                                                    {order.servicePlanName}
                                                </div>
                                            </div>
                                            <div
                                                className="text-stone-500 text-xs font-medium font-['Inter'] cursor-pointer"
                                                onClick={() => {
                                                    setOrderView(order);
                                                    // console.log('ddd', order)
                                                }}>
                                                View Details
                                            </div>

                                        </div>
                                    </td>

                                    <td>{order.scheduledDate}
                                        <br/> {order.scheduledStartTime}-{order.ScheduleEndtime}</td>
                                    <td>{order.serviceStatus}</td>
                                    <td>{`$${order.serviceAmount}`}</td>


                                </tr>

                            ))}
                            <tr>
                                <td colSpan='3'></td>
                                <td>Subtotal</td>
                                <td><strong>${order_details && payment_summary.subTotal}</strong></td>
                            </tr>
                            <tr>
                                <td colSpan='3'></td>
                                <td>Fees & Estimated Tax</td>
                                <td><strong>${order_details && payment_summary.totalTax}</strong></td>
                            </tr>
                            <tr>
                                <td colSpan='3'></td>
                                <td>Tip</td>
                                <td><strong>${order_details && payment_summary.total_tips}</strong></td>
                            </tr>
                            <tr>
                                <td colSpan='3'></td>
                                <th>Total</th>
                                <th><strong>${order_details && payment_summary.grandTotal}</strong></th>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="w-[100%]  p-3 px-2 bg-white flex rounded-3">
                        {order_data && workerData.map((tracking, index) => (
                            <OrderTrackingComponent data={tracking} rank={workerData.length}/>
                            ))}
                    </div>
                </div>

                <div className="right-block break-all">
                    {order_details && <CustomerCardComponent data={order_details} show={true} title={'Customer'}/>}

                    {order_data && Object.keys(workerMap).map((key, index) => (
                        <>
                        {order_details && <ProdiverCardComponent
                            data={order_details}
                            worker={workerMap[key]} 
                            show={index === 0 ? true : false}
                            title={'Service Provider'}
                            photo={workerMap[index]}
                        />}
                        </>
                    ))}


                </div>

            </div>}
        

        </>

    );
};

export default OrderDetailsComponent;
