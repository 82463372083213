import 'react-datepicker/dist/react-datepicker.css';
import React, {useEffect, useState} from "react";
import {BASE_URL} from "../../BaseUrl";
import {useDispatch, useSelector} from 'react-redux'
// import CustomDataTable from '../../components/CustomDataTable'
import { fetchReviewRatingManagementApi } from '../../reducers/TeamMemberSlice'
// import {Button, Form} from 'react-bootstrap'
import Layout from '../../components/Layout'

// 
// import DatePicker from "react-datepicker";

import RatingComponent from "../../component/ratings/RatingComponent";
// import StarRatingComponent from "../../component/ratings/StarRatingComponent";
import moment from "moment";
import ReviewsDataTable from "../../dataTables/ReviewssDataTable";
import StarRatingFromUserComponent from "../../component/ratings/StarRatingFromUserComponent";
// import {filterDataTable4Col} from "../../utility/FilterDataTable";
import ActionModelComponent from "../../component/ratings/ActionModalComponent";
// import Button from 'react-bootstrap/Button';
// import {CSVLink} from "react-csv";
import ExportFile from "../../component/utility-component/ExportFile";

const TestingFileDownload = () => {
    // const [startDate, setStartDate] = useState(new Date());
    const [activeTab, setActiveTab] = useState('Approved')
    const {teamMembers, error, loading, reviewRatingData} = useSelector((state) => state.teamMembers)
    const dispatch = useDispatch()
    // const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [query, setQuery] = useState('');
    // const [uniqueData, setUniqueData] = useState('');
    const [rowData, setRowData] = useState(false);

    // const headers = [
    //     "Services",
    //     "Reviewer",
    //     "Review",
    //     "Provider Name",
    //     "provider Name",
    //     "Date"
    // ];

    // const headers = [
    //     { label: "Name", key: "name" },
    //     { label: "Reviewer", key: "age" },
    //     { label: "Phone", key: "phone" },
    //     { label: "Email", key: "email" },
    // ];

    const handleClick = (e) => setActiveTab(e);
    let viewTableData = reviewRatingData.reviewRatingArray;
    let uniqueData = false;

    // console.log(csvData)
    // console.log(viewTableData)

    const columns = [
        {
            name: "Services",
            minWidth: '250px',
            className: 'review-column',
            sortable: true, selector: (row) => <>
                <div className="w-[100%] justify-start items-center gap-3 inline-flex">
                    <img className="w-11 h-11 my-1 rounded-lg" src={`${BASE_URL}${row.serviceImage}`} alt=''/>
                    <div className=" w-[100px] text-black text-sm font-medium font-['Inter']">{row.serviceTitle}

                    </div>
                </div>
            </>
        },
        {
            name: "Reviewer",
            minWidth: '250px',
            sortable: true,
            selector: (row) => <>
                <div className="w-[239px] justify-start items-center gap-3 inline-flex">
                    {/*<img className="w-11 h-11 rounded-[100px]" src="https://via.placeholder.com/44x44"/>*/}
                    <img className="w-11 h-11 my-1 rounded-full" src={`${BASE_URL}${row.userImage}`} alt=''/>
                    <div className="flex-col justify-center items-start inline-flex">
                        <div className="text-black text-sm font-medium font-['Inter']">{row.userName}</div>
                        <div
                            className="text-stone-500 text-xs font-normal font-['Inter'] leading-snug">hassanjahid@gmail.com
                        </div>
                    </div>
                </div>
            </>
        },
        {
            name: "Review",
            sortable: true,
            minWidth: '250px',
            selector: (row) => <>
                <div className="w-[100%] py-1 flex-col justify-start items-start gap-1 inline-flex">
                    <StarRatingFromUserComponent rating={row.rateForWorkerByEndUser}/>
                    {/*<div className="justify-start items-start gap-2 inline-flex"></div>*/}
                    <div className="self-stretch text-stone-500 text-sm font-medium font-['Inter'] leading-snug">

                        {row.commentForWorkerByEndUser}
                    </div>
                </div>
            </>
        },
        {
            name: "provider Name",
            minWidth: '200px',
            sortable: true, selector: (row) => row.providerName
        },
        {
            name: "Date",
            minWidth: '120px',
            sortable: true,
            selector: (row) => moment(new Date(row.reviewRatingDate)).format("DD MMM YYYY,  h:mm A")
        },
        {
            name: "Status",
            sortable: true,
            minWidth: '120px',
            selector: (row) => <span className={`status ${row.reviewRatingStatus}`}> {row.reviewRatingStatus}</span>
        },
        {
            name: "Action", minWidth: '10px', sortable: true, selector: (row) => <>

                <div className="text-center w-[30px]" onClick={() => setRowData(row)}>
                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </div>
                {row.orderTextId === rowData.orderTextId && <div className='relative w-[100%]'>
                    <ActionModelComponent data={row} close={setRowData}/>
                    <div className='fixed bg-transparent w-[100%] h-[100%] top-0 left-0 z-40'
                         onClick={() => setRowData('')}></div>

                </div>}
            </>
        },


    ]

    useEffect(() => {
        let res = dispatch(fetchReviewRatingManagementApi())
        res.then((results) => {
            // dispatch(breadcrumbTree({'Ratings List': ''}));
            document.title = 'Ratings Listing';
            // dispatch(breadcrumbTree([{'Service ': 'service-area'},results.payload.pageBreadcrumb]));
            // dispatch(breadcrumbTree({'Service ':'service-area', 'nur':'dddd'}));
        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
    }, [dispatch])


    return (
        <Layout component={
            <>
                {loading && <div>Loading ...</div>}
                {!teamMembers && error ? <div>Error: {error}</div> : null}
                {!loading ?
                    <>

                        <div className="w-[100%] flex-col justify-start items-start inline-flex">
                            <div className="justify-start items-start gap-7 inline-flex">
                                <div className="flex-col justify-start items-center gap-[25px] inline-flex">
                                    <div
                                        className={`text-neutral-600 text-[15px] font-medium font-['Inter'] cursor-pointer ${activeTab === 'Approved' && ' active'}`}
                                        onClick={() => handleClick('Approved')}>Published
                                    </div>

                                    <div
                                        className={`w-[87px] h-[3px] ${activeTab === 'Approved' ? ' bg-emerald-700' : 'bg-neutral-100'}`}></div>
                                </div>
                                <div className="flex-col justify-start items-center gap-[25px] inline-flex">
                                    <div className="justify-center items-start gap-2 inline-flex">
                                        <div
                                            className={`text-neutral-600 text-[15px] font-medium font-['Inter'] cursor-pointer ${activeTab === 'Pending' && ' active'}`}
                                            onClick={() => handleClick('Pending')}>Pending
                                        </div>
                                        <div
                                            className="px-[5px] py-[1.50px] bg-gray-200 rounded justify-start items-center gap-2.5 flex">
                                            {/*<div*/}
                                            {/*    className="text-center text-black text-xs font-normal font-['Inter']">4*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                    <div
                                        className={`w-[87px] h-[3px] ${activeTab === 'Pending' ? ' bg-emerald-700' : 'bg-neutral-100'}`}></div>
                                </div>
                                <div className="flex-col justify-start items-center gap-[25px] inline-flex">
                                    <div
                                        className={`text-neutral-600 text-[15px] font-medium font-['Inter'] cursor-pointer ${activeTab === 'Rejected' && ' active'}`}
                                        onClick={() => handleClick('Rejected')}>Rejected
                                    </div>
                                    <div
                                        className={`w-[87px] h-[3px] ${activeTab === 'Rejected' ? ' bg-emerald-700' : 'bg-neutral-100'}`}></div>
                                </div>
                            </div>
                            <div className="self-stretch h-[0px] border border-zinc-300"></div>
                        </div>
                        <div className="w-[100%] flex-col justify-start items-start inline-flex bg-white mt-4 mb-3">
                            {reviewRatingData && activeTab === 'Approved' &&
                                <RatingComponent ratings={reviewRatingData.approved_rating_summary}
                                                 averageRating={reviewRatingData.rating_calculation_dict.avg_approve_rating}
                                                 totalReviews={reviewRatingData.rating_calculation_dict.weekly_approved_ratings}/>}
                            {activeTab === 'Pending' &&
                                <RatingComponent ratings={reviewRatingData.pending_rating_summary}
                                                 averageRating={reviewRatingData.rating_calculation_dict.avg_pending_rating}
                                                 totalReviews={reviewRatingData.rating_calculation_dict.weekly_pending_ratings}/>}
                            {activeTab === 'Rejected' &&
                                <RatingComponent ratings={reviewRatingData.rejected_rating_summary}
                                                 averageRating={reviewRatingData.rating_calculation_dict.avg_rejected_rating}
                                                 totalReviews={reviewRatingData.rating_calculation_dict.weekly_rejected_ratings}/>}

                        </div>


                        <div className='data-table-wrapper'>
                            <div className="w-[100%] flex gap-2.5 relative mb-3">
                                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                                <div className='search-from-data-table w-[80%] bg-neutral-100 rounded-lg'>
                                    <input name="search" placeholder={'Search by Services Title or customer...'}
                                           type="text"
                                           className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                                           value={query ? query : ''}
                                           onChange={(e) => {
                                               setQuery(e.target.value)
                                           }}/>
                                </div>
                                <div
                                    className="w-[130px] absolute right-0 top  px-6 py-2 bg-neutral-100 rounded-lg justify-center items-center gap-2.5 flex">
                                    <i className="w-5 h-5 relative fa fa-download" aria-hidden="true"></i>
                                    <button className="text-neutral-600 text-base font-semibold font-['Inter']">Export
                                    </button>
                                    <ExportFile data={viewTableData} fileName={'Testing'}/>
                                    {/*<CSVLink data={jsonObject} filenam e="parents.csv" headers={headers}>*/}
                                    {/*    <button className="btn btn-primary mb-2">Export</button>*/}
                                    {/*</CSVLink>*/}

                                </div>
                            </div>

                            {reviewRatingData &&
                                <ReviewsDataTable data={uniqueData ? uniqueData : viewTableData} columns={columns}/>}
                        </div>
                    </>
                    : null}
            </>
        } top_content={
            <>

                {/*<DatePicker*/}
                {/*    showIcon*/}
                {/*    selected={selectedDate}*/}
                {/*    onChange={(date) => setSelectedDate(date)}*/}

                {/*/>*/}
                {/*<DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />*/}
                {/*<Button variant="default">*/}
                {/*    Primary button*/}
                {/*</Button>{' '}*/}
                {/*<Button variant="default">*/}
                {/*    Button*/}
                {/*</Button>*/}
            </>
        }></Layout>
    )
}

export default TestingFileDownload