const SideBarData = [
 
  {
    name: "Overview",
    link: "/dashboard",
    rank: 15,
    icon: "city-zip.png",
    child: [],
  },
  {
    name: "Service Items",
    link: "/service-items",
    rank: 1,
    icon: "serveces.png",
    child: [
      {
        name: "Add Service Items",
        link: "/service-items/add",
        rank: 2,
        icon: "approved-member.png",
      },
    ]
  },
  {
    name: "Serveces",
    link: "/services",
    rank: 1,
    icon: "serveces.png",
  },
  { name: "Area ", link: "/areas", rank: 2, icon: "area.png" },
  { name: "Category ", link: "/categories", rank: 22, icon: "category.png" },
  { name: "City", link: "/cities", rank: 3, icon: "city.png" },
  { name: "City Zip", link: "/cityzip", rank: 4, icon: "city-zip.png" },
  {
    name: "Admin Modules",
    link: "/admin/modules",
    rank: 5,
    icon: "admin-modules.png",
  },
  {
    name: "Preapprove Member",
    link: "/preapprove/team/member/list",
    rank: 6,
    icon: "preapprove-member.png",
 
  },
  //   {
  //   name: "Setting",
  //   link: "/setting",
  //   rank: 1,
  //   icon: "serveces.png",
  //   child: [
      
  //     {
  //       name: "Attribute Group",
  //       link: "/setting/attribute-group",
  //       rank: 2,
  //       icon: "approved-member.png",
  //     },
  //     {
  //       name: "Service Plan",
  //       link: "/setting/service-plan",
  //       rank: 3,
  //       icon: "approved-member.png",
  //     },
  //     {
  //       name: "Attribute",
  //       link: "/setting/attribute",
  //       rank: 4,
  //       icon: "approved-member.png",
  //     },
  //   ],
  // },
  {
 
    name: "Services",
    link: "/service",
    rank: 1,
    icon: "serveces.png",
     child: [
     
      // {
      //   name: "Attribute Service",
      //   link: "/service/attribute-group",
      //   rank: 1,
      //   icon: "approved-member.png",
      // },
      //  {
      //   name: "Category Service",
      //   link: "/service/category-service",
      //   rank: 2,
      //   icon: "approved-member.png",
      // },
    ],
  },
   {
    name: "Service Area",
    link: "/service-area",
    rank: 1,
    icon: "serveces.png",
     child: [],
 
    name: "Pending Member",
    link: "/pending/team/member/list",
    rank: 7,
    icon: "pending-member.png",
  },
  {
    name: "Approved Member",
    link: "/approved/team/member/list",
    rank: 8,
    icon: "approved-member.png",
  },
  {
    name: "Admin Module",
    link: "/admin/modules",
    rank: 9,
    icon: "approved-member.png",
  },
  {
    name: "Admin Roles",
    link: "/admin/roles",
    rank: 10,
    icon: "approved-member.png",
  },
  {
    name: "Admin Permissions",
    link: "/admin/permissions",
    rank: 11,
    icon: "approved-member.png",
  },
  {
    name: "Service Customization",
    link: "/service/customization",
    rank: 12,
    icon: "approved-member.png",
 
  },
  // { name: "Area ", link: "/areas", rank: 2, icon: "area.png", child: [] },
  // {
  //   name: "Category ",
  //   link: "/categories",
  //   rank: 3,
  //   icon: "category.png",
  //   child: [
  //     {
  //       name: "Category Attribute",
  //       link: "/categories/category-attribute",
  //       rank: 1,
  //       icon: "approved-member.png",
  //     }, 
  //   ],
  // },
  // { name: "City", link: "/cities", rank: 4, icon: "city.png", child: [] },
  // {
  //   name: "Zip Code",
  //   link: "/cityzip",
  //   rank: 5,
  //   icon: "city-zip.png",
  //   child: [],
  // },


  // {
  //   name: "Html Field Builder",
  //   link: "/servicebuilder",
  //   rank: 15,
  //   icon: "city-zip.png",
  //   child: [],
  // },
  // {
  //   name: "Admin",
  //   link: "/admin",
  //   rank: 6,
  //   icon: "admin-modules.png",
    
  //   child: [
  //     {
  //       name: "Admin Module",
  //       link: "/admin/modules",
  //       rank: 10,
  //       icon: "approved-member.png",
  //     },
  //     {
  //       name: "Admin Roles",
  //       link: "/admin/roles",
  //       rank: 11,
  //       icon: "approved-member.png",
  //     },
  //     {
  //       name: "Admin Permissions",
  //       link: "/admin/permissions",
  //       rank: 12,
  //       icon: "approved-member.png",
  //     },
  //   ],
  // },

  //  {
  //   name: "Franchise List",
  //   link: "/franchise",
  //   rank: 14,
  //   icon: "order.png",
  //   child: [],
  // },
  //   {
  //   name: "Woker List",
  //   link: "/provider",
  //   rank: 9,
  //   icon: "approved-member.png",
  //   child: [
  //     {
  //       name: "Approved Member",
  //       link: "/provider/member-approved",
  //       rank: 9,
  //       icon: "approved-member.png"},

  //     {
  //       name: "Preapprove Member",
  //       link: "/provider/member-preapprove",
  //       rank: 7,
  //       icon: "preapprove-member.png",
  //       child: [],
  //     },
  //     {
  //       name: "Pending Member",
  //       link: "/provider/member-pending",
  //       rank: 8,
  //       icon: "pending-member.png",
  //       child: [],
  //     },
  //   ],
  // },  
  

  //  {
  //   name: "End User List",
  //   link: "/end-user-list",
  //   rank: 14,
  //   icon: "order.png",
  //   child: [],
  // },{

  //   name: "Order",
  //   link: "/order",
  //   rank: 14,
  //   icon: "order.png",
  //   child: [],
  // },

  // {
  //   name: "",
  //   link: "/",
  //   rank: 15,
  //   icon: "city-zip.png",
  //   child: [],
  // },

  

  // {
  //   name: "Service Customization",
  //   link: "/service/customization",
  //   rank: 13,
  //   icon: "approved-member.png",
  //   child: [],
  // },
  // {
  //   name: "Create New Member",
  //   link: "/register",
  //   rank: 13,
  //   icon: "approved-member.png",
  //   child: [],
  // },
];

export default SideBarData;
