import {Form, Modal, Button, Image} from "react-bootstrap";
// import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
 
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState, useRef} from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {faqSchema} from "../../../schemas/faq";
import {useFormik} from "formik";
// import {createCategory, successConfirmation, updateCategory} from '../../reducers/CategorySlice'
// import {fetchServices} from "../../reducers/ServiceItemSlice";
import { updateDesignation, createNewDesignation } from "../../../reducers/IdentitySlice";
// import LoadingComponent from "../../modal/LoadingComponent";
// import ErrorComponent from "../../modal/ErrorComponent";
// import SuccessComponent from "../../modal/SuccessComponent";

// const appType=[
//     {name:'Admin',value:'Admin'}, 
//     {name:'EndUser',value:'EndUser'}, 
//     {name:'Provider',value:'Provider'}, 
// ]
// const QuestionCategory=[
//     {name:'Ordering',value:'Order Related Question'}, 
//     {name:'PRICE',value:'Service Price  Question'}, 
//     {name:'Provider',value:'Provider'}, 
// ]

const AddDesignationComponent = ({data, close}) => {

    // const [sender, setSender] = useState(false);
    // const [recever, setRecever] = useState(false);
    const dispatch = useDispatch();
    const [editorData, setEditorData] = useState();
    const [exitError, setExitError] = useState();

    
      const handleChangea = (event, editor) => {
        const details = editor.getData();
        setEditorData(details);
        setValues({...values, answer: details})
    };
    console.log(data)

    const {values, errors, handleChange, handleSubmit, setValues} = useFormik({

        initialValues: data,

        onSubmit: (values, action) => {

            const res = dispatch(data.update===true?updateDesignation(values):createNewDesignation(values));
            res.then(results => {
                console.log(results)
               if(results.error) {setExitError('Something went wrong. Please try again!'); 

               }
               else {
                close(results.payload.results); 
               }
            }).catch(error => {
                alert('error ---- false')
            });

        },
        //  validationSchema: faqSchema,

    });
    // console.log("🚀 values:", values)

     
    return (
        <> <div className="wrap-content">
            <Form onSubmit={handleSubmit} className="form-data">
                <div className="section-two-column faq-form">
                  
                        {/* <div className="w-full text-black text-xl font-medium font-['Inter']">Edit Question and Answer </div> */}
                        {/* <hr className="border" /> */}
                        
                        {exitError && <div className="alert alert-danger">
                              {exitError} 
                            </div>}

                       
                            <div className="field-row">
                                <label className="label">Designation Title</label>
                             
                            <input name="title" placeholder="Designation Title" type="text" className="border w-100 border-slate-700 p-2 rounded-md" value={values.title} onChange={handleChange} ></input>
                                 {errors.title && <p className="red">{errors.title}</p>} 
                            </div>
                       
                          
                        <div className='field-row'> 
                        <Form.Check type="switch" id="status" label="Status" name="status" defaultChecked={values.status === 'Y'? true:false} value={values.status} 
                        onChange={(e)=>{
                            setValues({...values,status:e.target.checked?'Y':'N'});
                            }}/>
                       
                        </div> 

                      
                          
                      
    

                       

                     


                    <div className="save-option">
                        {/* <button><span className="draft-icon"> <img src={uploadImgIcon} alt=""/> </span>Save as Draft  </button> */}
                        <button className="save-btn">{data.update===true?'Updated':'Save'}</button>
                        <button className="save-btn" onClick={()=>close()}>Close</button>
                    </div>

                </div>
            </Form>
        </div>
        </> 
    );
};

export default AddDesignationComponent;
