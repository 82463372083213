import {Form, Modal, Button} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {removeDuplicateRow, areaStatus, areaCounterUpgrade} from '../../utility/FilterDataTable';
 
import { storeCity, storeState,
    selectArea, zipPauseUnpause, cityPauseUnpause, statePauseUnpause,
    fetchStateListApi, fetchCityListApi, fetchZipListApi, removeCity, removeState, removeZip, fetchArea
} from "../../reducers/AreaSlice";
 
import {CityDataComponent} from './CityDataComponent';
import zone from "../../data/zone";
// import { areaSchema } from "../../schemas/area";
import {useFormik} from "formik";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import {array} from "yup";
import DynamicCheckboxes from '../DynamicCheckboxes'
import {useNavigate, useParams} from "react-router-dom";
import CurrencyComponent from '../../pages/area/component/CurrencyComponent';
import CustomDropDownComponent from '../../pages/area/component/CustomDropDownComponent';

const TestingZone = ({address, data, serviceTextId, close}) => {
    
    const dispatch = useDispatch();
    const [countryTitle, setCountryTitle] = useState('United States');
    const [selectedCountry, setSelectedCountry] = useState('US');

    // const [currentUpdatedState, setCurrentUpdatedState] = useState([]);
    // const [countState, setCountState] = useState([]);
    const [countZip, setCountZip] = useState([]);

    const [searchCountry, setSearchCountry] = useState('');
    const [searchState, setSearchState] = useState('');
    const [searchCity, setSearchCity] = useState('');
    const [searchZip, setSearchZip] = useState('');

    const [activeSearchCountry, setActiveSearchCountry] = useState(false);
    const [activeSearchState, setActiveSearchState] = useState(false);
    const [activeSearchCity, setActiveSearchCity] = useState('');
    // const [cityStatus, setCityStatus] = useState('Y');
    // const [stateStatus, setStateStatus] = useState('Y');
    // const [selectCity, setSelectCity] = useState(false);
    // const {item,id} = useParams();
    // const {loading } = useSelector((state) => state.area);
     
    const [selectZip, setSelectZip] = useState('');
    
    const [stateData, setStateData] = useState(data.stateArray);
    const [cityData, setCityData] = useState(removeDuplicateRow('cityTextId', data.cityArray));
    const [zipCodes, setZipCodes] = useState(removeDuplicateRow('zip',data.zipArray));

    const [currency, setCurrency] = useState('USD');
    const navigate = useNavigate()

    const [statePausedStatus, setStatePausedStatus] = useState('Y');
    const [cityPausedStatus, setCityPausedStatus] = useState('Y');
    const [zipPausedStatus, setZipPausedStatus] = useState('Y');
 
    const handleCountry = (e) => {
        // const p = e.target.value.split(',');
        setCountryTitle(e.name);
        setSelectedCountry(e.country_iso_code)
        setCurrency(e.currency);
 
        setActiveSearchCountry(false)
      
        // dispatch(changeCountry());
        const res = dispatch(fetchStateListApi(e.country_iso_code));
        res.then((results) => {
         
            // setStateData(data.stateArray);
            setStateData(removeDuplicateRow('textId',[...data.stateArray, ...results.payload.state_list]));
 
        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
    }

    //  const handleActiveSearchCountry = (argument) => {setActiveSearchCountry(argument) }
    //  const handleSearchCountry = (argument) => { setSearchCountry(argument)}
    const handleState = (arr) => {
        // dispatch(createStateList(e));

        const {name, value, checked} = arr[1];


        let tempState = stateData.map((each) => {
            const selectedItem = checked? each.selectedItem:0;
                return each.textId === value ? (
                    {...each, isChecked: checked, visibility:arr[2], selectedItem:selectedItem}) : each
                });
            setStateData(tempState);
            // setCurrentUpdatedState([{textId:value, status:checked}])


            if(checked){
                // const selectedStateList = []
                // selectedStateList.push(...stateData.filter(each =>
                //     each.isChecked === true
                // ).map(each => each.textId));
                
        // if (selectedStateList.length > 0) {
            const res = dispatch(fetchCityListApi({
                'countryIso2Code': selectedCountry,
                stateTextIds: [value]
            }));
            res.then((results) => { 
                const cityList = []
                results.payload.city_list.map(item => {
                    return item.cities.map(city => cityList.push({
                    ...city,
                    // stateTextId: item.stateTextIds,
                    countryIso2Code: selectedCountry
                }))})

                   
          
                setCityData(removeDuplicateRow('cityTextId', [...cityData, ...cityList]))
                // console.log(cityList)
            })
            res.catch((error) => {
                console.error('Promise rejected:', error);
            }); 
        }else{
            const currentCity = cityData.filter(item => item.stateTextId !==value)
            setCityData(currentCity)
             const res = dispatch(removeState({'stateTextId':value, 'serviceTextId':serviceTextId}));
            const currentZip = zipCodes.filter(item => item.stateTextId !==value)
            setZipCodes(currentZip)
        } 
 
    }

    // console.table(stateData)

    const handleCity = (arr) => { 

        const {name, value, checked} = arr[1];
          let tempState = cityData.map((each) =>{
            const selectedItem = checked? each.selectedItem:0;
                return each.cityTextId === value ? {...each, isChecked: checked, visibility:arr[2], selectedItem:selectedItem} : each}
            );
        setCityData(tempState);
        setStateData(areaCounterUpgrade(stateData, 'textId', arr[0]['stateTextId'], checked, {data:cityData,textId:'stateTextId'}))
//   console.log(name, value, checked)
         if(checked){
            const res = dispatch(fetchZipListApi([arr[0]]));
            res.then((results) => { 
                const zipList = []
                // console.log(results)
               
                results.payload.zip_list.map(zip => zipList.push({...zip, countryIso2Code: selectedCountry}))
                setZipCodes(removeDuplicateRow('zip', [...zipCodes, ...zipList])) 
                dispatch(storeCity(tempState)) 
                
            })
            res.catch((error) => {
                console.error('Promise rejected:', error);
            });

        }else{
            const res = dispatch(removeCity({'cityTextId':value, 'serviceTextId':serviceTextId}));
            // console.log(zipCodes)
            const currentZip = zipCodes.filter(item => item.cityTextId !==value)
            setZipCodes(currentZip)
        }

 

    }
    // console.table(cityData)
    const handleZipCodes = (arr) => {
        // setZip(e);
        // dispatch(createZipList(e))
        const {name, value, checked} = arr[1];
        // console.log(name, value, checked)
 
        if (name === "allSelectZipCodes") {
  
            let tempUser = zipCodes.map((user) => {
                return {...user, isChecked: checked};
            });
            setZipCodes(tempUser);
        } else {
            let tempState = zipCodes.map((each) =>
                each.zip === value ? {...each, isChecked: checked} : each
            );
            setZipCodes(tempState);
            setCityData(areaCounterUpgrade(cityData, 'cityTextId', arr[0]['cityTextId'] ,checked, {data:zipCodes, textId:'cityTextId'})) 
            // setStateData(areaCounterUpgrade(stateData, 'textId', arr[0]['stateTextId'], checked, {data:cityData,textId:'stateTextId'}))
            if(checked){
                setCountZip({...countZip,[arr[0].cityTextId]:countZip[arr[0].cityTextId]+1})
            }else{
                const res = dispatch(removeZip({'cityTextId':arr[0]['cityTextId'], 'zipCode':value, 'serviceTextId':serviceTextId, }));
            }
        }
            
    }



 

    // const handleSubmit = () => {
    //     // event.preventDefault();
    //     const selectedZip = []
    //     zipCodes.forEach(zip => {
    //         if (zip.isChecked) {
    //             selectedZip.push(zip);
    //         }
    //     });
    //     selectedZip.textId = id;
    //     // console.table(data)
    //     const res=dispatch(selectArea(selectedZip));
    //     res.then((results) => {
    //         navigate(-1)
    //     })
    //     res.catch((error) => {
    //         console.error('Promise rejected:', error);
    //     });
    // }

    const submitByupdate =(event, submitType)=>{
        event.preventDefault();
        const selectedZip = []
        zipCodes.forEach(zip => {
            if (zip.isChecked) {
                selectedZip.push(zip);
            }
        });
        
 console.log('post data:', selectedZip)
        const res=dispatch(selectArea({"zipList":selectedZip, "textId":serviceTextId,"submitType":submitType}));
        res.then((results) => {
            if(!results.error){
                if(close){close()}else{navigate(-1)}
                
            }
        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
    }
    const handleZipPauseUnpause =(event, status)=>{
        event.preventDefault();
 
        let data = zipCodes.filter((each) => each.isChecked );
               
        const res=dispatch(zipPauseUnpause({"serviceTextId":serviceTextId, "action_status":status, "list":data}));
        res.then((results) => {
            if(results.error){ }else{
                setZipCodes(removeDuplicateRow('zip', [...results.payload.newData,...zipCodes]))
            }
        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
         
    }

    const handleCityPauseUnpause =(event, value)=>{
        event.preventDefault();
        let cityAr = cityData.filter((each) =>  each.isChecked);
        console.log(cityAr)
        const res=dispatch(cityPauseUnpause({"serviceTextId":serviceTextId, "action_status":value, "list":cityAr}));
        res.then((results) => {
            if(results.error){ }else{ 
                let newData = results.payload.newData.map((each) => each.isChecked === true &&  {...each, status: value});
                setCityData(removeDuplicateRow('cityTextId', [...newData,...cityData])) 
                // dispatch(storeCity(cityData)) 
            }
        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
         
    }
    const handleStatePauseUnpause =(event, value)=>{
        event.preventDefault();
        let data = stateData.filter((each) => each.isChecked );
        const res=dispatch(statePauseUnpause({"serviceTextId":serviceTextId, "action_status":value, "list":data}));
        res.then((results) => {
            if(results.error){ }else{ 
                let newData = results.payload.newData.map((each) => each.isChecked === true &&  {...each, status: value});
                setStateData(removeDuplicateRow('textId', [...newData,...stateData])) 
            }
        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
         
    }
 
 

    useEffect(() => {

        setSelectZip(false)
        if(zipCodes.length>0){zipCodes.forEach(zip => {
            if (zip.isChecked) {
                setSelectZip(true)
            }
        });}
   
    }, [zipCodes])

    useEffect(() => {
        handleCountry({ name:countryTitle, currency: currency,  country_iso_code:selectedCountry})
    }, [data])

    const filterdata = stateData.filter(item => item['title']?.toLowerCase().includes(searchState.toLowerCase()))
    // const filterdata = filterdata1.filter(item => item['status']?.toLowerCase().includes(stateStatus.toLowerCase()))
    const filterCountry = address.filter(item => item['country']?.toLowerCase().includes(searchCountry.toLowerCase()))
    const filterCity = cityData.filter(item => item['cityTitle']?.toLowerCase().includes(searchCity.toLowerCase()))
    // const filterCity = filterCity1.filter(item => item['status']?.toLowerCase().includes(cityStatus.toLowerCase()))
    // const filterCity =cityData;
    // console.log(filterCity)
    const filterzip = zipCodes.filter(item => item['zip']?.toLowerCase().includes(searchZip.toLowerCase()))
    return (
        <>
      
            <div className="section-single-column area-add-form" key='test_01'>
                {close && <div className="close" onClick={()=>{close()}}><i className="fa fa-times" aria-hidden="true"></i></div>}
                {/* <strong>Service Area & Currency</strong> */}
                <div className='form-group country '  key='test_02'>
                    <label className="label">Country</label>
                    <div type="text" className="form-control w-7 custom-select" onClick={()=>{setActiveSearchCountry(true);setSearchCountry('')}}>
                        <span>{countryTitle}</span>
                        <i className={`fa fa-angle-${activeSearchCountry?'up':'down'}`} aria-hidden="true"></i>
                    </div>
 
                    <Form.Select className="form-control w-3">
                        <option key={currency} value={currency}>{currency}</option> 
                    </Form.Select> 

                   
                    
                    {activeSearchCountry && <><div className="offset-layer" onClick={()=>setActiveSearchCountry(false)}></div>
                    <div className={`drop-down-modal w-7 ${filterCountry.length>2?'scroll-y':''}`}> 
                        
                        <div className="search-box">
                            <i className="fa fa-search" aria-hidden="true"></i>
                            <input className="form-control" type="search" placeholder="Search Country" name="searchCountry"
                            onChange={(e)=>{ setSearchCountry(e.target.value);}}/>
                        </div>
                        {address.length > 0 && filterCountry.map((item, index) =>
                            <div key={item.country_iso_code} className="item">
                                <label htmlFor={`country_${item.country_iso_code}`}><span>{item.country} </span>
                                <input type="radio" name='country' value={item.country_iso_code} id={`country_${item.country_iso_code}`} checked={selectedCountry===item.country_iso_code}
                                defaultChecked={selectedCountry===item.country_iso_code}
                                    onChange={(e) => handleCountry({index:index, name:item.country, currency: item.currency,  country_iso_code:item.country_iso_code})}/> </label>
                            </div>)
                        }
                    </div></>}
                </div>
                
                <Form encType="multipart/form-data" className="form-data">

 
                    {stateData.length>0&&<div className='form-group state '>
                        <label className="label">State</label>
                        

                        <div type="text" className="form-control w-7 custom-select" onClick={()=>{setActiveSearchState(true);setSearchState('')}}>
                            <span>State</span>
                            <i className={`fa fa-angle-${activeSearchState?'up':'down'}`} aria-hidden="true"></i>
                        </div>

                        <Form.Select className="form-control w-3" onChange={(e)=>setStatePausedStatus(e.target.value)}>
                            <option key='y' value='Y'>All</option>
                            <option key='n' value='P'>Paused</option>
                        </Form.Select>  
                        {activeSearchState && <><div className="offset-layer" onClick={()=>{setActiveSearchState(false);setSearchState('')}}></div>
                         
                        <div className={`drop-down-modal w-7 ${filterdata.length>4?'scroll-y':''}`}> 
                            <div className="search-box">
                                <i className="fa fa-search" aria-hidden="true"></i>
                                <input className="form-control" type="search" placeholder="Search State" name="searchState"
                                onChange={(e)=>{ setSearchState(e.target.value);}}/>
                            </div>
                            <div className="total-count"><span>Total State Count:</span>  <span>{filterdata.length}</span></div>
                            {stateData.length > 0 && filterdata.map((item, index) =>
                                <label key={item.textId} className="item radio-btn">
                               
                                  
                                        <span className="label-name"> {item.title}</span> 
                                        <input name={item.textId} type="checkbox" className="item" value={item.textId}
                                            checked={item?.isChecked || false}
                                            onChange={(e) => handleState([item, e.target, true])}/>
                                </label>)
                            }
                        </div></>}
                    </div>}

                    <div className={`radio-area ${statePausedStatus==='P'?'pause-active':'none'}`}>
                    {stateData.length > 0 && Object.values(stateData).map((item, index) => (
                        <> 
                        {item.visibility ===true && 
                            <label key={item.textId} className={`radio-btn ${areaStatus(item)}`}> 
                             <span className="visibility-none" onClick={()=>{
                                            // filterCity[index].visibility=false;
                                            handleState([item, {name:item.textId, value:item.textId, checked:false}, false])
                                        }}><i className="fa fa-times" aria-hidden="true"></i></span>
                            
                                <input name={item.textId} type="checkbox" className="item" value={item.textId}
                                        checked={item?.isChecked || false} 
                                        onClick={(e) => handleState([item, e.target, true])}/>
                                        <span className="label-name"> {item.title} </span> 
                                </label>}
                        </>
                    ))}
                    
                        {statePausedStatus==='P' && <div className="pause-btn-wrapper">
                            <button  className="btn-submit btn" onClick={(e)=>handleStatePauseUnpause(e, 'P')}> Pause </button> 
                            <button  className="btn-submit btn" onClick={(e)=>handleStatePauseUnpause(e, 'Y')}> Unpause </button> 
                        </div>}
                    </div>

                    {cityData.length>0&&<div className='form-group state '>
                        <label className="label">Cities</label> 
                    
                        <div type="text" className="form-control w-7 custom-select" onClick={()=>{setActiveSearchCity(true); }}>
                            <span>City</span> {searchCity}
                            <i className={`fa fa-angle-${activeSearchCity?'up':'down'}`} aria-hidden="true"></i>
                        </div>

                        <Form.Select className="form-control w-3" onChange={(e)=>setCityPausedStatus(e.target.value)}>
                            <option key='y' value='Y'>All</option>
                            <option key='p' value='P'>Paused</option>
                        </Form.Select>  
                        {activeSearchCity && <><div className="offset-layer" onClick={()=>{setActiveSearchCity(false); setSearchCity('')}}></div>
                        <div className={`drop-down-modal w-7 ${filterCity.length>1?'scroll-y':''}`}>
                            
                            <div className="search-box">
                                <i className="fa fa-search" aria-hidden="true"></i>
                                <input className="form-control" type="search" placeholder="Search City"
                                onChange={(e)=>{ setSearchCity(e.target.value);}}/>
                            </div>
                            <div className="total-count"><span>Total Cites Count:</span> <span>{filterCity.length}</span></div>
                            {filterCity.length > 0 && filterCity.map((item, index) =>
                                <div key={item.cityTextId} className="item">
                                    {/* <label htmlFor={`country_${item.country_iso_code}`}><span>{item.country}</span><input type="radio" name='country' value={item.country_iso_code} id={`country_${item.country_iso_code}`}
                                        onChange={(e) => handleCountry({index:index, name:item.country, currency: item.currency })}/> </label> */}
                                    <label className="radio-btn">
                                        <span className="label-name"> {item.cityTitle} </span>
                                        <input name={item.stateTextId} type="checkbox" className="item"
                                            value={item.cityTextId}
                                            checked={item?.isChecked || false}
                                            onClick={(e) => {handleCity([item, e.target, true]);}}/>   
                                    </label>
                                    {/* filterCity[index].visibility=false; */}
                                </div>)
                            }
                        </div></>}
                    </div>}

                    <div className={`radio-area ${cityPausedStatus==='P' ? 'pause-active':'none'}`}>
                       
                      
                        {cityData.length > 0 && cityData.map((item, index) => (
                            <>
                      
                               {item.visibility ===true && <label key={item.cityTextId} className={`radio-btn ${areaStatus(item)}`}>
                                    <span className="visibility-none" onClick={()=>{
                                            // filterCity[index].visibility=false;
                                            handleCity([item, {name:item.stateTextId, value:item.cityTextId, checked:false}, false])
                                        }}><i className="fa fa-times" aria-hidden="true"></i></span>
                                    <input name={item.stateTextId} type="checkbox" className={`item `}
                                            value={item.cityTextId}
                                            checked={item?.isChecked || false}
                                            onClick={(e) => handleCity([item, e.target, true])}/>
                                    <span className="label-name">{item.cityTitle} </span>
                                </label>}
                                </>
                            )
                        )}
                        
                            
                    
                        {cityPausedStatus==='P' && <div className="pause-btn-wrapper">
                            <button  className="btn-submit btn" onClick={(e)=>handleCityPauseUnpause(e, 'P')}> Pause </button> 
                            <button  className="btn-submit btn" onClick={(e)=>handleCityPauseUnpause(e, 'Y')}> Unpause </button> 
                        </div>}
                   </div>
                   
                    {zipCodes.length > 0 &&
                        <> 
                        {cityData.length>0&&<div className='form-group state '>
                        <div className="label">Zip Codes</div>
                    
                        <div className="drop-down-modal w-7 zip-search">
                        <div className="search-box">
                                <i className="fa fa-search" aria-hidden="true"></i>
                                <input className="form-control" type="search" placeholder="Search Zip Code"
                                onChange={(e)=>{ setSearchZip(e.target.value);}}/>
                            </div>
                            </div>

                        <Form.Select className="form-control w-3" onChange={(e)=>setZipPausedStatus(e.target.value)}>
                            <option key='y' value='Y'>All</option>
                            <option key='p' value='P'>Paused</option>
                        </Form.Select> 
                    </div>}
                    
                            <label htmlFor="allSelectZipCodes" className="allSelectZipCodes"><input type="checkbox"
                                   checked={!zipCodes.some((item) => item.isChecked !== true)}
                                   name='allSelectZipCodes' id='allSelectZipCodes'
                                   onChange={(e) => handleZipCodes([0, e.target])}/> Select All Zip Codes  </label>

                            <div className={`radio-area ${zipPausedStatus==='P' ? 'pause-active':'none'}`}>
                                {filterzip.map((item, index) => (<>
                                {/* {console.log(item)} */}
                                
                                    {index<100&&
                                    <div key={item.zip} className={`radio-btn ${areaStatus(item)}`}> 
                                        {item.isChecked && <span className="visibility-none" onClick={()=>{ 
                                            handleZipCodes([item, {name:item.zip, value:item.zip, checked:false}, false])
                                        }}><i className="fa fa-times" aria-hidden="true"></i></span>}
                                        
                                        <label>

                                        <input name={item.zip} type="checkbox" className="item" value={item.zip}
                                            checked={item?.isChecked || false}
                                            onClick={(e) => handleZipCodes([item, e.target, true])}/>
                                        <span className="label-name">{item.zip} </span></label>
                                    </div>}</>
                                    )
                                )}
                            
                                {zipPausedStatus==='P' && <div className="pause-btn-wrapper">
                                    <button  className="btn-submit btn" onClick={(e)=>handleZipPauseUnpause(e, 'P')}> Pause </button> 
                                    <button  className="btn-submit btn" onClick={(e)=>handleZipPauseUnpause(e, 'Y')}> Unpause </button> 
                                </div>}
                            </div>
                         {statePausedStatus!=='P' && cityPausedStatus!=='P' && zipPausedStatus!=='P' && <>
                            {data.zipArray.length>0? <>{selectZip && <button  className="btn-submit btn" onClick={(e)=>submitByupdate(e, 'update')}> Update </button>}</>:
                            <>{selectZip && <button   className="btn-submit btn" onClick={(e)=>submitByupdate(e, 'save')}> Submit </button>}</>} </>}
                        </>
                    }
                </Form>
            </div>
        </>
    );
};

export default TestingZone;
