import React from 'react';
import {useForm, useFieldArray} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux'
import {useState} from "react";
import {updateValue, updateOption, removeOption, updateGroup, updateOptionRadioAndCheckebox} from '../../reducers/FormBuilderSlice';
import {removePunctuation} from '../../utility/StringImageIcon'
import AttributeElement from './component/AttributeElement';
import TextFieldComponent from './component/TextFieldComponent';

const OptionAddAndFromSave = ({data, index, parent}) => {
    // const [query, setQuery] = useState('');
    // const [show, setShow] = useState(false);
    // const [label, setLabel] = useState('Label Title');
    // const { register, handleSubmit, control, formState: { errors } } = useForm();


    const dispatch = useDispatch()
 

    const handleChange = (column, val) => {
        dispatch(updateValue([index, column, removePunctuation(val).replace("'","\'"), parent]))
    };
    const handeleMeasurement = (column, val) => {
        // console.log('Measurement Data : ',column, val)
        dispatch(updateValue([index, column, val, parent]))
    };
    const handleOption = (column, val, optionIndex) => {
        // console.log(index, column, val, optionIndex, parent)
        dispatch(updateOption([index, column, val, parent, optionIndex]))
    };
    const handleOptionRadioAndCheckebox = (column, val, optionIndex) => {
        console.log(index, column, val, optionIndex, parent)
        dispatch(updateOptionRadioAndCheckebox({lavel:index, attr:parent, option:optionIndex, value:val}))
        // state.dropitems[data.lavel].controls[data.attr].options[data.option].title = data.value;
                // let rootIndex = action.payload[0];
            // let name = action.payload[1];
            // let value = action.payload[2];
            // let childIndex = action.payload[3];
            // let optionIndex = action.payload[4];

   
    };
    const handleOptionRemove = (column, ind) => {
        dispatch(removeOption([index, column, ind, parent]))
    };
    const handleUngroupService = (column, val) => {

        dispatch(updateGroup([index, column, val, parent]))
        console.log('val', val, data.label);
    };


    return (
        <>
            <div className='editable'>
                {/* <strong className='field-label'>{data.name} ---------------</strong> */}
                {/* <div className='field-builder'> */}
                {/* {console.log('data', data, index)} || {data.index} */}
                 { data.btnName!=='labelField'&&   <TextFieldComponent data={data} index={index} handleChange={handleChange} />}
                <div className='from-group'>
                {data.btnName === 'textField' &&
                    <>
                    <label>Value</label> 
                    <input className="form-control" value={data.options} type={data.labelType}
                    onChange={(e) => handleChange('options', e.target.value)}/>
                    </>}
                {data.btnName === 'comboNumberField' &&
                    <> 
                        <div className='from-group'>
                            <label>Value</label>
                            <input className="form-control" value={data.options} type={data.type}
                                   onChange={(e) => handleChange('options', e.target.value)}/>
                        </div>
                        <div className='dfsdfsd'>
                            <label>Limit</label>
                            <input className="form-control" value={data.limit} type='number'
                                   onChange={(e) => handleChange('limit', e.target.value)}/>
                        </div>
                        </>}

                {data.btnName === 'textareaField' &&
                    <>
                          
                        <div className='from-group'>
                            <label>Value</label>
                            <textarea className="form-control" value={data.options} type={data.type}
                                      onChange={(e) => handleChange('options', e.target.value)} rows="4"
                                      cols="50"></textarea>

                        </div>
                         
                    </>}


                

                {data.btnName === 'selectField' &&
                    <> 
                        <div className='from-group'>
                        <label>Option</label>
                        {data.options.map((option, ind) => (
                            <div className='from-group option'>
                                <input className="form-control" value={option.title} type='text'
                                       onChange={(e) => handleOptionRadioAndCheckebox('options', e.target.value, ind)}/>
                                <button value={option} onClick={(e) => handleOptionRemove('options', ind)}
                                    className='remove edit-delete-btn'></button>
                                </div>

                        ))}
                        </div>
                        <div className='from-group'>
                            <button onClick={(e) => handleOption('options', {title:'New Option',isActive:null}, data.options.length)}
                                    className='add-option'> add new option
                            </button>
                            <button onClick={(e) => handleOption('options', {title:'New Option',isActive:null}, data.options.length)}
                                    className='add edit-delete-btn'></button>
                        </div>
                        </>}

                {data.btnName === 'checkboxField' &&
                    <>
                     


                        <div className='from-group'>
                        <label>Option</label>
                        {data.options.map((option, ind) => (
                            <div className='from-group option'>
                                <input className="form-control" value={option.title} type='text'
                                       onChange={(e) => handleOptionRadioAndCheckebox('options', e.target.value, ind)}/>
                                <button value={option} onClick={(e) => handleOptionRemove('options', ind)}
                                        className='remove edit-delete-btn'></button>
                            </div>

                        ))}
                        </div>
                        <div className='from-group'>
                            <button onClick={(e) => handleOption('options',  {title:'New Option',isActive:null}, data.options.length)}
                                    className='add-option'> add new option </button>
                            <button onClick={(e) => handleOption('options',  {title:'New Option',isActive:null}, data.options.length)}
                                    className='add edit-delete-btn'></button>
                        </div>
                        <hr />
                         
                    </>}

                {data.btnName === 'radioField' &&
                    <>
                   

                        <label>Option</label>
                        {data.options.map((option, ind) => (
                            <div className='from-group option'>
                                <input className="form-control" value={option.title} type='text'
                                       onChange={(e) => handleOptionRadioAndCheckebox('options', e.target.value, ind)}/>
                                <button value={option} onClick={(e) => handleOptionRemove('options', ind)}
                                        className='remove edit-delete-btn'></button>

                            </div>

                        ))}
                        <div className='from-group'>
                            <button onClick={(e) => handleOption('options', {title:'New Option',isActive:null}, data.options.length)}
                                    className='add-option'> add new option </button>
                            <button onClick={(e) => handleOption('options', {title:'New Option',isActive:null}, data.options.length)}
                                    className='add edit-delete-btn'></button>
                        </div>
                        <hr />
                         
                        

                    </>}

                {data.btnName !== 'textareaField' && data.btnName!=='labelField'&& <>  
                    <AttributeElement data={data} index={`${parent}_${index}`} handleChange={handleChange} handleUngroupService={handleUngroupService} handeleMeasurement={handeleMeasurement}/>
                    </>}
                    
 </div>
                 
            </div>
        </>
    );
}

export default OptionAddAndFromSave;
