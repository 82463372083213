import React from 'react'
// import {BASE_URL} from "../../BaseUrl";
const BundleServiceAttribute = ({data}) => {

    console.log(data)
    return (
        <div class="bundle-service">
            <div class="bundle-header">
                <h2>Bundle Service</h2>
                <span class="package-type">Fixed Package</span>
            </div>
            <div class="bundle-card">
                <div class="bundle-title">
                    <h3>Bundle</h3>
                    <span class="check-mark"><img src='/images/double-tick.svg' alt='' /></span>
                   
                </div>
                <div class="options">
                    {data && data.map((item, index) => (
                        <p key={item.title.replace(" ", "_")}><img class="icon" src='/images/square-ring.png' alt='' /> {item.title}: <strong>{item.options}</strong></p>
                        ))} 
                </div>
            </div>
        </div>

    );
};

export default BundleServiceAttribute;