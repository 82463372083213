import { Link, NavLink } from "react-router-dom";
import logo from "../assets/images/logo.png";
import category from "../assets/images/category.png";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {pageTitle} from "../reducers/DashboardSlice";
import {fetchServiceItem} from "../reducers/CategorySlice";
import Layout from './Layout';

export default function NotFound() {
    const {title} = useSelector((state) => state.localData)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(pageTitle({"title":'404 Not Found | Help Abode Admin Panel',"link":'not-found'}))
    }, [])
    return (<>
         <Layout page_title={'404 Page Not Found'} breadcrumb={[{"name":'Page Not Found',"link":''}]}
            pageType={'item'}
             component={
                <div className="not-found"> 
                    <div className="middle">
                        <h1>404</h1>
                        <h1>Page Not Found</h1>
                        <hr />
                        <p> Sorry, the page you are looking for does not exist. </p>
                    </div>
                    {/* <div className="bottomleft"> </div> */}
                </div>
                     
            }></Layout>
         {/* <div className="container"> */}
            {/* <div className="left-side-bar">
                <div className="logo">
                    <Link to="/dashboard" className="item"><img src={logo} alt="My logo" /> </Link >
                </div>
            </div> */}


            {/* <div className="wrap">
                <div className="header-section">
                    <span className="page-title">{title}</span>
                </div>
                <hr />
                <div className="content-block">
                    <div className="block">  
                    <h1>404 Not Found</h1> 
                        <p>Here are some helpful links:</p>
                        <Link to='/'>Home</Link>
                    </div>
                </div>
            </div> */}
        {/*  </div> */}
         </>
    )
}