//import './styles.css';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import React, {useState, useEffect} from "react";
import Form from 'react-bootstrap/Form';
import {createUser} from '../../reducers/AuthSlice';
import {useDispatch, useSelector} from "react-redux";
import Layout from "../../components/Layout";
import {useForm} from 'react-hook-form';
import SideBarData from "../../data/leftSideBar";
import {NavLink} from "react-router-dom";

import OrderManagementComponent from "./component/OrderManagementComponent";
import ServiceCardComponent from "./component/ServiceCardComponent";
import {fetchDashboard} from "../../reducers/DashboardSlice";
import ProviderListComponent from "./component/ProviderListComponent";
import LoadingComponent from "../../modal/LoadingComponent";

const AdminHome = () => {
    const {register, handleSubmit, formState: {errors}, reset} = useForm();
    const { loading, dashboardData, error, success, cardList, this_month_order, last_month_order, provider_list} = useSelector((state) => state.dashboard)
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(fetchDashboard());

        // dispatch(breadcrumbTree({'Dashboard': ''}));
        document.title = 'HelpAbode Admin Dashboard';
    }, [dispatch]);

    return (

        <Layout page_title={'Dashboard'} 
        // pageType={'Dashboard'}
        breadcrumb={[{"name":'Overview',"link": ''}]}
                component={
                    <div className='dashboard-page'>
                        {cardList && <div className="overview-cards">
                            <div className="row">
                                {cardList.map((item, index) => (
                                    <ServiceCardComponent data={item} index={index} key={index}/>
                                ))}
                            </div>
                        </div>}

                        {/*{this_month_order && <div className="overview-cards">*/}
                        {/*    <div className="row">*/}
                        {/*        <OrderManagementComponent data={'data'}/>*/}
                        {/*        <OrderManagementComponent data={'data'}/>*/}
                        {/*    </div>*/}
                        {/*</div>}*/}

                        {/*{last_month_order && <div className="overview-cards mt-4">*/}
                        {/*    <div className="row">*/}
                        {/*        <OrderManagementComponent data={'data'}/>*/}
                        {/*        <OrderManagementComponent data={'data'}/>*/}
                        {/*    </div>*/}
                        {/*</div>}*/}

                        {provider_list && <div className="provider_list">
                            <ProviderListComponent data={provider_list}/>
                        </div>}
                        {loading && <LoadingComponent message={''}/>}
                    </div>

                }
        ></Layout>
    );
}

export default AdminHome;
// what is redux toolkits?
// how to create functional components?
