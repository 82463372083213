import React from 'react';
 

const PriceRequestMessage = ({data, handleClick}) => {
  
    return (
        <div className="admin-declined-message container">
            <div className="message-header">
                <div className="user-info">
                    {/* <img src="profile-icon.svg" alt="Profile Icon" className="profile-icon" /> */}
                     <i className="profile-icon fa fa-user-circle-o" aria-hidden="true"></i> 
                    <span className="username">Admin</span>
                    <span className="timestamp">{data.priceRejectedDate}</span>
                </div>
                <div className="edit-icon" onClick={()=>handleClick(true)}>
                    <i className="fa fa-pencil" aria-hidden="true"></i>
                </div>
            </div>
            <div className="message-body">
                <p> {data.priceRejectedNote} </p>
                {data.isPriceRejectedAttached && <div className="attachment">
                <span className="attachment-icon"><i className="fa fa-paperclip" aria-hidden="true"></i></span>
                <span className="file-info">unnamed.png  - 124KB</span>
            </div>}
            </div>
            
        </div>
    );
};

export default PriceRequestMessage;
