import { useEffect, useState } from "react";
import {Button, Form} from 'react-bootstrap';
import AddRoleComponent from "./component/AddRoleComponent";
import { useDispatch, useSelector } from "react-redux";

// import doneAll from "../../assets/images/done-all.svg";
 
import {fetchRoleList, updateRole, deleteRole} from "../../reducers/IdentitySlice";

import CustomDataTable from "../../components/CustomDataTable";
import Layout from "../../components/Layout";
import ExportFile from "../../component/utility-component/ExportFile";
import { toast } from 'react-toastify';
import '../../assets/tostify/ReactToastify.css';
const WorkerRole = () => {
  const [show, setShow] = useState(false);
 
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState('');
  const [alertSuccess, setAlertSuccess] = useState('');
 
  const handleShow = () => setShow(true);
  const handleAlert = () => {setAlertSuccess(false)};

 

  const { role } = useSelector((state) => state.identity);
  const dispatch = useDispatch();
  // let tableData = null;

  console.log(role)
 

 
  const handleClose = (data) =>{
    console.log(data)
    if(data) {
    if(role.update || role.id){
       toast(` Your action was successful! `);
      }  
    }
    
    setShow(false); 
    setInitialData({...initialData,update: false,
          title: null,
          status: 'Y'})
  }
  const [ initialData, setInitialData ] = useState({
      update: false,
      title: null,
      status: 'Y',
    })
  let uniqueData=''
   
     if (role.length > 0) {
        const viewTableData = role.filter(item => item['title']?.toLowerCase().includes(filter.toLowerCase()))
        // let tableData1 = viewTableData.filter(item => item['question']?.toLowerCase().includes(query.toLowerCase()))
        // let tableData2 = viewTableData.filter(item => item['orderTextId']?.toLowerCase().includes(query.toLowerCase()))
        // let tableData3 = viewTableData.filter(item => item['workerName']?.toLowerCase().includes(query.toLowerCase()))
        // uniqueData = filterDataTable3Col('orderTextId', tableData1, tableData2, tableData3);
        uniqueData =  viewTableData;
    }
   
  // toast.success("Login success", results); 
    useEffect(() => {
      // dispatch(fetchNotificationTemplateData());
      dispatch(fetchRoleList());
      // dispatch(breadcrumbTree({'Setting': 'setting','Worker Role':''}));
      // document.title = 'HelpAbode Notification Template Create by Admin';
      
    }, []);

  const columns = [
    // { name: "ID", sortable: true, cell: (row) => row.id },
    { name: "Role Title", sortable: true, cell: (row) => row.title }, 
    // { name: "FAQ Type", minWidth: '250px', sortable: true, cell: (row) => row.faqCategory  },
    // { name: "Destination", minWidth: '250px',  sortable: true, cell: (row) => row.faqDestination },
    // { name: "Rank", sortable: true, cell: (row) => row.rank },
    // { name: "Status", minWidth: '100px', sortable: true, cell: (row) => row.status},
  //   {name: "Status", minWidth: '150px', cell: (row) =>  <select className={`form-select`} name='status' value={row.status}
  //   onChange={(e)=>{
  //     let localData = null;
  //     Role.map((e, index)=>e.id === row.id? localData = index:''); 
  //     const item ={};
  //     item.id =  row.id;
  //     item.localData = localData;
  //     item.question= row.question;
  //     item.answer= row.answer
  //     item.faqCategory=row.faqCategory
  //     item.faqDestination = row.faqDestination
  //     item.rank= row.rank
  //     item.update= true
  //     item.status= e.target.value
  //     const res= dispatch(updateRole(item));
  //     res.then(results => {
  //       if(results.error) {toast(` Something went wrong. Please try again!`) }
  //       else {
  //         // toast(` Your action was successful! `)
  //         setAlertSuccess('Your action was successful!');
  //         setTimeout(handleAlert, 1000);
       
  //       }
  //     }).catch(error => {
  //       alert('error ---- false')
  //     });
      
      
  //   }}> 
  //     <option value={`Y`} > Active</option>
  //     <option value={`N`}  >Inactive</option> 
        
  // </select> 
  //     },

    // {name: "Status", maxWidth: '100px',  cell: (row) => <div className='item-switch'>
    //         <Form.Check type="switch" name='status' defaultChecked={row.status === 'Y'? true:false}  
    //         onChange={(e)=>{
            
    //         let localData = null;
    //         role.map((e, index)=>e.id === row.id? localData = index:''); 
    //         const item ={};
    //         item.id =  row.id;
    //         item.textId =  row.textId;
    //         item.localData = localData;
    //         item.actionMultipleTime= e.target.checked?'Y':'N';
    //         item.notificationMessageSettingsTextId= null;
    //         console.log(item, e.target)
    //         const res= dispatch(updateRole(item));
    //         res.then(results => {
    //             if(results.error) {toast(` Something went wrong. Please try again!`) }
    //         }).catch(error => {
    //             alert('error ---- false')
    //         });
    //         }}/>
    //         </div>
    // },
    {name: "Action", width: '140px', cell: (row) =><div className="small-btn"> 
    <button className="btn-edit" onClick={() => {
          let localData = null;
           role.map((e, index)=>e.id === row.id? localData = index:'');
            setInitialData((initialData) => ({
            ...initialData,
            update: true,
            id: row.id, 
            title: row.title, 
            textId: row.textId, 
            status: row.status,
            localData: localData
          }))
          setShow(true)
        }}> <i className="fa fa-pencil" aria-hidden="true"></i> Edit</button>

     
 
        <span className="btn-delete"
          onClick={() => {
            const approval = window.confirm("Are you sure about the deletion?");
            if (approval === true) {
              
                let localData = null;
                role.map((e, index)=>e.id === row.id? localData = index:''); 
                const item ={};
                item.id =  row.id;
                item.textId =  row.textId;
                item.localData = localData;
 
              dispatch(deleteRole(item));
            }
          }}
        >
          Delete
        </span></div>
      
    },
  ];
  return (
    <Layout page_title={'Worker Role'} breadcrumb={{'Setting': 'setting','Worker Role':''}} 
      component={
        <> 
            {show ? <AddRoleComponent data={initialData} close={handleClose} />:
            <div className='data-table-wrapper newNotification-table'>
              {alertSuccess &&<div className="alert alert-success">
                <strong>Success!</strong> {alertSuccess}
              </div>}
              {/* <ToastContainer className="ToastContainer"/> */}
               <div className="w-[100%] flex gap-2.5 relative mb-3">
                                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                                {/* <Button variant="primary" onClick={handleShow}> Add Attribute </Button> */}
                                <Button className="new-configuration-btn add-new" onClick={handleShow}> Add Item </Button>

                                <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px] relative'>
                                    <input name="search" placeholder={'Search by  Title...'}
                                           type="text"
                                           className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                                           value={query ? query : ''}
                                           onChange={(e) => {
                                               setQuery(e.target.value)
                                           }}/>
                                    <div className={"absolute left-[12px] top-[12px]"}>
                                      <i className="fa fa-search" aria-hidden="true"></i>
                                    </div>
                                     
                                </div>
<div
                                        className="export-btn absolute right-0 top">
                                        <ExportFile data={uniqueData} fileName={'FAQ-template-data'}/>
                                    </div>
                            </div>
                            {/* <div className="faq-filter-list">
                              <div className={`item ${filter===''&&'active'}`} onClick={()=>setFilter('')}><img src="/images/done-all.svg" alt="Customer Management" />   All </div>
                              <div className={`item ${filter==='Ordering'&&'active'}`} onClick={()=>setFilter('Ordering')}> <img src="/images/ordering.svg" alt="Customer Management" />  Ordering </div>
                              <div className={`item ${filter==='Schedule'&&'active'}`} onClick={()=>setFilter('Schedule')}> <img src="/images/schedule.svg" alt="Customer Management" />  Schedule </div>
                              <div className={`item ${filter==='Account'&&'active'}`} onClick={()=>setFilter('Account')}> <img src="/images/account.svg" alt="Customer Management" />  Account </div>
                              <div className={`item ${filter==='Ratings'&&'active'}`} onClick={()=>setFilter('Ratings')}> <img src="/images/ratings.svg" alt="Customer Management" />  Ratings </div>
                              <div className={`item ${filter==='Earnings'&&'active'}`} onClick={()=>setFilter('Earnings')}> <img src="/images/earnings.svg" alt="Customer Management" />  Earnings </div> 
                            </div> */}

                             
              
              <CustomDataTable data={uniqueData} columns={columns} /></div>}

            
 
          
        </>
      }
    ></Layout>
  );
};

export default WorkerRole;
