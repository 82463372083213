import {Form, Modal, Button, Image} from "react-bootstrap";
// import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
 
 
import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState, useRef} from "react";
 
import {useFormik} from "formik";
// import {createCategory, successConfirmation, updateCategory} from '../../reducers/CategorySlice'
// import {fetchServices} from "../../reducers/ServiceItemSlice";
import { updatePageMetaByForm, createPageMetaByForm } from "../../../reducers/DashboardSlice";
 
const AddMetaForPageComponent = ({data, close, module, role}) => {

    // const [sender, setSender] = useState(false);
    // const [recever, setRecever] = useState(false);
    const dispatch = useDispatch();
    const [editorData, setEditorData] = useState();
    const [exitError, setExitError] = useState();

    
      const handleChangea = (event, editor) => {
        const details = editor.getData();
        setEditorData(details);
        setValues({...values, answer: details})
    };
    console.log(data)

    const {values, errors, handleChange, handleSubmit, setValues} = useFormik({

        initialValues: data,

        onSubmit: (values, action) => {

            const res = dispatch(data.update===true?updatePageMetaByForm(values):createPageMetaByForm(values));
            res.then(results => {
                
               if(results.error) {setExitError('Something went wrong. Please try again!'); 

               }
               else {
                close(results.payload.results); 
               }
            }).catch(error => {
                alert('error ---- false')
            });

        },
        //  validationSchema: faqSchema,

    });
    // console.log("🚀 values:", values)

     
    return (
        <> <div className="wrap-content">
            <Form onSubmit={handleSubmit} className="form-data">
                <div className="section-two-column faq-form"> 
                  
                        {/* <div className="w-full text-black text-xl font-medium font-['Inter']">Edit Question and Answer </div> */}
                        {/* <hr className="border" /> */}
                        
                        {exitError && <div className="alert alert-danger">
                              {exitError} 
                            </div>}

                       
                            <div className="field-row">
                                <label className="label">Page Title</label>
                             
                                <input name="title" placeholder="Page Title" type="text" className="border w-100 border-slate-700 p-2 rounded-md" value={values.title} onChange={handleChange} ></input>
                                 {errors.title && <p className="red">{errors.title}</p>} 
                            </div>

                            <div className="field-row">
                                <label className="label">Page URL</label>
                             
                                <input name="pageUrl" placeholder="Page URL" type="text" className="border w-100 border-slate-700 p-2 rounded-md" value={values.pageUrl} onChange={handleChange} ></input>
                                 {errors.pageUrl && <p className="red">{errors.pageUrl}</p>} 
                            </div>

                           

                      <div className="field-row">
                            <label className="label">Breadcrumb Tree</label>
                            <textarea className="form-control description" name="breadcrumbTree" rows="5" placeholder="Breadcrumb by Json"  
                                      value={values.breadcrumbTree} onChange={handleChange}></textarea>
                                      
                                 {errors.breadcrumbTree && <p className="red">{errors.breadcrumbTree}</p>} 
                            </div>
                          
                    <div className="save-option">
                        {/* <button><span className="draft-icon"> <img src={uploadImgIcon} alt=""/> </span>Save as Draft  </button> */}
                        <button className="save-btn">{data.update===true?'Updated':'Save'}</button>
                        <button className="save-btn" onClick={()=>close()}>Close</button>
                    </div>

                </div>
            </Form>
        </div>
        </> 
    );
};

export default AddMetaForPageComponent;
