import React, {useEffect, useState, useRef} from 'react'
// import AreaComponent from "../provider/AreaComponent";

const ZipCodeSliderComponent = ({data}) => {


    const [currentSlide, setCurrentSlide] = useState(1);
    const [currentPosition, setCurrentPosition] = useState(1);
    const [currentIndexWidth, setCurrentIndexWidth] = useState(50);
    const [elementWidth, setElementWidth] = useState(0);
    const [slideWidth, setSlideWidth] = useState(0);
    const zipListRef = useRef(null);
    const elementRef = useRef(null);

    const scrollLeft = () => {
        // if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
            setCurrentIndexWidth(zipListRef.current.children[currentSlide].offsetWidth)
            setCurrentPosition(currentPosition + currentIndexWidth)
        // }
        console.log('scrollLeft')
    };

    const scrollRight = () => {
        // if (currentSlide < zipListRef.current.children.length - 1) {
            setCurrentSlide(currentSlide + 1);
            setCurrentIndexWidth(zipListRef.current.children[currentSlide].offsetWidth)
            setCurrentPosition(currentPosition - currentIndexWidth)
            // zipListRef.current.scrollLeft += 300;


            console.log('scrollRight')
            // console.log(slideWidth - (elementWidth + currentPosition +currentIndexWidth))
            // console.log('zipListRef.current.scrollLeft ', zipListRef.current.scrollLeft )
        // }
    };

    // const updateArrowVisibility = () => {
    //     console.log('data', data);
    //     console.log('zipListRef', zipListRef);
    //     if (zipListRef != null) {
    //         return {

    //             leftArrow: currentSlide === 0 ? 'hidden' : '',
    //             rightArrow: currentSlide === data.length - 1 ? 'hidden' : '',
    //         };
    //     } else {
    //         return {

    //             leftArrow: currentSlide === 0 ? 'hidden' : '',
    //             rightArrow: currentSlide === zipListRef.current.children.length - 1 ? 'hidden' : '',
    //         };
    //     }
    // };

    useEffect(() => {
        setSlideWidth(zipListRef.current.offsetWidth)
        // setCurrentIndexWidth()
        setElementWidth(elementRef.current.offsetWidth)
        if (currentPosition > 0) {
            setCurrentPosition(0)
        }
        // if (slideWidth - elementWidth + currentPosition + currentIndexWidth <5) {
        //     setCurrentPosition(elementWidth)
        // }
        // console.log('useEffect currentPosition')
        // console.log(slideWidth - elementWidth + currentPosition + currentIndexWidth)
        // console.log('elementWidth:', elementWidth, ' slideWidth:', slideWidth, ' currentPosition:', currentPosition, ' currentSlide:', currentSlide, ' lastPosition: ', elementWidth + currentPosition + currentIndexWidth)
    }, [currentPosition]);


    // console.log(data)

    return (
        <>

            <div className="zip-slider-container" ref={elementRef}>
                {currentPosition < 0 && <button className={`left-arrow`} onClick={scrollLeft}>&#10094;</button>}
                <div className="zip-list-container">
                    <ul className="zip-list-slider" ref={zipListRef} style={{left: currentPosition}}>
                        {data.map((zip, index) => (
                            <li key={index} className=" bg-gray-200 rounded-lg justify-start items-center gap-2.5 inline-flex">

                                    <div className="text-black text-sm font-normal font-['Inter']">{zip}</div>

                            </li>
                        ))}
                    </ul>
                </div>
                {/*{slideWidth - elementWidth + currentPosition + currentIndexWidth <5}*/}

                {slideWidth - elementWidth + currentPosition + currentIndexWidth > 150 &&
                    <button className={`right-arrow }`}
                            onClick={scrollRight}>&#10095;</button>}
            </div>
        </>
    );
};

export default ZipCodeSliderComponent;