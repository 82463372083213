import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";

import {Button, Image} from 'react-bootstrap';
import ServiceItemAddForm from './ServiceItemAddForm';
import {BASE_URL} from '../../BaseUrl';
import NavBarUpdate from "./NavBarUpdate";
import Layout from '../../components/Layout';
import ServiceItemConfiguration from './ServiceItemConfiguration';
// import { fetchItemDetails } from '../../reducers/CategorySlice';

import ServiceSubItemForm from "./ServiceSubItemForm";

const EditItemPage = () => {
    const navigate = useNavigate();
    // let location = useLocation();
    const dispatch = useDispatch()
    // const handleClose = () => { navigate(-1)};
    const row = window.location.hash.replace('#', '');
    const {items} = useSelector((state) => state.category)
    const {item,parentCategory} = useParams();
    const { state } = useLocation();
    let itemInitialData = false;
    useEffect(() => {
        if(!state){
            navigate(`/service/${item}`)
        }
        // dispatch(fetchItemDetails())
        // dispatch(breadcrumbTree({'Item Edit Page': 'edit'}));
        document.title = 'Item Edit Page';
    }, [dispatch])

console.table('state:: ',state)
    return (
        <>
            <Layout component={
                <>
                    {/*<ServiceItemAddForm data={state}/>*/}
                    {window.location.hash ?<ServiceItemAddForm data={state}/>:  <ServiceSubItemForm data={state}/>}

                </>
            }></Layout>
        </>
    )
}

export default EditItemPage;