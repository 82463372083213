
import 'react-datepicker/dist/react-datepicker.css';
import React, {useEffect, useState} from "react";
import { useDispatch,useSelector } from 'react-redux'
import CustomDataTable from '../../components/CustomDataTable'
import {fetchProviderPriceListApi} from '../../reducers/TeamMemberSlice'
import { Form} from 'react-bootstrap'
import Layout from '../../components/Layout'
import {Link } from "react-router-dom";
// import {fetchActiveServiceItem} from "../../reducers/CategorySlice";
import { getUniqueData } from "../../utility/FilterDataTable";
// import DatePicker from "react-datepicker";
import * as moment from 'moment'
// import CustomDatePickerInput from '../../component/CustomDatePickerInput';
// import SetProviderStatus from "../../component/provider/SetProviderStatus";
// import GeneralInfo from "../../component/provider/GeneralInfo";
// import BusinessInfo from "../../component/provider/BusinessInfo";
// import CategoryRequested from "../../component/provider/CategoryRequested";
import StatusCardComponent from "../../component/card/PriceStatusCardComponent";
// import Button from 'react-bootstrap/Button';
// import {filterDataTable3Col} from "../../utility/FilterDataTable";
import ExportFile from "../../component/utility-component/ExportFile";

const PriceManagement = () => {
    // const [startDate, setStartDate] = useState(new Date());
    const {providerListByPrice, serviceSummary, loading, error } = useSelector((state) => state.teamMembers) 
    const dispatch = useDispatch()
    // const [selectedDate, setSelectedDate] = React.useState(new Date());
 
    const [query, setQuery] = useState('');
    const [filterBy, setFilterBy] = useState('serviceTitle');
    const [filterByProdider, setFilterByProdider] = useState('');
    // const [filterBy, setFilterBy] = useState('');
    // const [filterByStatus, setFilterByStatus] = useState('');
    // const navigate = useNavigate();

    // let tableData = providerListByPrice.filter(item => item['status']?.toLowerCase().includes(filterByStatus.toLowerCase())) 
    // let tableData = tableData1.filter(item => item['employeeType']?.toLowerCase().includes(filterBy.toLowerCase())) 
    let tableData2 = providerListByPrice.filter(item => item[filterBy]?.toLowerCase().includes(query.toLowerCase()))
    // let tableData3 = tableData.filter(item => item['workerName']?.toLowerCase().includes(query.toLowerCase()))
    // let tableData4 = tableData.filter(item => item['email']?.toLowerCase().includes(query.toLowerCase())) 

    const uniqueData = tableData2;
    //  const uniqueData = filterDataTable3Col('email',tableData2, tableData3, tableData4);

    const columns = [
        // {name: "ID", sortable: true, cell: (row) => row.id},
        // {name: "TextId", sortable: true, cell: (row) => row.textId},

        {name: "Services",  sortable: true, selector: (row) => row.serviceTitle},
        // {name: "Service Area", sortable: true, selector: (row) => row.serviceTitle},
        {name: "Service Provider", sortable: true, selector: (row) => row.providerName},
        {name: "Type", maxWidth:"100px", minWidth:"100px", sortable: true, selector: (row) => row.serviceType},
        {name: "Status", maxWidth:"150px", minWidth:"150px", sortable: true, selector: (row) => <span className={`status ${row.status}`}> {row.statusDisplay}</span>},
        {name: "Action", maxWidth:"90px", minWidth:"80px", selector: (row) => <Link to={`/provider/${row.workerTextId}/${row.serviceTextId}?ca=${row.categoryTextId}`} className="btn btn-default"> Details</Link>},
        {name: "Requested Date", maxWidth:"160px", minWidth:"150px", sortable: true, selector: (row) => moment(new Date(row.requested_date)).format("DD MMM YYYY,  h:mm A")}, 
    ]
    console.log(providerListByPrice)
    useEffect(() => {
        const res=dispatch(fetchProviderPriceListApi())
        res.then((results) => {
            // dispatch(breadcrumbTree({' Provider Request':'request', ' Manage Price ':'manage-price'}));
            document.title = 'Provider Listing';
            // dispatch(breadcrumbTree([{'Service ': 'service-area'},results.payload.pageBreadcrumb]));
            // dispatch(breadcrumbTree({'Service ':'service-area', 'nur':'dddd'}));
        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
    }, [dispatch])

 

  return (
    <Layout 
    page_title={'Price Request List'} 
    breadcrumb={{' Provider Request':'/provider/manage-price', ' Manage Price ':''}} 
     component={
        <>
    {loading && <div>Loading ...</div>}
    {!providerListByPrice && error ? <div>Error: {error}</div> : null}
    {!loading ?
        <>
      
            {serviceSummary  && <StatusCardComponent data={serviceSummary} />}
            
       

        <div className='data-table-wrapper order-table'>
 

            <div className="w-[100%] flex gap-2.5 relative mb-3">
                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px]'>
                    <input name="search" placeholder={'Search by Services Title or customer...'}
                            type="text"
                            className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                            value={query ? query : ''}
                            onChange={(e) => {
                                setQuery(e.target.value)
                            }}/>
                    <div className={"absolute left-[12px] top-[12px]"}><i className="fa fa-search"
                                                                            aria-hidden="true"></i>
                    </div>
                    <div
                        className="export-btn absolute right-0 top">
                        <ExportFile data={uniqueData} fileName={'Price-request-list'}/>
                    </div>
                </div>

            </div>
            <CustomDataTable data={uniqueData} columns={columns}/>
            </div>
        </>
        : null}
        </>
    } 
    top_content={
        <div className="search-header-area">
            
           
           
            {/* <Form.Select aria-label="Default select example" className="search-type" name="searchType"
                            value={filterByStatus} onChange={(e) => {
                setFilterByStatus(e.target.value); 
            }}>
                <option value=''>All Locations</option> 
                <option value='Pending'>Pending</option>
                <option value="Approved">Approved</option>
                <option value='Rejected'>Rejected</option>
                <option value='Pause'>Pause</option>
            </Form.Select> */}

             <Form.Select aria-label="Default select example" className="search-type" name="searchType"
                                 value={filterByProdider} onChange={(e) => {
                        setFilterByProdider(e.target.value);
                    }}>
                        <option value=''>Service Provider</option>
                        {Object.values(getUniqueData('providerName', providerListByPrice)).map(key => (<>{key &&
                            <option key={key} value={key}>{key}</option>}</>))}

                </Form.Select>

                    <Form.Select aria-label="Default select example" className="search-type" name="searchType"
                                 value={filterBy} onChange={(e) => {
                                    setFilterBy(e.target.value); 
                    }}>
                        <option value='serviceTitle'>Filter By</option> 
                        <option value='serviceTitle'>Service Title</option>
                        <option value="providerName">Service Provider</option>
                        {/* <option value='Rejected'>Rejected</option>
                        <option value='Pause'>Pause</option> */}
                        {/* {Object.values(getUniqueData('status', providerListByPrice)).map(key => (<>{key &&
                            <option key={key} value={key}>{key}</option>}</>))}  */}
                    </Form.Select>


        </div>
    }
    
    
    ></Layout>
  )
}

export default PriceManagement