import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../BaseUrl";
// import {addActiveServiceItem} from './NavDataSlice'
function deleteItemByServiceTextId(data, serviceTextId) {
  // Filter the data to exclude the item with the matching serviceTextId
  const filteredData = data.filter((item) => item.serviceTextId !== serviceTextId);

  // Return the modified data set
  return filteredData;
}

const initialState = {
    loading: false,
    items: [],
    identityArr: [],
    activeItems: [],
    categories: [],
    serviceList: [],
    subItems: [],
    configStatus: true,
    configuration: false,
    success: false,
    error: '',
    ref: false,
    itemAddStatus: false,
    configuration_data: false,
    title: '',
    itemImage: false,
    itemIcon:false
}

const url = `${BASE_URL}/category/`

const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: `Token ${token}`,
        "content-type": "multipart/form-data",
        // 'Cache-Control': 'no-cache',
        // 'Pragma': 'no-cache',
        // 'Expires': '0',
    }
}


export const serviceImageUpdate = createAsyncThunk(
    "create/services", async (formData) => {

        const curl = `${BASE_URL}/service/${formData.categoryTextId}/CategoryService`;
        return axios
            .post(curl, formData, config)
            .then((response) => response.data)
    }
)


export const createCategory = createAsyncThunk(
    "create/category", async (formData) => {
 
        return axios
            .post(`${url}${formData.serviceItemTextId}/${formData.categoryTextId}/ItemCategory`, formData, config)
            .then((response) => response.data)
    }
)

export const editCategory = createAsyncThunk(
    "update/editCategory", async (formData) => {
        if (typeof formData.image !== 'object' || formData.image === null) {
            formData.image = '';
            formData.icon = '';
        }
        console.table(formData)
        return axios
            .put(`${url}${formData.parentTextId}/${formData.textId}/ItemCategory`, formData, config)
            .then((response) => response.data)
    }
)

export const fetchServiceItem = createAsyncThunk(
    'settings/serviceItem', async (url) => {
        const curl = `${BASE_URL}/settings/serviceItemManagement/`;
        return axios
            .get(curl, config)
            .then((response) => response.data)
    }
)


 

export const createServiceItem = createAsyncThunk(
    "settings/createItem", async (formData) => {
        const curl = `${BASE_URL}/settings/serviceItemManagement/`
        return axios
            .post(curl, formData, config)
            .then((response) => response.data)
    }
)

export const updateServiceItem = createAsyncThunk(
    "settings/updateItem", async (formData) => {
        if (typeof formData.image !== 'object' || formData.image === null) {
            formData.image = '';
        }
        if (typeof formData.icon !== 'object' || formData.icon === null) {
            formData.icon = '';
        }
        const curl = `${BASE_URL}/settings/serviceItemManagement/`
        return axios
            .put(curl, formData, config)
            .then((response) => {
                // response.updateData = formData;
                return response.data
            })
    }
)

export const updateItemColumns = createAsyncThunk(
    'settings/updateServiceItemColumns', async (formData) => {
        const curl = `${BASE_URL}/settings/${formData.textId}/serviceItemSetting/`;
        return axios
            .put(curl, formData, config)
            .then((response) => response.data)
    }
)

export const createServiceSubItem = createAsyncThunk(
    "settings/createSubItem", async (formData) => {
        const curl = `${BASE_URL}/settings/${formData.parentTextId}/serviceSubItemManagement/`
        return axios
            .post(curl, formData, config)
            .then((response) => response.data)
    }
)

export const updateServiceSubItem = createAsyncThunk(
    "settings/updateSubItem", async (formData) => {
        const curl = `${BASE_URL}/settings/${formData.attributeGroupTextId}/serviceSubItemManagement/`
        return axios
            .put(curl, formData, config)
            .then((response) => response.data )
    }
)

export const updateCategory = createAsyncThunk(
    "update/category", async (formData) => {
        return axios
            .put(`${url}${formData.textId}`, formData, config)
            .then((response) => {
                response.data.localData = formData.localData;
                return response.data;
            })
    }
)


export const deleteCategory = createAsyncThunk(
    "delete/category", async (formData) => {
        return axios
            .delete(`${url}${formData.textId}`, config)
            .then((response) => {
                // response.data.localData = formData.localData;
                // console.log("🚀 ~ file: CategorySlice.js:50 ~ .then ~ response:", response)
                // console.log("🚀 ~ file: CategorySlice.js:51 ~ .then ~ response:", formData)

                return formData;
            })
    }
)


export const deleteService = createAsyncThunk(
    "delete/service", async (formData) => {
        return axios
            .delete(`${BASE_URL}/service/${formData}/ServiceDelete/`, config)
            .then((response) => {
                // response.data.localData = formData.localData;
                // console.log("🚀 ~ file: CategorySlice.js:50 ~ .then ~ response:", response)
                // console.log("🚀 ~ file: CategorySlice.js:51 ~ .then ~ response:", formData)

                return {data:response.data,id:formData};
            })
    }
)
 

export const deleteRootItem = createAsyncThunk(
    "delete/item", async (formData) => {
        return axios
            .delete(`${BASE_URL}/settings/${formData}/serviceItemDelete/`, config)
            .then((response) => {
                // response.data.localData = formData.localData;
                // console.log("🚀 ~ file: CategorySlice.js:50 ~ .then ~ response:", response)
                // console.log("🚀 ~ file: CategorySlice.js:51 ~ .then ~ response:", formData)

                return response.data;
        })
    }
)


// export const deletePermission = createAsyncThunk(
//   "delete/permissions", async (id) => {
//     return axios
//     .delete(`${url}/${id}`, config)
//     .then((response) => response.data)
//   }
// )

export const fetchActiveServiceItem = createAsyncThunk(
    'settings/ActiveServiceItem', async () => {

        // if(textId.length<2){ url = `${url}${textId[2]}`;}
        const curl = `${BASE_URL}/settings/ServicesItemList/`
        return axios
            .get(curl, config)
            .then((response) => response.data)
    }
)
export const fetchServiceSubItem = createAsyncThunk(
    'settings/serviceSubItemFetch', async (textId) => {
        // if(textId.length<2){ url = `${url}${textId[2]}`;}
        const curl = `${BASE_URL}/settings/${textId}/serviceSubItemManagement/`;
        return axios
            .get(curl, config)
            .then((response) => response.data)
    }
)

export const fetchCategory = createAsyncThunk(
    'fetch/category', async (textId) => {
        return axios
            .get(`${url}${textId}/ItemCategory`, config)
            .then((response) => response.data)
    }
)

export const fetchServiceWithCategory = createAsyncThunk(
    'fetch/Service_and_category', async (textId) => {
 
        return axios
            .get(`${url}${textId}/ItemCategory`, config)
            .then((response) => response.data)
    }
)

export const itemRankUpdateApi = createAsyncThunk(
    "updateQty/category", async (formData) => {
        return axios
            .post(`${BASE_URL}/settings/serviceItemRankUpdate/`, formData, config)
            .then((response) => {
                response.data.localData = formData.localData;
                return response.data;
            })
    }
)

export const serviceRankUpdate = createAsyncThunk(
    "updateQty/service", async (formData) => {
        return axios
            .post(`${url}serviceRankUpdate/`, formData, config)
            .then((response) => {
                response.data.localData = formData.localData;
                return response.data;
            })
    }
)

// export const categoryRankUpdateApi = createAsyncThunk(
//     "updateQty/category", async (formData) => {
//         return axios
//             .post(`${url}categoryRankUpdate/`, formData, config)
//             .then((response) => {
//                 response.data.localData = formData.localData;
//                 return response.data;
//             })
//     }
// )

export const serviceItemUpdateStatus = createAsyncThunk(
    "update/UpdateStatus", async (formData) => {
        return axios
            .put(`${BASE_URL}/settings/${formData.textId}/service-item-pauseUnpause/`, formData, config)
            .then((response) =>  response.data)
    }
)


const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {
        successConfirmation(state, action) {
            state.success = false;
            state.error = false;
            console.log('ddddddddddddddddddd')
        },
        updateState(state, action) {
            state.res = false;
            state.itemAddStatus = false;
        },
        updateItemStatus(state, action) {
            // state[action.payload.arr] = action.payload.status;
            state[action.payload.arr][action.payload.index]['status'] = action.payload.status;            
        },
        appendNewServices(state, action) {
            state.serviceList.push(action.payload);
        },
        setEditedData(state,action){
            const id = action.payload.data.index;
            state.items[id].title = action.payload.data.title;
            state.items[id].details = action.payload.data.details;
            if(action.payload.thumb){
                state.items[id].image = action.payload.thumb;
            }
            // state.items[id].image = {action.payload.thumbImage && };
            console.table('*******************************')
            console.table(action.payload)
        },
        subItemConfigurationResponse(state, action) {
            state.configStatus = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchServiceWithCategory.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.title = ''
            state.serviceList = [];
        });
        builder.addCase(fetchServiceWithCategory.fulfilled, (state, action) => {
            state.loading = false;
            state.serviceList = action.payload.service_list;
            // state.success = true;
            if (action.payload !== '') {
                state.categories = action.payload.categories;
            }
            state.error = "";
            state.title = action.payload.title;
            // console.log(action.payload)
        });
        builder.addCase(fetchServiceWithCategory.rejected, (state, action) => {
            state.loading = false;
            state.categories = [];
            state.error = action.error;
        });
        builder.addCase(itemRankUpdateApi.fulfilled, (state, action) => {
            state.loading = false;
            // state.categories[action.payload.localData] = action.payload;
            state.error = "";
        });

        builder.addCase(itemRankUpdateApi.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });

        builder.addCase(serviceItemUpdateStatus.fulfilled, (state, action) => {
            state.loading = false;
            // state.categories[action.payload.localData] = action.payload;
            state.error = "";
        });

        builder.addCase(serviceItemUpdateStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });

        builder.addCase(fetchServiceItem.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.nav = false;
        });

        builder.addCase(fetchServiceItem.fulfilled, (state, action) => {
            state.error = "";
            state.loading = false;
            state.nav = true;

            console.log(action.payload)
            if (action.payload === '') {
                state.items = [];
            } else {
                // state.items = [...action.payload];
                state.items = [...action.payload.get_attributes];
                // state.itemImage = action.payload.parent_image;
                // state.itemIcon = action.payload.parent_icon;

                state.identityArr = [...action.payload.get_identifier_list];
                state.status = true;
            }

        });
        builder.addCase(fetchServiceItem.rejected, (state, action) => {
            state.items = [];
            state.error = action.error;
            state.success = false;
            state.loading = false;
        });


        builder.addCase(fetchActiveServiceItem.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.nav = false;
        });

        builder.addCase(fetchActiveServiceItem.fulfilled, (state, action) => {
            state.activeItems = action.payload;
            // console.log(action.payload)
            state.error = "";
            state.loading = false;
            if (action.payload !== '') state.status = true;
            state.nav = true;
        });
        builder.addCase(fetchActiveServiceItem.rejected, (state, action) => {
            state.activeItems = [];
            state.error = action.error;
            state.loading = false;
        });
        builder.addCase(fetchServiceSubItem.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.configuration_data = []
        });

        builder.addCase(fetchServiceSubItem.fulfilled, (state, action) => {
            state.loading = false;
            console.log(action.payload)
        
            if (action.payload.configuration_data.length > 0) {
                state.configuration_data = JSON.parse(action.payload.configuration_data);
            }
            if (action.payload.configuration === 'true') {
                state.configStatus = true;
            } else {
                state.configStatus = false;
                // state.configuration_data = false
            }
            state.title = action.payload.title;
            state.itemImage = action.payload.parent_image;
            state.itemIcon = action.payload.parent_icon;
            state.subItems = action.payload.item_list;
            state.error = "";
        });
        builder.addCase(fetchServiceSubItem.rejected, (state, action) => {
            state.loading = false;
            // state.subItems = [];
            state.error = action.error;
            state.configStatus = false;
            // console.log('errors',action.error)
        });

        builder.addCase(createCategory.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(createCategory.fulfilled, (state, action) => {
            state.loading = false;
            state.error = false;
            state.success = true;

            state.categories.push(action.payload);
        });

        builder.addCase(createCategory.rejected, (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = action.error;
        });

        builder.addCase(editCategory.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.error = false;
        });
        builder.addCase(editCategory.fulfilled, (state, action) => {
            state.success = true;
            state.loading = false;
            state.error = false;
            // state.categories.push(action.payload);
        });

        builder.addCase(editCategory.rejected, (state, action) => {
            state.loading = false;
            state.success = false;
            state.error = action.error;
        });

        builder.addCase(fetchCategory.pending, (state) => {
            state.loading = true;
            state.success = false;
        });

        builder.addCase(fetchCategory.fulfilled, (state, action) => {
            state.loading = false;
            state.error = "";
            state.categories = action.payload.categories;
            state.serviceList = action.payload.service_list;
        });

        builder.addCase(fetchCategory.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.success = false;
        });

        builder.addCase(createServiceItem.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.ref = false;
            state.itemAddStatus = false;
        });
        builder.addCase(createServiceItem.fulfilled, (state, action) => {
            state.ref = action.payload.textId;
            state.loading = false;
            state.error = "";
            state.items.push(action.payload);
            state.itemAddStatus = true;
            state.success = true;
        });

        builder.addCase(createServiceItem.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.success = false;
        });

        builder.addCase(updateServiceItem.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.ref = false;
            state.itemAddStatus = false;
            state.items = [];
        });
        builder.addCase(updateServiceItem.fulfilled, (state, action) => {
            // state.ref = action.payload.textId;
            state.loading = false;
            state.error = "";
            // state.items = [];
            state.items.push(action.payload.get_data);
            state.itemAddStatus = true;
            state.success = true;
            console.log('fulfilled: ', action.payload)
        });

        builder.addCase(updateServiceItem.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.success = false;
        });

        builder.addCase(updateServiceSubItem.pending, (state) => {
            state.loading = true;
            state.success = false;
            state.ref = false;
            state.itemAddStatus = false;
        });
        builder.addCase(updateServiceSubItem.fulfilled, (state, action) => {
            // state.ref = action.payload.textId;
            state.loading = false;
            state.error = "";
            state.items = [];
            state.itemAddStatus = true;
            state.success = true;
            console.log('succcess', action.payload)
        });

        builder.addCase(updateServiceSubItem.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.success = false;
        });

        builder.addCase(createServiceSubItem.pending, (state) => {
            state.loading = true;
            state.success = false;
       
        });
        builder.addCase(createServiceSubItem.fulfilled, (state, action) => {
            state.ref = action.payload.textId;
            state.loading = false;
            state.error = "";
            state.subItems.push(action.payload);
            state.itemAddStatus = true;
        });

        builder.addCase(createServiceSubItem.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            state.success = false;
        });

        builder.addCase(updateCategory.fulfilled, (state, action) => {
            state.loading = false;
            state.categories[action.payload.localData] = action.payload;
            state.error = "";
            // console.log(action.payload);
        });

        builder.addCase(updateCategory.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            console.log(action.error);
        });

        builder.addCase(deleteCategory.fulfilled, (state, action) => {
            state.loading = false;
            state.categories.splice(action.payload.localData, 1);
            state.error = "";
            // console.log("🚀 ~ file: CategorySlice.js:110 ~ builder.addCase ~ action:", action)

        });


        builder.addCase(deleteCategory.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            
        });

        
        builder.addCase(deleteService.pending, (state) => {
            state.loading = true;
            state.success = false;
       
        });

        builder.addCase(deleteService.fulfilled, (state, action) => {
            state.loading = false;
            // state.categories.splice(action.payload.localData, 1);
            state.error = "";
            // console.log('',action.payload)
            state.serviceList = deleteItemByServiceTextId(state.serviceList, action.payload.id);
            
        });

        builder.addCase(deleteService.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            
        });

    },
});


export const {
    setEditedData,
    updateState,
    appendNewServices,
    subItemConfigurationResponse,
    successConfirmation, updateItemStatus
} = categorySlice.actions;

export default categorySlice.reducer
