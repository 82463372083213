import React, {useEffect, useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import CustomDataTable from '../../components/CustomDataTable'
import { fetchEndUser } from '../../reducers/CustomersSlice'
import { Form } from 'react-bootstrap'
import Layout from '../../components/Layout'
import {fetchTeamMembers} from "../../reducers/TeamMemberSlice";

import LoadingComponent from "../../modal/LoadingComponent";
import {filterDataTable3Col, filterDataTable4Col} from "../../utility/FilterDataTable";
import {Link} from "react-router-dom";
import ExportFile from "../../component/utility-component/ExportFile";
import * as moment from 'moment'

const CustomerListPage = () => {
    const {customers, loading, error} = useSelector((state) => state.customers)
    const dispatch = useDispatch()
    const [query, setQuery] = useState('');

    // let viewTableData = customers;
    console.log('customers', customers)
    let uniqueData = false;

        let tableData1 = customers.filter(item => item['email']?.toLowerCase().includes(query.toLowerCase()))
        let tableData2 = customers.filter(item => item['firstName']?.toLowerCase().includes(query.toLowerCase()))
        // let tableData2 = customers.filter(item => item['lastName']?.toLowerCase().includes(query.toLowerCase()))
        let tableData3 = customers.filter(item => item['lastName'].toLowerCase().includes(query.toLowerCase()))
        let tableData4 = customers.filter(item => item['phone']?.toLowerCase().includes(query.toLowerCase()))
        uniqueData = filterDataTable3Col('textId', tableData1, tableData2, tableData4);




    const columns = [
        // {name: "ID", minWidth: '100px', sortable: true, cell: (row) => row.id},
        // {name: "TextId", sortable: true, cell: (row) => row.textId},
        // {name: "FranchiseTextId", sortable: true, cell: (row) => row.franchiseTextId},
        {name: "Customer Name", minWidth: '250px', sortable: true, cell: (row) => <><span>{row.firstName} </span> &nbsp; <span> {row.lastName}</span></> },
        {name: "Email", minWidth: '250px',sortable: true, cell: (row) => row.email},
        {name: "Phone", sortable: true, cell: (row) => row.phone},
        {name: "Country Code", sortable: true, cell: (row) => row.countryIso2Code},
        {name: "Created", sortable: true, cell: (row) =>  moment(new Date(row.created)).format("DD MMM YYYY,  h:mm A")},
        {name: "Action", minWidth: '150px', cell: (row) => <Link to={`./${row.textId}`}
                                              className="btn btn-default"> Details</Link>},


    ]

    useEffect(() => {

        let res= dispatch(fetchEndUser())
        if(res){
            // dispatch(breadcrumbTree({'Customer List':'customer'}));
            document.title = 'Customers Listing';
        }
    }, [dispatch])
    console.log(customers);

  return (
    <Layout page_title={'Customers Listing '} breadcrumb={{'Customers Listing': 'customer'}} 
     component={
        <>
            {loading && <LoadingComponent message={''}/>}
    {/*{error ? <div>Error: {error}</div> : null}*/}
    {customers.length>0 ?
        <div className='data-table-wrapper'>
            {/*<div className="w-[100%] flex gap-2.5 relative mb-3 bg-neutral-100 rounded-lg">*/}
            {/*    <input name="search" placeholder={'Search by Services Title or customer...'}*/}
            {/*           type="text"*/}
            {/*           className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"*/}
            {/*           value={query ? query : ''}*/}
            {/*           onChange={(e) => {*/}
            {/*               setQuery(e.target.value)*/}
            {/*           }}/>*/}

            {/*</div>*/}
            <div className="w-[100%] flex gap-2.5 relative mb-3">
                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px]'>
                    <input name="search" placeholder={'Search by Customer Name, Email and Phone number...'}
                           type="text"
                           className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                           value={query ? query : ''}
                           onChange={(e) => {
                               setQuery(e.target.value)
                           }}/>
                    <div className={"absolute left-[12px] top-[12px]"}><i className="fa fa-search"
                                                                          aria-hidden="true"></i>
                    </div>
                    <div
                        className="export-btn absolute right-0 top">
                        <ExportFile data={uniqueData} fileName={'Customer List'}/>
                    </div>
                </div>
            </div>

            <CustomDataTable data={uniqueData} columns={columns}/>
        </div>
        : null}
        </>
    }></Layout>
  )
}

export default CustomerListPage