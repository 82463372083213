import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../BaseUrl";

const initialState = {
    loading: false,
    teamMembers: [],
    category: [],
    zoneList: [],
    profile: '',
    images: false,
    error: '',
    providerListByPrice:[],
    providerPrice:false,
    tips:[],
    reviewRatingData:false,
    order:[],
    serviceSummary:false,
    dedeclineMessage:false,
    cardData:false
}

const url = `${BASE_URL}/worker`

const token = localStorage.getItem("token")

const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


export const fetchTeamMembers = createAsyncThunk(
    'fetch/teamMembers', async () => {
        return axios
            .get(`${url}/`, config)
            .then((response) => response.data)
    }
)
export const fetchProviderOrders = createAsyncThunk(
    'fetch/providerOrder', async (item) => {
        console.log('fetchProviderOrders***********************',item)
        return axios
            .get(`${url}/orderlist/${item}/`, config)
            .then((response) => response.data)
    }
)

export const fetchProviderPriceListApi = createAsyncThunk(
    'fetch/providerPrice', async () => {
        return axios
            .get(`${url}/servicePriceReview`, config)
            .then((response) => response.data)
    }
)
export const fetchReviewRatingManagementApi = createAsyncThunk(
    'fetch/ReviewRatingManagement', async () => {
        return axios
            .get(`${url}/ReviewRatingManagement`, config)
            .then((response) => response.data)
    }
)


export const updateReviewRatingManagementApi = createAsyncThunk(
    'update/ReviewRatingManagement', async (data) => {
        return axios
            .post(`${url}/ReviewRatingManagement`, data, config)
            .then((response) => response.data)
    }
)

export const fetchProfile = createAsyncThunk(
    'fetch/profile', async (textId) => {
        return axios
            .get(`${url}/profile/${textId}/`, config)
            .then((response) => response.data)
    }
)

export const fetchWorkerDocumentsView = createAsyncThunk(
    'fetch/WorkerDocumentsView', async (textId) => {
        return axios
            .get(`${url}/profile/${textId}/WorkerDocumentsView/`, config)
            .then((response) => response.data)
    }
)
export const fetchCategoryServiceWithAreas = createAsyncThunk(
    'fetch/CategoryServiceAreas', async (textId) => {
        return axios
            .get(`${url}/PendingServiceWithAreas/${textId}/`, config)
            .then((response) => response.data)
    }
)
export const fetchServicePriceListApi = createAsyncThunk(
    'fetch/servicePriceList', async (data) => {
        return axios
            .get(`${url}/servicePriceReviewDetails/${data.workerTextId}/${data.serviceTextId}/${data.categoryTextId}`, config)
            .then((response) => response.data)
    }
)
export const fetchProviderTipManagementApi = createAsyncThunk(
    'fetch/ProviderTipManagement', async () => {
        return axios
            .get(`${url}/providerTipManagement`, config)
            .then((response) => response.data)
    }
)
// export const exportOrderApi = createAsyncThunk(
//     'fetch/tips', async (formData) => {
//         return axios
//             .post(`${url}/providerTipManagement`,formData, config)
//             .then((response) => response.data)
//     }
// )

export const changeTeamMemberStatus = createAsyncThunk(
    "update/teamMemberStatus", async (data) => {
        return axios
            // .put(`${url}/status/${formData.textId}/`, {"status": formData.status}, config)
            .put(`${url}/status/${data.textId}/`, data, config)
            .then((response) => response.data)
    }
)

export const workerDataValidationNoticePost = createAsyncThunk(
    "create/workerDataValidationCheck", async (formData) => {
        console.log('------------------', formData)
        return axios
            .put(`${url}/workerDataValidationCheck/${formData.workerTextId}/`, formData, config)
            .then((response) => response.data)
    }
)
export const zipCodeRejectedPost = createAsyncThunk(
    "create/DataValidationNotice", async (formData) => {
        // console.log(formData)
        return axios
            .put(`${url}/zipCodeAcceptReject/${formData.workerTextId}/`, formData, config)
            .then((response) => {
                response.data.localData = formData.localData;
                return response.data;
            })
    }
)
export const workerPriceDeclinePostApi = createAsyncThunk(
    "create/PriceDecline", async (formData) => {
        console.log(formData)
        return axios
            .post(`${url}/servicePriceReviewDetails/${formData.workerTextId}/${formData.serviceTextId}/${formData.categoryTextId}`, formData, config)
            .then((response) => {
                response.data.localData = formData.localData;
                response.data.priceRejectedNote = formData.priceRejectedNote;
                return response.data;
            })
    }
)
export const workerPriceDeclineUpdate = createAsyncThunk(
    "create/PriceDeclineUpdate", async (formData) => {
        console.log(formData) 
        // rejectedServiceId
        // rejectedServiceId
        return axios
            .put(`${url}/priceRejectedContentUpdate/${formData.rejectedServiceId}/`, formData, config)
            .then((response) => {
                response.data.priceRejectedNote = formData.priceRejectedNote;
                return response.data;
            })
    }
)


const teamMembersSlice = createSlice({
    name: "teamMembers",
    initialState,
    reducers: {
        zipCodeList(state, action) {
            let zipRow = action.payload.zipRow;
            let zoneRow = action.payload.zoneRow;
            // state.subData[rootIndex].controls[childIndex][name] = (value);
            state.zoneList[zoneRow].zipcode[zipRow]['status'] = action.payload.status;
           
        },
        workerStatusUpdate(state, action) {
            state.profile.status = action.payload.status;
        },
    },
    extraReducers: (builder) => {

        builder.addCase(fetchTeamMembers.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchTeamMembers.fulfilled, (state, action) => {
            state.loading = false;
            state.teamMembers = action.payload.worker_list;
            state.cardData = action.payload.provider_statistic;
            state.error = "";
            console.log(action.payload)
        });
        builder.addCase(fetchTeamMembers.rejected, (state, action) => {
            state.loading = false;
            state.teamMembers = [];
            state.error = action.error;
        });



        builder.addCase(fetchProfile.pending, (state) => {
            state.loading = true;
            state.profile = '';
        });

        builder.addCase(fetchProfile.fulfilled, (state, action) => {
            state.loading = false;
            state.profile = action.payload;
            // console.log(action.payload.addressJsonWhileVerification)
            // console.log('profile',action.payload)
            // if (action.payload.addressJsonWhileVerification) {
            //     state.profile.addressJsonWhileVerification = JSON.parse(action.payload.addressJsonWhileVerification.replace(/'/g, "\""));
            // }
            state.error = "";
        });

        builder.addCase(fetchProfile.rejected, (state, action) => {
            state.loading = false;
            state.profile = [];
            state.error = action.error;
        });

        builder.addCase(fetchWorkerDocumentsView.pending, (state) => {
            state.loading = true;
            state.images = false;
        });
        builder.addCase(fetchWorkerDocumentsView.fulfilled, (state, action) => {
            state.loading = false;
            state.images = action.payload.documents; 
            state.error = "";
        });
        builder.addCase(fetchWorkerDocumentsView.rejected, (state, action) => {
            state.loading = false;
            state.images = [];
            state.error = action.error;
        });

        builder.addCase(fetchCategoryServiceWithAreas.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchCategoryServiceWithAreas.fulfilled, (state, action) => {
            state.loading = false;
            // state.profile = action.payload;
            state.category = action.payload.category_list;
            state.zoneList = action.payload.zone_list;

            state.error = "";
        });
        builder.addCase(fetchCategoryServiceWithAreas.rejected, (state, action) => {
            state.loading = false;
            state.category = [];
            state.zoneList = [];
            state.error = action.error;
        });
        builder.addCase(fetchProviderOrders.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchProviderOrders.fulfilled, (state, action) => {
            state.loading = false; 
            if(action.payload.order_list){
            state.order = action.payload.order_list;
            }
            

            state.error = "";
        });
        builder.addCase(fetchProviderOrders.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
        builder.addCase(fetchServicePriceListApi.pending, (state) => {
            state.loading = true;
            state.providerPrice =false;
            state.dedeclineMessage= false;
        });
        builder.addCase(fetchServicePriceListApi.fulfilled, (state, action) => {
            state.loading = false;
            state.providerPrice = action.payload.results;
            if(action.payload.results.pricingStatus==='Rejected'){
            state.dedeclineMessage = action.payload.results.rejected_contents
            }
            state.error = ""; 
        });
        builder.addCase(fetchServicePriceListApi.rejected, (state, action) => {
            state.loading = false;
            state.providerPrice = false;
            state.error = action.error;
        });
        builder.addCase(fetchProviderTipManagementApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchProviderTipManagementApi.fulfilled, (state, action) => {
            state.loading = false;
            state.tips = action.payload.tip_list;
            // console.log(action.payload)
            state.error = "";
        });
        builder.addCase(fetchProviderTipManagementApi.rejected, (state, action) => {
            state.loading = false;
            state.tips = [];
            state.error = action.error;
        });
        
        builder.addCase(fetchReviewRatingManagementApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchReviewRatingManagementApi.fulfilled, (state, action) => {
            state.loading = false;
            state.reviewRatingData = action.payload.review_data;
            state.error = "";
            // console.log(action.payload)
        });
        builder.addCase(fetchReviewRatingManagementApi.rejected, (state, action) => {
            state.loading = false;
            state.providerListByPrice = [];
            state.error = action.error;
        });

        builder.addCase(fetchProviderPriceListApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchProviderPriceListApi.fulfilled, (state, action) => {
            state.loading = false;
            if(action.payload.service_list){
                state.providerListByPrice = action.payload.service_list;
            }
            if(action.payload.serviceSummary){
                state.serviceSummary = action.payload.serviceSummary;
            }
            // state.providerListByPrice = action.payload.service_list;
            state.error = "";
            console.log(action.payload)
        });
        builder.addCase(fetchProviderPriceListApi.rejected, (state, action) => {
            state.loading = false;
            state.providerListByPrice = [];
            state.error = action.error;
        });

        builder.addCase(changeTeamMemberStatus.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(changeTeamMemberStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.profile.status = action.payload.status;
            state.error = "";
            // console.log(action.payload)
        });
        builder.addCase(changeTeamMemberStatus.rejected, (state, action) => {
            state.loading = false;
            // state.providerListByPrice = [];
            state.error = action.error;
        });

        builder.addCase(workerPriceDeclinePostApi.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(workerPriceDeclinePostApi.fulfilled, (state, action) => {
            state.loading = false;
            // state.dedeclineMessage = action.payload;
            state.error = "";
            console.log(action.payload)
        });
        builder.addCase(workerPriceDeclinePostApi.rejected, (state, action) => {
            state.loading = false;
            // state.providerListByPrice = [];
            state.error = action.error;
        });

        builder.addCase(workerPriceDeclineUpdate.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(workerPriceDeclineUpdate.fulfilled, (state, action) => {
            state.loading = false;
            state.dedeclineMessage.priceRejectedNote = action.payload.priceRejectedNote;
            state.error = "";
            console.log(action.payload)
        });
        builder.addCase(workerPriceDeclineUpdate.rejected, (state, action) => {
            state.loading = false;
            // state.providerListByPrice = [];
            state.error = action.error;
        });

    },
});


export const {
    zipCodeList, workerStatusUpdate
} = teamMembersSlice.actions;
export default teamMembersSlice.reducer


 
