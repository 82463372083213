import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";
// import removeUser from '../components/RemoveUser';


const initialState = {
    loading: false,
    data: null,
    error: '',
    res: false,
}



let token = localStorage.getItem("token");

let config = {}
//  const currentTime = new Date().getTime();

export const authRequest = createAsyncThunk(
    'user/login', async (formData) => {
        return await axios
        .post(`${BASE_URL}/login`, formData)
        .then((response) => {  
        localStorage.setItem("textId", response.data.textId)
        localStorage.setItem("token", response.data.token)
        localStorage.setItem("userName", response.data.firstName+' '+response.data.lastName )
        // localStorage.setItem("expireDate", currentTime + 8.64e+7)
          console.log('::',response.data) 
        config = {
          headers: {
              Authorization: `Token ${response.data.token}`,
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': '0',
            } 
          }
  //  console.log('response.data.token:', config);
  //  console.log('response.data:', response.data);
        return  response.data})
  }
)

export const createUser = createAsyncThunk(
    'user/register', async (formData, config) => {
        return await axios
        .post(`${BASE_URL}/register/`, formData)
        .then((response) => {  
          return  response.data})
    }
)
export const saveFCMTokenPost = createAsyncThunk(
    'user/saveToken', async (formData) => {
      let token =localStorage.getItem("token");
      config = {
            headers: {
                Authorization: `Token ${token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            }
        }
      
        return await axios
        .post(`${BASE_URL}/service/SaveDeviceToken/`, formData, config)
        .then((response) => {  
          return  response.data})
    }
)

export const removeFCMTokenPost = createAsyncThunk(
    'user/saveToken', async (formData) => {
      let token =localStorage.getItem("token");
      console.log('token====', token)
      console.log('config', config)
      config = {
            headers: {
                Authorization: `Token ${token}`,
                'Cache-Control': 'no-cache',
                'Pragma': 'no-cache',
                'Expires': '0',
            }
        }
      
        return await axios
        .post(`${BASE_URL}/service/RemoveDeviceToken/`, formData, config)
        .then((response) => {  
          return  response.data})
    }
)
 
export const userLogRemove = createAsyncThunk(
    'user/logout', async (formData) => {
        // const token = localStorage.getItem("token");
        // const textId = localStorage.getItem("textId");
        console.log(formData)
        config = {
            headers: {
                Authorization: `Token ${token}`,
                'Cache-Control': 'no-cache',
               'Pragma': 'no-cache',
                'Expires': '0',
            }
        }
        return await axios
        .post(`${BASE_URL}/logout/`, formData, config)
        .then((response) => {  
          return  response.data})
    }
)

 

const authSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: (builder) => {
      builder.addCase(authRequest.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(authRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = "";
        state.res= true;  
      });

      builder.addCase(authRequest.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.error;

        console.log('lll:',action.payload)
      }); 

      builder.addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = "";
         state.res= 'createUser'; 
      });
      builder.addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.error;
      });
      
      builder.addCase(userLogRemove.pending, (state) => {
        state.loading = true;
      
      });
      builder.addCase(userLogRemove.fulfilled, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = "";
        state.res= true;
 

      });
      
      builder.addCase(userLogRemove.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.error;
        state.res= false; 
       
      });


       builder.addCase(saveFCMTokenPost.pending, (state) => {
        state.loading = true;
       
      });
      builder.addCase(saveFCMTokenPost.fulfilled, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = "";
        state.res= true;

      });
      
      builder.addCase(saveFCMTokenPost.rejected, (state, action) => {
        state.loading = false;
        
      });
      

    },
  });
  


export default authSlice.reducer