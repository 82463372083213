import {useEffect, useState} from 'react' 
import {useDispatch, useSelector} from 'react-redux';
import ItemConfigDataTable from '../../dataTables/ItemConfigDataTable' 
import {Button, Image, Form} from 'react-bootstrap';
import ServiceItemAddForm from '../setting/ServiceItemAddForm';
// import FormBuilder from './FormBuilder';
import {BASE_URL} from '../../BaseUrl';
import NavBarUpdate from "../setting/NavBarUpdate";
import Layout from '../../components/Layout';
// import ServiceItemConfiguration from '../setting/ServiceItemConfiguration';
import { fetchNotificationStage, updateNotificationStage } from '../../reducers/NotificationSlice';

import {getStatusName} from '../../utility/GetStatusName';

import * as moment from 'moment'
// import {  itemRankUpdateApi, fetchServiceItem } from '../../reducers/FormBuilderSlice';
import { ToastContainer, toast } from 'react-toastify';

 

const JointNotificationToStage = () => {
    const { stages, notification_list } = useSelector((state) => state.notification)

    const [configuration, setConfiguration] = useState(true);
    const [show, setShow] = useState(false);
    
    const dispatch = useDispatch()

const columns = [
        {name: "Stage Title", sortable: true, cell: (row) => row.title},
        {name: "Status", cell: (row) => <span className={`status ${row.status}`}> {getStatusName(row.status)}  </span>},
        {name: "Updated", cell: (row) => moment(new Date(row.updated)).format("DD MMM YYYY,  h:mm A ")  },
        // {name: "Show Multiple Time", cell: (row) => row.actionMultipleTime  },
        // {name: "Try multiple times or not",   cell: (row) => <div className='item-switch'>
        //     <Form.Check type="switch" name='actionMultipleTime' defaultChecked={row.actionMultipleTime === 'Y'? true:false}  
        //     onChange={(e)=>{
            
        //     let localData = null;
        //     stages.map((e, index)=>e.id === row.id? localData = index:''); 
        //     const item ={};
        //     item.id =  row.id;
        //     item.textId =  row.textId;
        //     item.localData = localData;
        //     item.actionMultipleTime= e.target.checked?'Y':'N';
        //     item.notificationMessageSettingsTextId= null;
        //     console.log(item, e.target)
        //     const res= dispatch(updateNotificationStage(item));
        //     res.then(results => {
        //         if(results.error) {toast(` Something went wrong. Please try again!`) }
        //     }).catch(error => {
        //         alert('error ---- false')
        //     });
        //     }}/>
        //     </div>
        // },
        // {name: "created", cell: (row) => row.updated },
        {name: "Notification", cell: (row) => 
            <select className={`form-select`} name="notificationMessageSettingsTextId" defaultValue={row.notificationMessageSettingsTextId} 
            onChange={(e)=>{
                let localData = null;
                stages.map((e, index)=>e.id === row.id? localData = index:''); 
                const item ={};
                item.id =  row.id;
                item.textId =  row.textId;
                item.localData = localData;
                item.actionMultipleTime = row.actionMultipleTime;
                item.notificationMessageSettingsTextId= e.target.value;
                const res= dispatch(updateNotificationStage(item));
                res.then(results => {
                    if(results.error) {toast(` Something went wrong. Please try again!`) }
                }).catch(error => {
                    alert(error)
                });
            }}>
                <option value=''> select  </option>
                {notification_list.map((item) => (
                <option selected={item[1]===row.notificationMessageSettingsTextId} value={item[1]}> {item[0]}</option>))}
            </select>  
        },
    //     {name: "Registration Process",   cell: (row) => <div className='item-switch'>
    //     <Form.Check type="switch" name='isRegistrationProcess' defaultChecked={row.isRegistrationProcess === 'Y'? true:false}  
    //     onChange={(e)=>{
            
    //         let localData = null;
    //         stages.map((e, index)=>e.id === row.id? localData = index:''); 
    //         const item ={};
    //         item.id =  row.id;
    //         item.textId =  row.textId;
    //         item.localData = localData;
    //         item.name= e.target.name;
    //         item.value= e.target.checked?'Y':'N';
    //         // console.log(item)
    //         const res= dispatch(updateNotificationStage(item));
    //         res.then(results => {
    //             if(results.error) {toast(` Something went wrong. Please try again!`) }
    //         }).catch(error => {
    //             alert('error ---- false')
    //         });
    // }}/>
    // </div>
    //     },
    ]

    useEffect(() => {
        dispatch(fetchNotificationStage())
        // dispatch(breadcrumbTree({'Joint Notification To Stage': ''}));

        document.title = 'Item Configuration';
    }, [dispatch])


    return (
        <>
            <Layout page_title={'Joint Notification To Stage'} breadcrumb={{'Connect To Stage': ''}} 
            component={ <> <div className='data-table-wrapper newNotification-table'>
                <ItemConfigDataTable data={stages} columns={columns} sortable={true} />
                </div>
                </>
            }></Layout>
        </>
    )
}

export default JointNotificationToStage;