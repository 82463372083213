import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    loading: false,
    control: {},
    error: '',
    res: false
}

const url = `${BASE_URL}/`

// const token = localStorage.getItem("token")

const config = {
    headers: {
      // Authorization: `Token ${token}`,
       Authorization: `Token b45a010cf04d8008242b61426a5834f0b7753200`,
    }
}

export const fetchControl  = createAsyncThunk(
    'fetch/service/customize/id', async (path) => {
        return axios
        .get(`${url}${path}`, config)
        .then((response) =>  response.data)
    }
)
 


export const createControl = createAsyncThunk(
  "create/customize/id", async (formData) => {
    return axios
    .post(`${url}/${formData.path}`, formData, config)
    .then((response) => response)
  }
)

export const updateControl = createAsyncThunk(
  "update/customize/id", async (formData) => {
    return axios
    .put(`${url}${formData.path}/${formData.textId}`, formData, config)
    .then((response) => formData)
  }
)

export const deleteControl = createAsyncThunk(
  "delete/service/customize/id", async (formData) => {
    return axios
    .delete(`${url}${formData.path}/${formData.textId}`, config)
    .then((response) => formData)
  }
)

// export const deleteCoyntrol = createAsyncThunk(
//   "update/customize/id", async (formData) => {
//     return axios
//     .put(`${url}${formData.path}/${formData.textId}`,  config)
//     .then((response) =>  response.data)
//   }
// )


const formControlSlice = createSlice({
    name: "control",
    initialState,
    extraReducers: (builder) => {
      builder.addCase(fetchControl.pending, (state) => {
        state.loading = true;
         state.control =[];
          state.error = ''; 
      });
      builder.addCase(fetchControl.fulfilled, (state, action) => {
        state.loading = false;
        state.control = action.payload;
        // console.log("fetchControl.fulfilled") 
        // console.log(action.payload.controls) 
      });

      builder.addCase(fetchControl.rejected, (state, action) => {
        state.loading = false; 
          state.control = [];
        state.error = action.error; 
        //  console.log("fetchControl.rejected") 
      });
      
      // createControl
      builder.addCase(createControl.fulfilled, (state, action) => {
        state.loading = false;
        state.control.controls.push(action.payload.data);
        state.error = ""; 
        // console.log(action.payload.data)
      });
      builder.addCase(createControl.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error; 
        console.log("🚀 ~ file: FormControlSlice.js:98 ~ builder.addCase ~ action:", action.error)
      });

      
      builder.addCase(updateControl.fulfilled, (state, action) => {
        state.loading = false;
        state.control.controls[action.payload.localData] = action.payload;
        state.error = "";
        // console.log(action.payload);
      });

      builder.addCase(updateControl.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error; 
      });


      // deleteControl

       builder.addCase(deleteControl.fulfilled, (state, action) => {
        state.loading = false;
        // state.control.forms.push(action.payload.data); 
          state.control.controls.splice(action.payload.localData, 1);
          console.log(action.payload);
      });
      builder.addCase(deleteControl.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
  
      });
    },
  });
  


export default formControlSlice.reducer