import React, {useState} from 'react';
import ReviewsDataTable from "../../dataTables/ReviewssDataTable";
import {BASE_URL} from "../../BaseUrl";
import StarRatingFromUserComponent from "./StarRatingFromUserComponent";
import ActionModelComponent from "./ActionModalComponent";
import moment from "moment";
import {filterDataTable4Col} from "../../utility/FilterDataTable";
import ExportFile from "../utility-component/ExportFile";

const ReviewsRatingsPageComponent = ({data, filterBy, getCountRating}) => {
    const [query, setQuery] = useState('');
    const [rowData, setRowData] = useState(false);
    console.log(data)
    const columns = [
        {
            name: "Services",
            minWidth: '250px',
            className: 'review-column',
            sortable: true, selector: (row) => <>
                <div className="w-[100%] justify-start items-center gap-3 inline-flex">
                    <img className="w-11 h-11 my-1 rounded-lg" src={`${BASE_URL}/${row.serviceImage}`} alt=''/>
                    <div className=" w-[100px] text-black text-sm font-medium font-['Inter']">{row.serviceTitle}

                    </div>
                </div>
            </>
        },
        {
            name: "Reviewer",
            minWidth: '250px',
            sortable: true,
            selector: (row) => <>
                <div className="w-[239px] justify-start items-center gap-3 inline-flex">
                    {/*<img className="w-11 h-11 rounded-[100px]" src="https://via.placeholder.com/44x44"/>*/}
                    <img className="w-11 h-11 my-1 rounded-full" src={`${BASE_URL}/${row.userImage}`} alt=''/>
                    <div className="flex-col justify-center items-start inline-flex">
                        <div className="text-black text-sm font-medium font-['Inter']">{row.userName}</div>
                        <div
                            className="text-stone-500 text-xs font-normal font-['Inter'] leading-snug">hassanjahid@gmail.com
                        </div>
                    </div>
                </div>
            </>
        },
        {
            name: "Review",
            sortable: true,
            minWidth: '250px',
            selector: (row) => <>
                <div className="w-[100%] py-1 flex-col justify-start items-start gap-1 inline-flex">
                    <StarRatingFromUserComponent rating={row.rateForWorkerByEndUser}/>
                    {/*<div className="justify-start items-start gap-2 inline-flex"></div>*/}
                    <div className="self-stretch text-stone-500 text-sm font-medium font-['Inter'] leading-snug">

                        {row.commentForWorkerByEndUser}
                    </div>
                </div>
            </>
        },
        {
            name: "Provider Name",
            minWidth: '200px',
            sortable: true, selector: (row) => row.providerName
        },
        {
            name: "Date",
            minWidth: '120px',
            sortable: true,
            selector: (row) =>  moment(new Date(row.reviewRatingDate)).format("DD MMM YYYY,  h:mm A")
        },
        {
            name: "Status",
            sortable: true,
            minWidth: '120px',
            selector: (row) => <span className={`status ${row.reviewRatingStatus}`}> {row.reviewRatingStatus}</span>
        },
        {
            name: "Action", minWidth: '10px', sortable: true, selector: (row) => <>
                <div className="text-center w-[30px]" onClick={() => setRowData(row)}>
                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </div>
                {row.orderTextId === rowData.orderTextId && <div className='relative w-[100%]'>
                    <ActionModelComponent data={row} close={setRowData}/>
                    <div className='fixed bg-transparent w-[100%] h-[100%] top-0 left-0 z-40'
                         onClick={() => setRowData('')}></div>
                </div>}
            </>
        },
    ]

    // let viewTableData = data;
    let viewTableData = data.filter(item => item['reviewRatingStatus']?.toLowerCase().includes(filterBy.toLowerCase()))
    let tableData1 = viewTableData.filter(item => item['serviceTitle']?.toLowerCase().includes(query.toLowerCase()))
    let tableData2 = viewTableData.filter(item => item['userName']?.toLowerCase().includes(query.toLowerCase()))
    let tableData3 = viewTableData.filter(item => item['commentForWorkerByEndUser']?.toLowerCase().includes(query.toLowerCase()))
    let tableData4 = viewTableData.filter(item => item['providerName']?.toLowerCase().includes(query.toLowerCase()))
    let uniqueData = filterDataTable4Col('orderTextId', tableData1, tableData2, tableData3, tableData4);
    getCountRating(uniqueData.length);

    const headers = ['Order Id','User Name','EndUser Id' ]
    const jsonObject = [];
    if (uniqueData) {
        // Select only the desired properties for each order
        data.map((order, index) => 
            jsonObject.push(Object.values({
                orderTextId: order.orderTextId,
                userName: order.userName,
                endUserTextId: order.endUserTextId
            }))
        )
    }

    return (
        <>
            <div className='data-table-wrapper reviewsDataTable'>
                <div className="w-[100%] flex gap-2.5 relative mb-3">
                    {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                    <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px]'>
                        <input name="search" placeholder={'Search by Provider, customer, Order Id...'}
                               type="text"
                               className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                               value={query ? query : ''}
                               onChange={(e) => {
                                   setQuery(e.target.value)
                               }}/>
                        <div className={"absolute left-[12px] top-[12px]"}><i className="fa fa-search"
                                                                              aria-hidden="true"></i></div>
                        <div
                            className="export-btn absolute right-0 top">
                            <ExportFile data={jsonObject} fileName={'Rating-filter-data'} headersData={headers}/>
                        </div>
                    </div>

                </div>

                <ReviewsDataTable data={uniqueData ? uniqueData : data} columns={columns}/>
            </div>
        </>
    );
};

export default ReviewsRatingsPageComponent;