import React, { useState} from 'react';
import {Link} from "react-router-dom";
import CustomerOrderDataTable from "../../dataTables/CustomerOrderDataTable";
import {BASE_URL} from "../../BaseUrl";
import moment from "moment";
import {Form} from "react-bootstrap";
import {filterDataTable3Col} from "../../utility/FilterDataTable";


const CustomerOrderComponent = ({data}) => {
    const [query, setQuery] = useState('');
    const [filterBy, setFilterBy] = useState('');
    const columns = [
        {name: "Order ID", minWidth: '250px', sortable: true, cell: (row) => row.orderTextId},
        {
            name: "Date",
            sortable: true,
            cell: (row) =>
                <div className={`date`}>
                    <span> {moment(new Date(row.orderDate)).format("DD MMM YYYY")}</span> <br/>
                    <span>{moment(new Date(row.orderDate)).format(" h:mm a")}</span></div>
        },


        {
            name: "Service", minWidth: '250px', sortable: true, cell: (row) => <>
                <div className="w-[100%] justify-start items-center gap-3 inline-flex">
                    <img className="w-11 h-11 my-1 rounded-lg" src={`${BASE_URL}${row.serviceImage}`} alt=''/>
                    <div className=" w-[100px] text-black text-sm font-medium font-['Inter']">{row.serviceName}

                    </div>
                </div>
            </>
        },
        {name: "Provider", sortable: true, cell: (row) => row.workerName},
        {name: "Price", sortable: true, cell: (row) => `$${row.amount}`},
        {name: "Status", cell: (row) => <span className={`status ${row.orderStatus}`}> {row.orderStatus}</span>},
        {
            name: "Action",  cell: (row) => <Link to={`./${row.orderTextId}`}
                                                                    className="btn btn-default"> Details</Link>
        },


    ]

    let uniqueData = false;
    let viewTableData = data;

    if (data) {
        // viewTableData = data.filter(item => item['status']?.toLowerCase().includes(filterBy.toLowerCase()))
        let tableData1 = viewTableData.filter(item => item['serviceName']?.toLowerCase().includes(query.toLowerCase()))
        let tableData2 = viewTableData.filter(item => item['orderTextId']?.toLowerCase().includes(query.toLowerCase()))
        let tableData3 = viewTableData.filter(item => item['workerName']?.toLowerCase().includes(query.toLowerCase()))
        uniqueData = filterDataTable3Col('orderTextId', tableData1, tableData2, tableData3);
    }
    // uniqueData =   data.filter(item => item['serviceName']?.toLowerCase().includes(query.toLowerCase()))
// console.log(uniqueData)

    return (
        <>

            <div className='data-table-wrapper'>

                <div className="w-[100%] flex gap-2.5 relative mb-3">
                    {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                    <div className='search-from-data-table w-[80%] bg-neutral-100 rounded-lg'>
                        <input name="search" placeholder={'Search by Services Title or customer...'}
                               type="text"
                               className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                               value={query ? query : ''}
                               onChange={(e) => {
                                   setQuery(e.target.value)
                               }}/>

                    </div>
                    <div
                        className="w-[120px] absolute right-0 top bg-neutral-100 rounded-lg justify-center items-center flex">
                        <Form.Select
                            aria-label="Default select example"
                            name="searchType"
                            className="w-full"
                            defaultValue={filterBy}
                            onChange={(e) => setFilterBy(e.target.value)}
                        >
                            <option value=''>Filter By</option>
                            <option value='Pending'>Pending</option>
                            <option value='Confirmed'>Confirmed</option>
                            <option value='Partial Confirmed'>Partial Confirmed</option>
                        </Form.Select>
                    </div>
                    {/*<div*/}
                    {/*    className="w-[130px] absolute right-0 top  px-6 py-2 bg-neutral-100 rounded-lg justify-center items-center gap-2.5 flex">*/}
                    {/*    <i className="w-5 h-5 relative fa fa-download" aria-hidden="true"></i>*/}
                    {/*    <button className="text-neutral-600 text-base font-semibold font-['Inter']">Export*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>


                <CustomerOrderDataTable data={uniqueData ? uniqueData : viewTableData} columns={columns}/>
            </div>


        </>

    );
}

export default CustomerOrderComponent;