// import Button from 'react-bootstrap/Button';
// import Alert  from 'react-bootstrap/Alert';
// import { useState } from "react";
// import { ReactComponent as MySVG } from '../../assets/images/upload-img-icon.svg';

// import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import {BASE_URL} from "../../BaseUrl";
// import Form from 'react-bootstrap/Form';
// import {createUser} from '../../reducers/AuthSlice';
import {useDispatch, useSelector} from "react-redux";
import Layout from "../../components/Layout";
// import {useForm} from 'react-hook-form';
// import SideBarData from "../../data/leftSideBar";
import { useNavigate, useParams} from "react-router-dom";
// import {NavLink, useLocation} from "react-router-dom";

import React, {useEffect, useState} from 'react';
import DraggableComponent from './DraggableComponent';
import DroppableComponent from './DroppableComponent';
import ItemConponent from './ItemConponent';

// import React from 'react';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import DroppableComponentInner from './DroppableComponentInner';
// import DraggableComponent from './DraggableComponent';
// import DroppableComponent from './DroppableComponent';
import {createItemConfiguration, fetchConfiguration,  successConfirmation,  attributeSwap} from '../../reducers/FormBuilderSlice';

// import alert from "bootstrap/js/src/alert";
import SuccessComponent from "../../modal/SuccessComponent";
import LoadingComponent from "../../modal/LoadingComponent";
import ErrorComponent from "../../modal/ErrorComponent";


const ServiceItemConfiguration = ({close}) => {
    // const {register, handleSubmit, formState: {errors}, reset} = useForm();
    // const { configuration_data, title } = useSelector((state) => state.category)
    const fetchData = useSelector((state) => state.formBuilderData)
    const data = fetchData.dropitems;
    const dispatch = useDispatch();
    const {item} = useParams();
    // const location = useLocation();
    const navigate = useNavigate();
    // const [droppedItems, setDroppedItems] = useState([]);
    const [successModal, setSuccessModal] = useState(false);
    // const urlArr = location.pathname.split("/");

    const openSuccessModal = (url) => setSuccessModal(url ? url : false);
    const closeSuccessModal = () => {
        handleSuccess()
        navigate(successModal);
    };
    const handleSuccess = () => dispatch(successConfirmation());

// console.log('urlArr:',urlArr)
// console.log('urlArr:',urlArr[urlArr.length-1])
    // const handleDrop = (itemName) => {
    //     setDroppedItems((prevItems) => [...prevItems, itemName]);
    // };
    const handleSave = (param) => {
        const configData ={status:param, configurationData:data}
        // data.status=param;
        console.log('configuration data:::',configData)
        const res = dispatch(createItemConfiguration(configData));
        res.then((results)=> {
            if(results.error){
                console.log('results.error', results.error)
            }else{
            openSuccessModal(`/service-items/${item}#root`);
            }
        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
        // if (res) {
        //     // console.log(`{/service-items/${item}}`)
        //     navigate(`/service-items/${item}#root`);
        // }
    };

        const handleAttributeRank = (parent, index, rank) => { 
            const result = Array.from(data[parent].controls);
            const [removed] = result.splice(index, 1);
            result.splice(rank, 0, removed);
            dispatch(attributeSwap({lavel:parent, controlData:result}))
            // return result;
       
        // setAttrRank(rank)
        //  console.log('==',reorder(parent, index, rank))
        // const res=dispatch(attributeRankUpdate({lavel:index, attr:parent, rank:rank}))
    };

    // console.log(configuration_data)
    useEffect(() => {
        dispatch(fetchConfiguration(item));
        // res.then((results)=> {
        //     dispatch(setConfigurationData(configuration_data))
        // })
    }, [dispatch, item])

    // if (title) {
    //     // document.title = `Item Configuration`;
    //     // dispatch(breadcrumbTree({
    //     //     'Service Item List': 'service-items',
    //     //     // [title]: textId,  'Configuration': ''
    //     // }));
    // }
    return (
        <Layout page_title={'Item Configuration'} 
        component={
            <>
                <div className="section-two-column form-builder">
                    <DndProvider backend={HTML5Backend}>
                        <div className="left-block item-configuration">
                            <legend className="config-title">{fetchData.info.title} </legend>
                            {/* <MySVG /> */}

                            {data.map((item, index) => (
                                <>
                                    <fieldset className="field-builder px">
                                        <ItemConponent dataItem={item} index={index} parrent={null} rank={handleAttributeRank}/>
                                        {item.btnName === 'labelField' ? (
                                            <>
                                                {item.controls === undefined ? null : item.controls.map((dataItem, dataIndex) => (
                                                    <ItemConponent dataItem={dataItem} index={index} parent={dataIndex} rank={handleAttributeRank} totalItem={item.controls.length}/>
                                                ))}
                                                <DroppableComponentInner index={index}/>

                                            </>
                                        ) : null}
                                    </fieldset>
                                </>
                            ))}

                            {data.length > 0 &&
                                <div className="save-option">
                                    <button className="btn draft-btn" onClick={() => handleSave('D')}>Save as Draft
                                    </button>
                                    <button className="btn save-btn" onClick={() => handleSave('Y')}>Save</button>
                                </div>}


                            <DroppableComponent/>


                        </div>
                        <div className="right-block">
                            <div className="drag-items">
                                <div className='drag-title'>
                                    Select an Option
                                </div>


                                <DraggableComponent btnName="labelField" dropTitle='Label' labelType='text'
                                                    label="Label" controls={['d']} rel='root' options='' limit={0}/>
                                <DraggableComponent btnName="textField" dropTitle='Text Input' labelType='text'
                                                    label='Text Input' options='' limit={0}/>
                                <DraggableComponent btnName="textField" dropTitle='Number Input' labelType='number'
                                                    label='Number Input' options='' imit={0}/>
                                <DraggableComponent btnName="comboNumberField" dropTitle='Combo Box / Number'
                                                    labelType='selectNumber' label="Combo Box / Number" rel='controls'
                                                    limit={10} options=''/>                                                    
                                <DraggableComponent btnName="selectField" dropTitle='Combo Box / Dropdown'
                                                    labelType='select' label="Combo Box / Dropdown"
                                                    options={[{title:'Option1',isActive:null}, {title:'Option2',isActive:null}, {title:'Option3',isActive:null}]} limit={0}/>
                                <DraggableComponent btnName="radioField" dropTitle='Radio Button' labelType='radio'
                                                    label="Radio Button"
                                                    options={[{title:'Radio Button 1',isActive:null}, {title:'Radio Button 2',isActive:null}, {title:'Radio Button 3',isActive:null}]}
                                                    limit={0}/>
                                <DraggableComponent btnName="checkboxField" dropTitle='Checkbox' labelType='checkbox'
                                                    label="Checkbox" rel='controls'
                                                    options={[{title:'Checkbox option1',isActive:null}, {title:'Checkbox option2',isActive:null}, {title:'Checkbox option3',isActive:null}]}
                                                    limit={0}/>
                                <DraggableComponent btnName="textareaField" dropTitle='Some Text / Paragraph'
                                                    labelType='textarea' rel='controls' label="Some Text / Paragraph"
                                                    options='' limit={0}/>
                                <DraggableComponent btnName="textareaField" dropTitle='i - Instructions'
                                                    labelType='textarea' label="i - Instructions" rel='controls'
                                                    options='' limit={0}/>
                                {/* <DraggableComponent name="Box 5" data={dataset5} /> */}
                                {/* Add more draggable components */}
                            </div>
                        </div>

                    </DndProvider>


                </div>
                {fetchData.loading && <LoadingComponent message={''}/>}
                {successModal && <SuccessComponent close={closeSuccessModal}/>}
                {fetchData.error && <ErrorComponent close={handleSuccess} />}
            </>}></Layout>
    );
}

export default ServiceItemConfiguration;
// what is redux toolkits?
// how to create functional components?
