// import React, {useEffect, useRef } from 'react'
import React, {useState } from 'react'
// import AreaComponent from "../provider/AreaComponent";
import ZipCodeSliderComponent from "./ZipCodeSliderComponent";
// import PriceDeclineModal from "../../modal/PriceDeclineModal";
// import {workerPriceDeclinePostApi} from "../../reducers/TeamMemberSlice";
// import {useDispatch,  } from "react-redux";
// import { useSelector} from "react-redux";

const PricePackage = ({each, index, serviceType, title, oldData, status }) => {
    const [show, setShow] = useState(true)
    // const [priceDeclineModal, setPriceDeclineModal] = useState(false)
    const handleClick = (e) => show?setShow(false):setShow(true);
    // const priceDeclineModalClose = (e) => setPriceDeclineModal(false);
    // const dispatch = useDispatch();
    console.log('oldData: ' + oldData)
 
    return (
        <>
        <div className={show ? 'accordion-item active' : 'accordion-item'}>
            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                <button onClick={() => handleClick(show)}
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne">
                    {each.zoneTitle}
                </button>
            </h2>
            <ZipCodeSliderComponent data={each.zipCode}/>

        <div
            id="panelsStayOpen-collapseOne"
            className={show ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'}
            aria-labelledby="panelsStayOpen-headingOne">

            <div key={`each${index}`} className={show ? "accordion-body active" : "accordion-body"}>
                <table className='table service-attributes-table'>
                    <tbody>
                    <tr key={`each-tr-${index}`}>
                        <th className='bold options'>Options</th>
                        {/* <th className='options'>Options</th> */}
                        <th className='bold pricing-by'>Pricing by</th>
                    
                        {status!=='Approved' && oldData ? <>
                            <th className='bold estimated-time'>Old Estimated times</th>
                            <th className="bold price">Old Price</th>
                            <th className='bold estimated-time'>Propose Estimated times</th>
                            <th className="bold price">Propose Price</th>
                        </>:<>
                            <th className='bold estimated-time'>Estimated times</th>
                            <th className="bold price">Price</th>
                        </>}
                    </tr>
                    </tbody>
                    {serviceType==='Bundle' ? <tr key={`Bundle`}>
                            <td className="text-dark">{title && title} </td>
                            <td className="text-dark">{serviceType}</td>
                        {status!=='Approved' && oldData ? <>
                            <td className="text-dark">{oldData.estTime} </td>
                            <td className="text-dark text-right">
                                <span>${oldData.minimumPrice}</span> 
                            </td>
                            <td className="text-dark text-right">{each.estTime} </td>
                            <td className="text-dark text-right">
                                <span>${each.minimumPrice}</span> 
                            </td>
                        </>:<>
                            <td className="text-dark text-right">{each.estTime}</td>
                            <td className="text-dark text-right">
                                <span>${each.minimumPrice}</span>
                            </td>
                        </>}
                            
                        </tr>:<>
                                </>}
                    {each.attributes && each.attributes.map((attribute, index_1) => (
                        <tbody>{attribute.isPricing ==='Yes'&& attribute.options.length>1 ? <>
                            <tr key={`attributes-${index_1}`}>
                                <th className='options'>{attribute.title}</th> 
                                <th className='pricing-by'>--</th>
                                 {status!=='Approved' && oldData && <>
                                    <th className='estimated-time text-right'>--</th>
                                    <th className="price text-right">--</th></>}
                                <th className='estimated-time text-right'>--</th>
                                <th className="price text-right">--</th>
                            </tr>
                            
                        
                            {attribute.options.map((optionElement, index_2) => (
                            
                                <tr key={`options${index_2}`} className='options-group'>
                                <td className="text-dark">{optionElement.optionLabel && <span>{optionElement.optionLabel} </span>} </td>
                                <td className="text-dark">{serviceType}</td>
                                {status!=='Approved' && oldData ? <>
                                    <td className="text-dark text-right">{oldData.attributes[index_1].options[index_2].estTime} </td>
                                    <td className="text-dark text-right">
                                        <span>${oldData.attributes[index_1].options[index_2].price}</span> 
                                    </td>
                                    <td className="text-dark text-right">{optionElement.estTime}  </td>
                                    <td className="text-dark text-right">
                                        <span>${optionElement.price}</span> 
                                    </td>
                                     
                                </>:<>
                                    <td className="text-dark text-right">{optionElement.estTime}</td>
                                    <td className="text-dark text-right">
                                        <span>${optionElement.price}</span>
                                    </td>
                                </>}
                                

                            </tr>))}
                    
                        </>: <tr key={`attributes-${index}`}>
                                <td className="text-dark">
                                    {attribute.title && <span>{attribute.title}</span>} 
                                </td>
                                <td className="text-dark">{serviceType}</td>
                               {status!=='Approved' && oldData ? <>
                               <td className='text-right'>{oldData.attributes[index_1].options[0].estTime}</td>
                               
                                    {/* <td className="text-dark text-right">{oldData.attributes[index].options[0].estTime ? oldData.attributes[index].options[0].estTime:'None'}</td> */}
                                    <td className="text-dark text-right">
                                        <span>{oldData.attributes[index_1].options[0].price? `$${oldData.attributes[index_1].options[0].price}`:'None'}</span>
                                    </td>

                                    <td className="text-dark text-right">{attribute.options[0].estTime ? attribute.options[0].estTime:'None'}</td>
                                    <td className="text-dark text-right">
                                        <span>{attribute.options[0].price? `$${attribute.options[0].price}`:'None'}</span>
                                    </td>
                                  
                               </>:<>
                                    <td className="text-dark text-right">{attribute.options[0].estTime ? attribute.options[0].estTime:'None'}</td>
                                    <td className="text-dark text-right">
                                        <span>{attribute.options[0].price? `$${attribute.options[0].price}`:'None'}</span>
                                    </td>
                                </>}

                        </tr>}</tbody>
                    ))}
                </table>
            </div>
        </div>

    </div>
        
       
    </>
    );
};

export default PricePackage;