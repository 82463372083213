import {Form, Modal, Button, Image} from "react-bootstrap";
import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
import zone from "../../data/zone";
import {BASE_URL} from "../../BaseUrl";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {itemFormSchema} from "../../schemas/item";
import {useFormik} from "formik";
// import { createServiceSubItem } from '../../reducers/FormBuilderSlice';
import {
    updateCategory,
    createServiceSubItem,
    updateServiceSubItem,
    successConfirmation,
    updateServiceItem, createServiceItem
} from '../../reducers/CategorySlice';
import LoadingComponent from "../../modal/LoadingComponent";
import ErrorComponent from "../../modal/ErrorComponent";
import SuccessComponent from "../../modal/SuccessComponent";
import {setConfigurationData} from "../../reducers/FormBuilderSlice";
// 
import {createPageMetaSettings} from "../../reducers/DashboardSlice";
// import { Breadcrumb } from 'react-bootstrap/Breadcrumb';
import {checkStructure} from '../../utility/StringModify';
import {ClearCacheForImage} from '../../utility/ClearCache';
const ServiceSubItemForm = ({data}) => {
    const { title, identityArr, itemImage, itemIcon} = useSelector((state) => state.category)
    const { singleMeta, breadcrumbTree} = useSelector((state) => state.dashboard);
    const [breadcrumb, setBreadcrumb] = useState(false);
    // const [imageError, setImageError] = useState(false);
    const [thumbIcon, setIcon] = useState(false);
    const [thumbImage, setImage] = useState(false);
    const [cate, setCate] = useState(false);
    const {loading, error} = useSelector((state) => state.category)
    const dispatch = useDispatch();
    const [editorData, setEditorData] = useState('');
    let location = useLocation();
    const navigate = useNavigate();
    // const loca =location.pathname.split("/");
    const textId = window.location.hash.replace('#', '');
    const [successModal, setSuccessModal] = useState(false);
    const handleSuccess = ()=>dispatch(successConfirmation());
    const openSuccessModal = (url) => setSuccessModal(url ? url : false);
    const closeSuccessModal = (url) => {
        handleSuccess()
        // console.log('successModal', successModal)
        navigate(successModal);
    };
  const currentItem= {...breadcrumbTree, 'nur x55' : 'sdfgd' }
//   var newobj = Object.assign(breadcrumbTree,{"nur x44" : 'sdfgd'});

// console.table('newobj:', newobj)
console.table('currentItem: ', currentItem)

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const imageType = /^image\//;
        if (imageType.test(file.type)) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setImage(e.target.result);
            };

            reader.readAsDataURL(file);
        } else {
            alert('Please select an image file.');
        }
    };
    const handleIconChange = (event) => {
        const file = event.target.files[0];
        const imageType = /^image\//;
        if (imageType.test(file.type)) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setIcon(e.target.result);
            };

            reader.readAsDataURL(file);
        } else {
            alert('Please select an image file.');
        }
    };
    let initialValues = data;
    // if(itemIcon){
    //     initialValues= {...data, image: itemImage,icon: itemIcon}
    // }

    const {values, errors, handleBlur, handleChange, handleSubmit, setValues} = useFormik({

        initialValues: initialValues,

        onSubmit: (values, action) => {
            // if(data.textId){
            //     const res = dispatch(updateServiceItem(values));
            //     res.then((results)=> {
            //         // dispatch(fetchServiceItem())
            //         openSuccessModal();
            //         navigate(-1)
            //
            //     }).catch(error => {
            //         console.error('Error:', error);
            //     });
            //
            // }else{
            //     const res = dispatch(createServiceItem(values));
            //     res.then((results)=> {
            //         dispatch(setConfigurationData([]))
            //         openSuccessModal(`/service-items/${results.payload.textId}/configuration`);
            //
            //     }).catch(error => {
            //         console.error('Error:', error);
            //     });
            // }

            if(data.textId){
                const res = dispatch(updateServiceSubItem(values));
                    res.then((results)=> {
                        ClearCacheForImage(`${BASE_URL}${values.image}`)
                        ClearCacheForImage(`${BASE_URL}${values.icon}`)
                        openSuccessModal();
                        navigate(-1)
                    }).catch(error => {
                        console.error('Error:', error);
                    });
            }else{
            const res = dispatch(createServiceSubItem(values));

                res.then(results => {
                    if(!results.error){

                        
                        // const brTree= checkStructure(breadcrumbTree)
                        // const withCurrent= brTree.concat([{"name":values.title,"link":results.payload.textId}])
                        // dispatch(createPageMetaSettings({"title":values.title, "breadcrumbTree":JSON.stringify(withCurrent),"pageUrl":`/service-items/${results.payload.textId}`}))
                        // dispatch(createPageMetaSettings({"title":values.title, "breadcrumbTree":JSON.stringify(withCurrent).replace('service-items','service'),"pageUrl":`/service/${results.payload.textId}`}))
                

                        // const currentBreadcrumb= breadcrumbTree.push({[values.title]:results.payload.textId})
                        // dispatch(createPageMetaSettings({"title":values.title, "breadcrumbTree":JSON.stringify({...breadcrumbTree, [values.title] : results.payload.textId }),"pageUrl":`/service-items/${results.payload.textId}`}))
                  
                        openSuccessModal(`/service-items/${results.payload.textId}/configuration-sub`);
                    
                    }
                }).catch(error => {
                    console.error('Error:', error);
                });

                // navigate('/service-items/f9f22f47a1099c49d7c4a8698785b7/configuration');

            }
            // close(true);
        },
        //  validationSchema: data,

    });
    console.log('values:: ',values)
  useEffect(() => {
        // dispatch(breadcrumbTree({'Service Item List': 'service-items', 'Add Sub Item': 'add-item'}));
        document.title = 'Service New Item';
    }, [dispatch])

    return (
        <>
            <Form onSubmit={handleSubmit} encType="multipart/form-data" className="form-data">
                <div className="section-two-column add-service">
                    <div className="left-block w100-200">
                        <div className="field-row"><label className="label">Service Item Name</label>
                            <Form.Control type="text" name="title" placeholder="Service Sub Item Name" value={values.title}
                                          onChange={handleChange} onBlur={handleBlur}></Form.Control>
                            {errors.title && <p className="red">{errors.title}</p>}
                            {/* <Form.Control type="hidden" name="categoryTextId" value={data.categoryTextId} onChange={handleChange} onBlur={handleBlur}></Form.Control> */}
                        </div>

                        <div className="field-row"><label className="label">Item Description</label>
                            <textarea className="description form-control" name="details" rows="5" placeholder="Description"
                                      value={values.details} onChange={handleChange}></textarea>
                            {/*<CKEditor editor={ClassicEditor} details={editorData} onChange={handleChangea} />*/}
                            {errors.details && <p className="red">{errors.details}</p>}
                        </div>

                        {/* <div className="field-row check-box">
                               

                            <Form.Check type="switch" id="isBundleItem" label="is Bundle Item?" name="isBundleItem"
                                checked={values.isBundleItem === 'Y'? true:false}
                                onClick={(e)=>{
                                if(e.target.checked){
                                    setValues({...values, isBundleItem: 'Y'})
                                } else {
                                    setValues({...values, isBundleItem: 'N'})
                                }
                                    return handleChange
                                }}
                            />
                        </div> */}

                          {/* {thumbImage} <br /> */}
                                {/* {values.image} 
                                <br />
                                {values.icon} 
                                {thumbIcon}  */}
                    </div>
                    <div className="right-block w100-200">

                        <div className="category">
                            <strong className="title">Service subitem image</strong>
                            
                              {!thumbImage && !values.image && !itemImage && <span className="info">Add image for the service item </span>}

                            <div className={`img-view ${errors.image && 'border-red'}`} style={{backgroundImage: `url(${thumbImage?(thumbImage):values.image?(`${BASE_URL}${values.image}`):(`${BASE_URL}${itemImage}`)})`}}>
                                <label id="drop-area" className="drop-image">
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                     
                                <input type="file" hidden name="image" onChange={(e) => {
                                    handleImageChange(e);
                                    setValues({...values, image: e.target.files[0],});
                                    return handleChange
                                }} accept="image/*"/>
                                </label>                                     
                                {!thumbImage && !values.image && !itemImage && <>
                                    <div className="canvas"><img src={uploadImgIcon} alt=""/></div>
                                    <span className="drag-drop">Drop image here, or browse</span><br />
                                    <span className="support-image">Support: JPG, JPEG, PNG</span>
                                </>}
                            </div>
                        </div>

                         <div className="category">
                            <strong className="title">Service subitem icon dd</strong>
                            
                              {!thumbIcon && !values.icon && !itemImage && <span className="info">Add icon for the service subitem </span>}

                            <div className={`img-view ${errors.icon && 'border-red'}`} style={{backgroundImage: `url(${thumbIcon?(thumbIcon):values.icon?(`${BASE_URL}${values.icon}`):(`${BASE_URL}${itemIcon}`)})`}}> 
                                <label id="drop-area" className="drop-image">
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                     
                                <input type="file" hidden name="image" onChange={(e) => {
                                    handleIconChange(e);
                                    setValues({...values, icon: e.target.files[0],});
                                    return handleChange
                                }} accept="image/*"/>
                                </label> 
                                {!thumbIcon && !values.icon && !itemIcon && <>
                                    <div className="canvas"><img src={uploadImgIcon} alt=""/></div>
                                    <span className="drag-drop">Drop icon here, or browse</span><br />
                                    <span className="support-image">Support: JPG, JPEG, PNG</span>
                                </>}
                            </div>
                        </div>
                    </div>


                    <div className="save-option">
                        <button onClick={(e)=>{ e.preventDefault(); navigate(-1)}}> <span className="draft-icon"></span> Cancel</button>
                        {/* <button><span className="draft-icon"> <img src={uploadImgIcon} alt=""/> </span>Save as Draft </button> */}
                        <button className="save-btn">Save</button>
                    </div>
                </div>
            </Form>
            {loading && <LoadingComponent message={''}/>}
            {error && <ErrorComponent close={handleSuccess}/>}
            {successModal && <SuccessComponent close={closeSuccessModal}/>}
        </>
    );
};

export default ServiceSubItemForm;
