import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { Button, Image } from 'react-bootstrap';
import ServiceSubItemForm from './ServiceSubItemForm';
import { BASE_URL } from '../../BaseUrl';
import NavBarUpdate from "./NavBarUpdate";
import Layout from '../../components/Layout';
import {
    fetchServiceSubItem,
    createCategory,
    updateCategory,
    deleteCategory,
    itemRankUpdateApi, successConfirmation
} from '../../reducers/CategorySlice';
// import { fetchServiceSubItem  } from '../../reducers/FormBuilderSlice';  
// const token = localStorage.getItem("token");
import { pageTitle } from "../../reducers/DashboardSlice";
import LoadingComponent from "../../modal/LoadingComponent";
import SuccessComponent from "../../modal/SuccessComponent";
import ErrorComponent from "../../modal/ErrorComponent";
import CardNavigationForItemComponent from "../../component/items/CardNavigationForItemComponent";
import ServiceItemAddForm from "./ServiceItemAddForm";
import {checkStructure} from '../../utility/StringModify';
 
const ServiceSubItemListPage = () => {
  const {loading, error,  subItems, configStatus, identityArr, itemImage, itemIcon } = useSelector((state) => state.category)
 
  const formBuilder = useSelector((state) => state.formBuilderData) 
  const [configuration, setConfiguration] = useState(true);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
    const [editId, setEditId] = useState('');
    const handleEdit = (id) => setEditId(id);
  // console.log('navigate', navigate)
  // const [fetch, setFetch] = useState(false); 
  const handleClose = () => {
  setShow(false)
  // setConfiguration(true);
  // setCategoryData(() => ({update: false}))
  };
  // const handleShow = () => setShow(true);
  // const closeConfigurationFrom = () => setConfiguration(true);
    const {item,parentCategory} = useParams();
  let location = useLocation();
  const loca =location.pathname.split("/");
  const textId = loca[loca.length-1];
  // console.log('location', location);
    let hashId =location.hash?location.hash : '-sub'
    // console.log('hashId', hashId)

  const subInitialData = {
    parentTextId: textId,
    title: '',
    rank: false,
    status: false,
    details: '',
    image: false,
    icon: false
  }
  
  const dispatch = useDispatch()
  const [ categoryData, setCategoryData ] = useState({
        update: false,
        // id: null,
        // textId: null,
        title: null,
        categoryTextId: 'root',
        // breadcrumb: null,
        rank: null,
        status: null,
        itemDescription: null,
        image: null,
        icon: null,
        // zoneTextId: null
      })

    const handleRankUpdate = (id, rank) => {
        if (rank) {
            dispatch(itemRankUpdateApi({textId: id, rank: rank}))
        }
    }
    const handleSuccess = () => dispatch(successConfirmation());
    const handleErrors = () => {
      if(navigate){
          navigate(-1);
      }else {
          navigate('/dashboard');
      }
    }

    useEffect(() => {   
     const res = dispatch(fetchServiceSubItem(textId))
     res.then((results) => {   
          if(!results.error){ dispatch(pageTitle({"title":results.payload.title,"link":textId}))}
          
        })
            res.catch((error) => {
            console.error('Promise rejected:', error);
        });
    }, [dispatch, location])
 
 
    return (
    <>
       <Layout  
       pageType={{'type':'item', 'textId':textId}}
       prependBreadcrumb={{"name":'Item Listing',"link":'/service-items'}}
        
 
        component={
        <>

        {show ? <ServiceSubItemForm data={{...subInitialData,image: itemImage,icon: itemIcon}} />: <>
          {editId==='' && 
            <div className='top-btn-area'> {!loading && configStatus ?
                  <div>   <span onClick={()=>{setShow(true)}} className="new-configuration-btn add-new"> Add Sub Item  </span></div>:
                  // <div>   <span to={`/service-items/add-item#${item}`} className="new-configuration-btn add-new"> Add Sub Item  </span></div>:
                  <div><Link to={`/service-items/${item}/configuration${hashId}`} className="new-configuration-btn add-new"> Add Configuration </Link></div>
                  }

                {!loading && subItems.length > 0 &&  subItems.map((data, index) => (
                  <CardNavigationForItemComponent item={data} index={index} edit={handleEdit} configurationUrl={`/service-items/${data.textId}/configuration-sub`} viewUrl={`/service-items/${data.textId}`} editUrl={`/service-items/edit-item/${data.textId}`} root={'subItems'} />

                ))}
            </div>
          }</>
        }

            {/*{configuration===false && <ServiceItemConfiguration close={handleClose} configuration_data={configuration_data} textId={textId} title={title}  />}*/}
        
 
            {loading && <LoadingComponent message={''}/>}
            
            {error && <ErrorComponent close={handleErrors} errors={error}/>}
        </>
      }></Layout>
    </>
  )
}

export default ServiceSubItemListPage;