import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {BASE_URL} from "../BaseUrl"; 
import { checkStructure } from '../utility/StringModify'

const initialState = {
 
    loading: false,
    metaTitle:false,
    dashboardData: [],
    this_month_order: false,
    last_month_order: false,
    cardList: false,
    provider_list: false,
    success: false,
    error: '', 
    breadcrumbTree:[], 
    metaData:[]
}

const url = `${BASE_URL}`;
const token = localStorage.getItem("token")
const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}


export const fetchDashboard = createAsyncThunk(
    'fetch/Dashboard', async () => {
        return axios
            .get(`${url}/dashboard`, config)
            .then((response) => response.data)
    }
)

export const fetchPageMetaSettings = createAsyncThunk(
    'fetch/fetchPageMetaAll', async () => {
        return axios
            .get(`${url}/pageMeta/`, config)
            .then((response) => response.data)
    }
)
export const fetchPageMetaInfo = createAsyncThunk(
    'fetch/fetchPageMeta', async (param) => {
        return axios
            .get(`${url}/singlePageMeta/?pageUrl=${param.link}&pageType=${param.pageType.type}&textId=${param.pageType.textId}`, config)
            .then((response) => response.data)
    }
)

export const createPageMetaSettings = createAsyncThunk(
    "post/createPageMeta", async (formData) => {
        return axios
            .post(`${url}/pageMeta/`, formData, config)
            .then((response) => response.data
            )
    }
)

export const createPageMetaByForm = createAsyncThunk(
    "post/createPageMetaByForm", async (formData) => {
        return axios
            .post(`${url}/pageMeta/`, formData, config)
            .then((response) => response.data
            )
    }
)

export const updatePageMetaByForm = createAsyncThunk(
    'update/updatePageMeta', async (formData) => {
        return axios
            .put(`${url}/pageMeta/`, formData, config)
            .then((response) => response.data)
    }
)

const dashboardSlice = createSlice({
    name: "order",
    initialState,
    reducers: {
         pageTitle(state, action) {
            state.metaTitle = action.payload.title;
            // state.pageId = action.payload.link;
            // state.pageType = action.payload.pageType;
        },
        successConfirmation(state, action) {
            state.success = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDashboard.pending, (state) => {
            state.dashboardData = [];
            state.this_month_order = false;
            state.last_month_order = false;
            state.cardList = false;
            state.provider_list = false;
            state.success = false;
            state.loading = true;
        });

        builder.addCase(fetchDashboard.fulfilled, (state, action) => {
            state.loading = false;
            state.dashboardData = action.payload;
            state.cardList = action.payload.cardList;
            state.this_month_order = action.payload.this_month_order;
            state.last_month_order = action.payload.last_month_order;
            state.provider_list = action.payload.provider_list;
            state.error = "";
        });

        builder.addCase(fetchDashboard.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });




        builder.addCase(fetchPageMetaInfo.pending, (state) => { 
            state.loading = true;
            state.singleMeta=false;
            // state.previousTree=state.breadcrumbTree;
            // state.breadcrumbTree=[];

        });

        builder.addCase(fetchPageMetaInfo.fulfilled, (state, action) => {
            state.loading = false;
            // console.log('******* fulfilled ******',action.payload)
            state.metaTitle = action.payload.title;
            if(action.payload.results.breadcrumbTree){
                state.breadcrumbTree = checkStructure(JSON.parse(action.payload.results.breadcrumbTree));
                //  console.log(action.payload.results.breadcrumbTree)
            }
            state.error = "";
        });

        builder.addCase(fetchPageMetaInfo.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
            console.log('Rejected')
        });

        builder.addCase(fetchPageMetaSettings.pending, (state) => {
            state.dashboardData = [];
            state.loading = true;
        });

        builder.addCase(fetchPageMetaSettings.fulfilled, (state, action) => {
            state.loading = false;
            state.metaData = action.payload.results;
            state.error = "";
           
        });

        builder.addCase(fetchPageMetaSettings.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });

        builder.addCase(createPageMetaSettings.fulfilled, (state, action) => {
            state.loading = false;
       
            state.metaData.push(action.payload.results);
            state.breadcrumbTree = checkStructure(JSON.parse(action.payload.results.breadcrumbTree));
            state.error = "";
        });

        builder.addCase(createPageMetaByForm.pending, (state) => {
            state.dashboardData = [];
            state.loading = true;
        });

        builder.addCase(createPageMetaByForm.fulfilled, (state, action) => {
            state.loading = false;
     
            state.metaData.push(action.payload.results);
            state.error = "";
        });

        builder.addCase(createPageMetaByForm.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });

        

        builder.addCase(updatePageMetaByForm.pending, (state) => {
            state.dashboardData = [];
            state.loading = true;
        });

        builder.addCase(updatePageMetaByForm.fulfilled, (state, action) => {
            state.loading = false;
            state.metaData[action.payload.results.localData]=action.payload.results;
            state.error = "";
        });

        builder.addCase(updatePageMetaByForm.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });


    },
});


export const { successConfirmation, pageTitle } = dashboardSlice.actions;
export default dashboardSlice.reducer