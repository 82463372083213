// import Button from 'react-bootstrap/Button';
// import Alert  from 'react-bootstrap/Alert';
// import { useState } from "react";
// import { ReactComponent as MySVG } from '../../assets/images/upload-img-icon.svg';
import Layout from "../../components/Layout";
// import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import { BASE_URL } from "../../BaseUrl";
// import Form from 'react-bootstrap/Form'; 
// import { createUser } from '../../reducers/AuthSlice'; 
import {useDispatch, useSelector} from "react-redux";
// import Layout from "../../components/Layout";
// import { useForm } from 'react-hook-form';
// import SideBarData from "../../data/leftSideBar";
import { useLocation, useNavigate, useParams} from "react-router-dom";

import React, {useEffect, useState} from 'react';
// import DraggableComponent from './DraggableComponent';
// import DroppableComponent from './DroppableComponent';
// import ItemConponent from './ItemConponent';

// import React from 'react';
// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
// import DroppableComponentInner from './DroppableComponentInner';
// import DraggableComponent from './DraggableComponent';
// import DroppableComponent from './DroppableComponent';
import Controls from './component/Controls';
import {
    fetchSubItemConfiguration,
    subItemConfigurationPost, 
    resetConfiguration, 
    successConfirmation
} from '../../reducers/FormBuilderSlice';
// import {
//     subItemConfigurationResponse
// } from '../../reducers/CategorySlice';
// import {useFormik} from "formik";
import LoadingComponent from "../../modal/LoadingComponent";
import SuccessComponent from "../../modal/SuccessComponent";
import ErrorComponent from "../../modal/ErrorComponent";
// import UnGroupServiceComponent from "./component/UnGroupServiceComponent";

const SubItemConfiguration = ({close}) => {
    const { subItemCurrentData, subData, parent_config, loading, error,} = useSelector((state) => state.formBuilderData)
    const dispatch = useDispatch();
    const configuration_data = subData;
    const {state } = useLocation();
    // const [droppedItems, setDroppedItems] = useState([]);
    const [resetCount, setResetCount] = useState(0);
    const {item} = useParams();
    const navigate = useNavigate()
    // const urlArr = location.pathname.split("/");
    const [successModal, setSuccessModal] = useState(false);
 
// console.log('isBundleItem:',isBundleItem)

    //   const actionSubmit = (event) => {
    //   event.preventDefault();

    //   const formData = new FormData(event.target);
    //   const dataObj = Object.fromEntries(formData);

    //   const data = {'formData':dataObj,  'textId':urlArr[urlArr.length-1]}
    //   // dispatch(subItemConfiguration(data));
    //   console.log('dddd',data)
    //   // close();
    // };

    const openSuccessModal = (url) => setSuccessModal(url ? url : false);
    const closeSuccessModal = (url) => {
        setSuccessModal(false)
        handleSuccess()
        navigate(successModal);
    };
    const handleSuccess = () => dispatch(successConfirmation());

   
    const actionSubmit = (param) => {
        param.preventDefault();
       
        const data = {'formData': subItemCurrentData, 'draft': param, 'textId': item}
        // console.error('actionSubmit configuration-sub data:', data);
        console.log('Final data: ',data)
        const res = dispatch(subItemConfigurationPost(data));
 
        res.then((results)=> {
            openSuccessModal(`/service-items/${item}`);
            // alert('sub configuration action Submit', `/service-items/${item}`)
        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });



    };
    const resetSubItemConfiguration = () => {
        console.log(parent_config)
        dispatch(resetConfiguration(parent_config))
        setResetCount(resetCount+1)
    }

    // const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    //     initialValues: 'data',
    //     onSubmit: (values, action) => {
    //
    //             // dispatch(createCityZip(values));
    //
    //     },
    //     // validationSchema: zipSchema,
    //
    // });

    useEffect(() => {
        
        dispatch(fetchSubItemConfiguration(item));
  
    }, [dispatch, item]);


    // console.log(subData, 'param data')
    // console.log('param data',configuration_data)

    return (
        <Layout 
        page_title={document.title} 
        // pageType={{'type':'item', 'textId':item}}
        component={
            <>

                <div className="section-two-column from-builder">
                    {/* <DndProvider backend={HTML5Backend}> */}
                    <div className="left-block">

                        {state && <legend className="config-title">{state.title} </legend>}
                      


                        {/* <MySVG /> */}
                        <form onSubmit={actionSubmit}>

                            {configuration_data && configuration_data.length > 0 && configuration_data.map((item, index) => (
                                <>
                                    <fieldset className="field-builder subitem-manage px">
                                        <legend>{item.label}</legend>

                                        {/* <UnGroupServiceComponent data={item} index={[index,'labelField']} callFrom='subConfiguration'/> */}

                                        {item.btnName === 'labelField' ? (
                                            <>
                                                {item.controls === undefined ? null : item.controls.map((dataItem, dataIndex) => (
                                                    <Controls data={dataItem} px={index} cx={dataIndex} reset={resetCount}/>
                                                    
                                                ))}
                                            </>
                                        ) : null}
                                    </fieldset>
                                </>
                            ))}

                            {configuration_data && configuration_data.length > 0 &&
                                <div className="save-option">
                                    <span className="btn reset-btn" onClick={resetSubItemConfiguration}>Reset
                                        Configuration
                                    </span>
                                    <button className="btn save-btn" type="submit">Save</button>
                                </div>}


                        </form>

                    </div>
                    <div className="right-block">
                        <div className="drag-items">

                            {/*       {console.log(subData)}*/}
                            {/*{subData!='' && Object.keys(subData).map((item, index) => (*/}
                            {/*            <>  <div className="roe"><strong>{item}:</strong> <span>{subData[item]} </span></div>*/}
                            {/* </>*/}
                            {/*         ))}*/}


                        </div>
                    </div>

                    {/* </DndProvider> */}
 
                </div>
                {loading && <LoadingComponent message={''}/>}
                {successModal && <SuccessComponent close={closeSuccessModal} message={'sub item configuration page'}/>}
                {error && <ErrorComponent close={handleSuccess} message={error}/>}
            </>}></Layout>
    );
}

export default SubItemConfiguration;

