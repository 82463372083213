export function ClearCacheForImage(imageUrl){
    console.log('imageUrl',imageUrl);
    if ('caches' in window) {
  caches.match(imageUrl).then(function(response) {
    if (response) {
      console.log('File is cached');
    } else {
      console.log('File is not cached');
    }
  });
}

    caches.open('image-cache').then(function(cache) {
        cache.delete(imageUrl).then(function(response) {
            // Cache deleted
            console.log(imageUrl, ' ::Image cache cleared');
        });
    });
}