 import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    loading: false,
    fieldBuilder: [],
    error: '',
    nur:false
}

const url = `${BASE_URL}/fieldsetbuilder/`
const token = localStorage.getItem("token")
const config = {
    headers: {
        Authorization: `Token ${token}`,
    }
}

export const createfieldsetBuilder = createAsyncThunk(
  "create/fieldsetbuilder", async (formData) => {
    return axios
    .post(url, formData, config)
    .then((response) => response)
  }
)

export const updatefieldsetBuilder = createAsyncThunk(
  "update/fieldsetbuilder", async (formData) => {
    return axios
    .put(`${url}${formData.id}`, formData, config)
    .then((response) => response.data)
  }
)

export const deletefieldsetBuilder = createAsyncThunk(
  "delete/fieldsetbuilder", async (id) => {
    return axios
    .delete(`${url}${id}`, config)
    .then((response) => response.data)
  }
)

export const fetchfieldsetBuilder = createAsyncThunk("fetch/fieldsetbuilder", async () => {
  return axios
  .get(url, config)
  .then((response) => response.data);
});

const fieldsetBuilderSlice = createSlice({
  name: "fieldsetbuilder",
  initialState,
    reducers: {
    allfieldset(state, action) {
      // state = action.payload.id;
      // console.log(action.payload);
      state.fieldsetBuilder.push(action.payload);

    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchfieldsetBuilder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchfieldsetBuilder.fulfilled, (state, action) => {
      state.loading = false;
      state.fieldsetBuilder = action.payload;
      state.error = "";
    });
    builder.addCase(fetchfieldsetBuilder.rejected, (state, action) => {
      state.loading = false;
      state.fieldsetBuilder = [];
      state.error = action.error;
    });
    
    builder.addCase(createfieldsetBuilder.fulfilled, (state, action) => {
      state.loading = false;
      state.fieldsetBuilder.push(action.payload.data);
      // state.fieldsetBuilder = [action.payload.data];
      state.error = "";
    });
    builder.addCase(deletefieldsetBuilder.fulfilled, (state, action) => {
      state.loading = false;
      state.fieldsetBuilder.splice(action.payload[1], 1);
      // state.splice(action.payload, 1);
      // state.fieldsetBuilder = [action.payload.data];
      state.error = "";
   
    });
  },
});

export const { allfieldset } = fieldsetBuilderSlice.actions;
export default fieldsetBuilderSlice.reducer;