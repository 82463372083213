import {Form, Modal, Button, Image} from "react-bootstrap";
import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
 
import zone from "../../data/zone";
import {BASE_URL} from "../../BaseUrl";
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from "react";
import {notificationCategorySchema} from "../../schemas/category";
import {useFormik} from "formik";
 
import { createNotificationCategory, updateNotificationCategory} from "../../reducers/NotificationSlice";
 

const AddCategoryForNotification = ({data, categories, close}) => {
    const dispatch = useDispatch();

    // console.log('data:', data)

    const {values, errors, handleChange, handleSubmit, setValues} = useFormik({
        initialValues: data,
        onSubmit: (values, action) => {
            if(values.update){
                const res = dispatch(updateNotificationCategory(values));
            res.then(results => {
               if(results.error){ } else {
               close();}
            }).catch(error => { alert('error ---- false', error) });

            }else {

            const res = dispatch(createNotificationCategory(values));
            res.then(results => {
               if(results.error){
                
               } else {
               close();}
            }).catch(error => {
                alert('error ---- false')
            });
        }

        },
         validationSchema: notificationCategorySchema,

    });
    // console.log("🚀 values:", values)

     
    return (
        <>
        <div className="wrap-content"> 
            <Form onSubmit={handleSubmit} className="form-data">
                <div className="section-two-column notification-form">
                    <div className="field-row"><label className="label">Title </label>
                        <input name="title" placeholder="Title" type="text" className="border w-100 border-slate-700 p-2 rounded-md" value={values.title} onChange={handleChange} ></input>
                            {errors.title && <p className="red">{errors.title}</p>}   
                    </div> 
                    {/* {errors} */}

                    <div className="field-row"><label className="label">Rank </label>
                        <input name="rank" placeholder="Rank (Number)" type="number" className="border w-100 border-slate-700 p-2 rounded-md" value={values.rank} onChange={handleChange} ></input>
                    </div> 
            
                    <div className='field-row'> 
                        <Form.Check type="switch" id="status" label="Status" name="status" defaultChecked={values.status === 'Y'? true:false} value={values.status} 
                        onChange={(e)=>setValues({...values,status:e.target.checked?'Y':'N'})}/>
                    </div>
                    {/* {data.status} || 
                    {values.status} || */}

                    <div className="save-option">
                        {/* <button><span className="draft-icon"> <img src={uploadImgIcon} alt=""/> </span>Save as Draft  </button> */}
                        {/* <button className="save-btn">Save</button> */}
                        <button className="save-btn">{data.update===true?'Updated':'Save'}</button>
                        <button className="save-btn" onClick={()=>close()}>Close</button>
                    </div>

                </div>
            </Form>
        </div>
        </> 
    );
};

export default AddCategoryForNotification;
