//import './styles.css';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import React, {useState, useEffect} from "react";
import Form from 'react-bootstrap/Form';
  

const UnderConstructionComponent = () => { 

    return (
        <div className="under-construction">
            <div className="middle">
                <h1>COMING SOON</h1>
                <hr />
                <div className='text'>Under Construction </div>
            </div>
                <div className="bottomleft"> </div>
        </div>
    );
}

export default UnderConstructionComponent;
// what is redux toolkits?
// how to create functional components?
