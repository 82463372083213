
export function truncateString(str, maxLength = 50) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  }
  return str;
}

export function checkStructure(brTree) {
    // console.log('in checkStructure :: ',brTree)
  if (Array.isArray(brTree)) {
    // console.error('got 1st')
    return brTree
  } else if (typeof brTree === 'object') {
    const result= Object.keys(brTree).map(key => ({
            name: key,
            link: brTree[key]
        }));
        //  console.error('got 2nd')
         return result
  } else {
    //  console.error('got 3rd')
    return brTree;
  }
}