import { createSlice } from "@reduxjs/toolkit";
// import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
// import axios from "axios";
// import {BASE_URL} from "../BaseUrl";
// import Breadcrumb from "react-bootstrap/Breadcrumb";
// import React from "react";

const initialState = {
    loading: false,
    title: false,
    pageId:false,
}
const localDataSlice = createSlice({
    name: "formbuilder",
    initialState,
    reducers: {
        pageTitle(state, action) {
            state.title = action.payload.name;
            state.pageId = action.payload.link;
            state.pageType = action.payload.pageType;
        },

        breadcrumbTree(state, action) {
            // let data= action.payload;
            // console.log( action.payload)
            // if('number'=== typeof action.payload.length){
            //       data = action.payload[0];
            //     action.payload[1].forEach(currentObject => {
            //         const key = Object.keys(currentObject)[0];
            //         data[key] = currentObject[key];
            //     });
            //     console.log(data)
            // }

            // let fullLink = '';
            // state.breadcrumb = data;
            
            //     Object.keys(data).map((item,index) => {
            //         fullLink += '/' + data[item];
            //         state.breadcrumb[item] = (index<3 && data[item]  ? fullLink : '')
            //     })
            

        }
    }
});


export const {pageTitle, breadcrumbTree} = localDataSlice.actions;
export default localDataSlice.reducer

