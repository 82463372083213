 
import {BASE_URL} from "../../BaseUrl";
 
import React, {  useState} from "react";
 
import {NameIcon} from "../../utility/StringImageIcon";



const CustomerCardComponent = ({ data, title, show}) => {
    const [active, setActive] = useState(show);
 

    const handleToggle= () => setActive(!active);


    const user_data = data.user_data;
    // const workerData = data.workerData;
    // const payment_summary = data.payment_summary;
    // const paymentArray = data.paymentArray;
    console.log('user_data',user_data)

    return (
        <>
            {user_data &&<div
                className="w-[100%]  p-3 bg-white rounded-xl flex-col justify-start items-start gap-1 inline-flex">
                <strong>{title}</strong>
                <div className="w-[100%] border border-neutral-100 mx-0 my-[12px] opacity-[25%]"></div>
                <div className="w-[100%] self-stretch pr-3 justify-start items-start gap-2 flex relative cursor-pointer">
                    {user_data && user_data.isImage ? <div className="profile-icon w-11 h-11 rounded-full truncate" style={{backgroundImage: `url(${BASE_URL}${user_data.image})`}}>
                        {/* <img className="max-w-full" src={user_data && `${BASE_URL}${user_data.image}`}/> */}
                        </div> :
                        <strong className="w-11 h-11 rounded-full bg-[green] text-center text-white py-2.5 pt-[12px] capitalize">
                            {user_data && NameIcon(user_data.userName)}
                            {/*{user_data && user_data.userName}*/}
                            {/*{imageString[0][0]}{imageString[1][0]}*/}
                        </strong>}


                    {/*<img className="w-11 rounded-full"*/}
                    {/*     src="https://via.placeholder.com/44x44"/>*/}
                    <div className="flex-col justify-start items-start  inline-flex">
                        <strong>{user_data && user_data.userName}</strong>
                        {user_data && <>{user_data.totalOrder} orders</>}
                    </div>
                    <i
                        className={`right-[5px] top-[5px] absolute fa cursor-pointer ${
                            active ? 'fa-chevron-down':'fa-chevron-right' 
                        }`}
                        aria-hidden="true"
                        onClick={handleToggle}
                    ></i>
                </div>


                {active && <>
                    <div className="w-[100%] border border-neutral-100 mx-0 my-[12px] opacity-[25%]"></div>
                    <strong> Contact info</strong>
                    <div className="flex"><i className="fa fa-envelope mt-1" aria-hidden="true"></i>
                        <span className="email ml-3"> {user_data && user_data.userEmail}</span></div>

                    <div className="flex"><i className="fa fa-phone mt-1" aria-hidden="true"></i>
                        <span className="email ml-3">{user_data && user_data.userPhone}</span></div>


                    <div className="w-[100%] border border-neutral-100 mx-0 my-[12px] opacity-[25%]"></div>
                    <div className="flex-col justify-start items-start gap-2 flex">
                    <div className="text-black text-sm font-medium font-['Inter']">Address</div>
                        <div className="justify-start items-start gap-2.5 inline-flex">
                            {user_data &&<div
                                className="text-neutral-600 text-sm font-normal font-['Inter'] leading-snug capitalize">
                                    {user_data.addressLine1 && user_data.addressLine1}
                                    {user_data.addressLine2 && `, ${user_data.addressLine2}`} 
                                    {user_data.countryIso2Code && `, ${user_data.countryIso2Code}`} 
                                    
                            </div>}
                            <div className="w-3.5 h-3.5 rounded-[75px]"></div>
                        </div>
                    </div>
                    <div className="w-[100%] border border-neutral-100 mx-0 my-[12px] opacity-[25%]"></div>

                    {/* <div className="flex-col justify-start items-start gap-2 flex">
                    <div className="text-black text-sm font-medium font-['Inter']">
                            Payment info
                        </div>
                        <div className="flex-col justify-start items-start gap-3 flex">
                            <div className="justify-start items-center gap-3 inline-flex">
                                {paymentArray && paymentArray.length > 0 && paymentArray[0].paymentType &&
                                    <img className="w-8 "
                                         src={`/images/${paymentArray[0].paymentType}.svg`} alt={user_data && user_data.userName}/>}
                                <div className="text-neutral-600 text-sm font-normal font-['Inter']">
                                    {paymentArray && paymentArray.length > 0 && paymentArray[0].paymentType}
                                </div>
                            </div>
                            <div className="justify-start items-center gap-3 inline-flex">
                                <div
                                    className="text-emerald-700 text-sm font-normal font-['Inter']">Card
                                    Number: **** **** **** 4291
                                </div>
                            </div>
                        </div>
                    </div> */}
                </>}
            </div>}
             
        </>

    );
};

export default CustomerCardComponent;
