import React, { useState} from 'react';
// import {Link} from "react-router-dom";
// import CustomerOrderDataTable from "../../dataTables/CustomerOrderDataTable";
import {BASE_URL} from "../../BaseUrl";
// import moment from "moment";
// import {Form} from "react-bootstrap";
// import {filterDataTable3Col} from "../../utility/FilterDataTable";
// import {favouriteServiceListApi, fetchEndUser} from "../../reducers/CustomersSlice";
// 
import { useSelector} from "react-redux";


const CustomerSaveServiceComponent = ({user}) => {
    const [active, setActive] = useState('GRID');
    const {serviceList} = useSelector((state) => state.customers)
    // const dispatch = useDispatch()


    console.log('serviceList', serviceList)
    return (<>
            <div className="w-[100%] flex-col justify-start items-start  inline-flex border-1">
                <div className="w-[100%] bg-white justify-between items-center inline-flex">
                    <div className="w-[180px] self-stretch py-4 justify-start items-center gap-2 flex">
                        {serviceList.favourite_service_list && serviceList.favourite_service_list.length > 0 && <div
                            className="text-black text-sm font-medium font-['Inter']">{serviceList.favourite_service_list.length} Saved
                            services</div>}
                    </div>
                    <div className="p-1 bg-neutral-100 rounded-md justify-start items-start flex">
                        <div
                            className={`w-[34px] h-[34px] pl-[9px] pt-[8px] ${active === 'GRID' && 'bg-white rounded-md shadow'}`}
                            onClick={() => setActive('GRID')}>
                            <i className="fa fa-th-large" aria-hidden="true"></i>
                        </div>
                        <div className="w-[34px] relative rounded-md">
                            <div
                                className={`w-[34px] h-[34px] pl-[9px] pt-[8px] ${active === 'ROW' && 'bg-white rounded-md shadow'}`}
                                onClick={() => setActive('ROW')}>
                                <i className="fa fa-th-list" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                </div>


                {active==='ROW' ?<div className={`w-[100%]  gap-4`}>

                    {serviceList && serviceList.favourite_service_list.map((item) => (<>
                        <div
                            className="w-[100%] my-3 px-2.5 pt-2.5 pb-2 bg-white rounded-xl border border-gray-200  justify-center flex">
                            <div className={`w-[150px] relative overflow-hidden mr-3`}>
                                {/*<img className="w-[100%] rounded-[10px]" src="https://via.placeholder.com/313x160"/>*/}
                                <img
                                    src={(item.serviceImage ? `${BASE_URL}${item.serviceImage}` : "/images/no-image.jpg")}
                                    alt={item.serviceTitle}
                                    className="w-[100%] rounded-[10px]"/>
                            </div>
                            <div className="w-[100%] relative">
                                <div className="justify-between items-center inline-flex w-[100%] relative">
                                    {item.serviceTitle && <div
                                        className="text-zinc-900 text-base font-semibold font-['Inter']">{item.serviceTitle}</div>}
                                    <div className="w-[17px] h-[17px] relative text-[#008951] right-0 top-0 absolute">
                                        <i className="fa fa-heart" aria-hidden="true"></i></div>
                                </div>
                                <div className="w-[100%] justify-between items-center flex-col gap-2 w-[100%]">
                                    <div
                                        className="text-neutral-600 text-sm font-semibold font-['Inter']">{item.price !== 0 &&
                                        <span>${(item.price).toFixed(2)}+</span>}
                                    </div>
                                    <div className="relative   flex align-center mt-3">
                                    {item.average_rating !== 0 &&
                                            <>
                                                <div className="text-zinc-900 text-sm font-semibold font-['Inter']">
                                                    {item.average_rating} </div>
                                                <i className="fa fa-star text-[#E3B600] ml-1"
                                                   aria-hidden="true"></i></>}
                                        {item.totalRatingsCount !== 0 &&
                                            <div className="text-neutral-500 text-sm font-semibold font-['Inter'] ml-2">
                                                ({item.totalRatingsCount})
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>))}
                </div>:

                <div className="w-[100%] grid grid-cols-3 gap-4">

                    {serviceList && serviceList.favourite_service_list.map((item) => (<>
                        <div
                            className="w-[100%] px-2.5 pt-2.5 pb-2 bg-white rounded-xl border border-gray-200 flex-col justify-center items-start gap-2 inline-flex">
                            <div className="w-[100%] relative h-[180px] overflow-hidden">
                                {/*<img className="w-[100%] rounded-[10px]" src="https://via.placeholder.com/313x160"/>*/}
                                <img
                                    src={(item.serviceImage ? `${BASE_URL}${item.serviceImage}` : "/images/no-image.jpg")}
                                    alt={item.serviceTitle}
                                    className="w-[100%] rounded-[10px]"/>
                            </div>
                            <div className="w-[100%] relative">
                                <div className="justify-between items-center inline-flex w-[100%] relative">
                                    {item.serviceTitle && <div
                                        className="text-zinc-900 text-base font-semibold font-['Inter']">{item.serviceTitle}</div>}
                                    <div className="w-[17px] h-[17px] relative text-[#008951] right-0 top-0 absolute">
                                        <i className="fa fa-heart" aria-hidden="true"></i></div>
                                </div>
                                <div className="w-[100%] justify-between items-center inline-flex w-[100%] relative">
                                    <div
                                        className="text-neutral-600 text-sm font-semibold font-['Inter']">{item.price !== 0 &&
                                        <span>${item.price}</span>}
                                    </div>
                                    <div className="relative right-0 top-0 absolute flex align-center">
                                        {item.average_rating !== 0 &&
                                            <>
                                                <div className="text-zinc-900 text-sm font-semibold font-['Inter']">
                                                    {item.average_rating} </div>
                                                <i className="fa fa-star text-[#E3B600] ml-1"
                                                   aria-hidden="true"></i></>}
                                        {item.totalRatingsCount !== 0 &&
                                            <div className="text-neutral-500 text-sm font-semibold font-['Inter'] ml-2">
                                                ({item.totalRatingsCount})
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>))}
                </div>}

            </div>
        </>

    );
}

export default CustomerSaveServiceComponent;