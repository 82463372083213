import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    unauthorized: true,
    loading: false,
    data: [],
    notice: [],
    designation:[], 
    designationRole:[], 
    role:[], 
    module:[], 
    rolePermission:[], 
    count: 0,
    error: ''
}

const url = `${BASE_URL}/settings`

const token = localStorage.getItem("token")
// const textId = localStorage.getItem("textId")

const config = {
    headers: {
        Authorization: `Token ${token}`,
        "content-type": "multipart/form-data",
    }
}


 

export const fetchServiceIdentity = createAsyncThunk(
    'fetch/FetchIdentity', async () => {
        return axios
        .get(`${url}/identity/`, config)
        .then((response) => response.data)
    }
)
 
export const updateIdentity = createAsyncThunk(
    "update/updateIdentity", async (formData) => {
        if (typeof formData.image !== 'object' || formData.image === null) {
            formData.image = '';
        }
        // console.table(formData)
        const cUrl = `${url}/identity/`
        return axios
            .put(cUrl, formData, config)
            .then((response) => {
                // response.data.updateData = formData;
                return response.data
            })
    }
)

export const createNewIdentity = createAsyncThunk(
    "post/createIdentity", async (formData) => {
       
        // console.log(formData)
        return axios
            .post(`${url}/identity/`, formData, config)
            .then((response) => response.data
            )
    }
)

export const deleteQuction = createAsyncThunk(
    "delete/identityQuctionDelete", async (formData) => {
      // console.log(formData)
        return axios
            .delete(`${url}/Identity/${formData.id}`,  config)
            .then((response) => formData)
    }
)


export const fetchRoleList = createAsyncThunk(
    'fetch/WorkerRoleList', async () => {
        return axios
        .get(`${url}/workerRole/`, config)
        .then((response) => response.data)
    }
)

export const createRole = createAsyncThunk(
    "settings/createWorkerRole", async (formData) => {
       
        // console.log(formData)
        return axios
            .post(`${url}/workerRole/`, formData, config)
            .then((response) => response.data
            )
    }
)
 
export const updateRole = createAsyncThunk(
    "settings/updateWorkerRole", async (formData) => {
        const cUrl = `${url}/workerRole/`
        return axios
            .put(cUrl, formData, config)
            .then((response) => {
                // response.data.updateData = formData;
                return response.data
            })
    }
)

export const deleteRole = createAsyncThunk(
    "delete/deleteWorkerRole", async (formData) => {
      // console.log(formData)
        return axios
            .delete(`${url}/workerRole/?q=${formData.textId}`,  config)
            .then((response) => formData)
    }
)




export const fetchDesignationList = createAsyncThunk(
    'fetch/DesignationList', async () => {
        return axios
        .get(`${url}/designation/`, config)
        .then((response) => response.data)
    }
)

export const createNewDesignation = createAsyncThunk(
    "settings/createDesignation", async (formData) => {
       
        // console.log(formData)
        return axios
            .post(`${url}/designation/`, formData, config)
            .then((response) => response.data
            )
    }
)
 
export const updateDesignation = createAsyncThunk(
    "settings/updateDesignation", async (formData) => {
        const cUrl = `${url}/designation/`
        return axios
            .put(cUrl, formData, config)
            .then((response) => {
                // response.data.updateData = formData;
                return response.data
            })
    }
)

export const deleteDesignation = createAsyncThunk(
    "delete/deleteDesignation", async (formData) => {
      // console.log(formData)
        return axios
            .delete(`${url}/designation/?q=${formData.textId}`,  config)
            .then((response) => formData)
    }
)


export const fetchDesignationRoleList = createAsyncThunk(
    'fetch/DesignationRoleList', async () => {
        return axios
        .get(`${url}/designationRole/`, config)
        .then((response) => response.data)
    }
)

export const createDesignationRole = createAsyncThunk(
    "settings/createDesignationRole", async (formData) => {
       
        // console.log(formData)
        return axios
            .post(`${url}/designationRole/`, formData, config)
            .then((response) => response.data
            )
    }
)
 
export const updateDesignationRole = createAsyncThunk(
    "settings/updateDesignationRole", async (formData) => {
        const cUrl = `${url}/designationRole/`
        return axios
            .put(cUrl, formData, config)
            .then((response) => {
                // response.data.updateData = formData;
                return response.data
            })
    }
)

export const deleteDesignationRole = createAsyncThunk(
    "delete/deleteDesignationRole", async (formData) => {
      // console.log(formData)
        return axios
            .delete(`${url}/designationRole/?q=${formData.id}`,  config)
            .then((response) => formData)
    }
)

  

export const fetchModuleList = createAsyncThunk(
    'fetch/ModuleList', async () => {
        return axios
        .get(`${url}/workerModule/`, config)
        .then((response) => response.data)
    }
)

export const createModule = createAsyncThunk(
    "settings/createModule", async (formData) => {
       
        // console.log(formData)
        return axios
            .post(`${url}/workerModule/`, formData, config)
            .then((response) => response.data
            )
    }
)
 
export const updateModule = createAsyncThunk(
    "settings/updateModule", async (formData) => {
        const cUrl = `${url}/workerModule/`
        return axios
            .put(cUrl, formData, config)
            .then((response) => {
                // response.data.updateData = formData;
                return response.data
            })
    }
)

export const deleteModule = createAsyncThunk(
    "delete/deleteModule", async (formData) => {
      // console.log(formData)
        return axios
            .delete(`${url}/workerModule/?q=${formData.textId}`,  config)
            .then((response) => formData)
    }
)
 
export const fetchRolePermissionList = createAsyncThunk(
    'fetch/RolePermissionList', async () => {
        return axios
        .get(`${url}/workerRolePermission/`, config)
        .then((response) => response.data)
    }
)

export const createRolePermission = createAsyncThunk(
    "settings/createRolePermission", async (formData) => {
       
        // console.log(formData)
        return axios
            .post(`${url}/workerRolePermission/`, formData, config)
            .then((response) => response.data )
    }
)
 
export const updateRolePermission = createAsyncThunk(
    "settings/updateRolePermission", async (formData) => {
        const cUrl = `${url}/workerRolePermission/`
        return axios
            .put(cUrl, formData, config)
            .then((response) =>   response.data )
    }
)
export const updateRolePermissionColumns = createAsyncThunk(
    "settings/updateRolePermissionColumns", async (formData) => {
        const cUrl = `${url}/updatePermissionColumns/`
        return axios
            .put(cUrl, formData, config)
            .then((response) =>   response.data )
    }
)

export const deleteRolePermission = createAsyncThunk(
    "delete/deleteRolePermission", async (formData) => {
        return axios
            .delete(`${url}/workerRolePermission/?q=${formData.id}`,  config)
            .then((response) => formData)
    }
)


const identitySlice = createSlice({
    name: "identity",
    initialState,
    reducers: {

        updateBellCount(state, action) {
            state.count = state.count+1;
            // console.log('bell count incrememt!!')
        },
    },
    extraReducers: (builder) => {
       

      builder.addCase(fetchServiceIdentity.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchServiceIdentity.fulfilled, (state, action) => {
        state.loading = false;
         
      //  console.log(action.payload)
        state.data= action.payload.results;
        // state.Identity_category= action.payload.Identity_category;
        state.error = "";
      });
      builder.addCase(fetchServiceIdentity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });


      builder.addCase(createNewIdentity.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(createNewIdentity.fulfilled, (state, action) => {
        state.loading = false;
         
      //  console.log(action.payload.results)
        state.data.push(action.payload.results);
        state.error = "";
      });
      builder.addCase(createNewIdentity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });


      builder.addCase(updateIdentity.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(updateIdentity.fulfilled, (state, action) => {
        state.loading = false;
        state.data[action.payload.results.localData] = action.payload.results;
        state.error = "";
        console.log(action.payload.results)
 
      });
      builder.addCase(updateIdentity.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

      builder.addCase(deleteQuction.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(deleteQuction.fulfilled, (state, action) => {
        state.loading = false;     
        state.data.splice(action.payload.localData, 1);
        state.error = "";
      });
      builder.addCase(deleteQuction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
      


            /* Worker Module  */

      builder.addCase(fetchModuleList.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchModuleList.fulfilled, (state, action) => {
        state.loading = false;
       console.log(action.payload.results)
        state.module= action.payload.results; 
        state.error = "";
      });
      builder.addCase(fetchModuleList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
      
      builder.addCase(createModule.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(createModule.fulfilled, (state, action) => {
        state.loading = false;
        state.module.push(action.payload.results);
        state.error = "";
      });
      builder.addCase(createModule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
      
      builder.addCase(updateModule.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(updateModule.fulfilled, (state, action) => {
        state.loading = false;
        state.module[action.payload.results.localData]=action.payload.results;
        state.error = "";
      });
      builder.addCase(updateModule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

      builder.addCase(deleteModule.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(deleteModule.fulfilled, (state, action) => {
               console.log(action.payload);
        state.loading = false; 
        state.module.splice(action.payload.localData, 1);
        state.error = "";
 
      });
      builder.addCase(deleteModule.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

      



      /* Worker Role  */
            
      builder.addCase(fetchRoleList.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchRoleList.fulfilled, (state, action) => {
        state.loading = false;
       console.log(action.payload.results)
        state.role= action.payload.results;
        // state.Identity_category= action.payload.Identity_category;
        state.error = "";
      });
      builder.addCase(fetchRoleList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
      
      builder.addCase(createRole.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(createRole.fulfilled, (state, action) => {
        state.loading = false;
        state.role.push(action.payload.results);
        state.error = "";
      });
      builder.addCase(createRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
      
      builder.addCase(updateRole.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(updateRole.fulfilled, (state, action) => {
        state.loading = false;
        state.role[action.payload.results.localData]=action.payload.results;
        state.error = "";
      });
      builder.addCase(updateRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

      builder.addCase(deleteRole.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(deleteRole.fulfilled, (state, action) => {
               console.log(action.payload);
        state.loading = false;
        state.role.splice(action.payload.localData, 1);
        state.error = "";
 
      });
      builder.addCase(deleteRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

    

            /* Worker Designation  */

      builder.addCase(fetchDesignationList.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchDesignationList.fulfilled, (state, action) => {
        state.loading = false;
       console.log(action.payload.results)
        state.designation= action.payload.results;
        // state.Identity_category= action.payload.Identity_category;
        state.error = "";
      });
      builder.addCase(fetchDesignationList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
      
      builder.addCase(createNewDesignation.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(createNewDesignation.fulfilled, (state, action) => {
        state.loading = false;
        state.designation.push(action.payload.results);
        state.error = "";
      });
      builder.addCase(createNewDesignation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
      
      builder.addCase(updateDesignation.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(updateDesignation.fulfilled, (state, action) => {
        state.loading = false;
        state.designation[action.payload.results.localData]=action.payload.results;
        state.error = "";
      });
      builder.addCase(updateDesignation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

      builder.addCase(deleteDesignation.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(deleteDesignation.fulfilled, (state, action) => {
               console.log(action.payload);
        state.loading = false;
        // state.designation.push(action.payload.results);
        state.designation.splice(action.payload.localData, 1);
        state.error = "";
 
      });
      builder.addCase(deleteDesignation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });


            /* Worker Designation Role */
      
      builder.addCase(fetchDesignationRoleList.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchDesignationRoleList.fulfilled, (state, action) => {
        state.loading = false;
       console.log(action.payload.results)
        state.designationRole= action.payload.results;
        state.role= action.payload.role;
        state.designation= action.payload.designation;
        // state.Identity_category= action.payload.Identity_category;
        state.error = "";
      });
      builder.addCase(fetchDesignationRoleList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

      
      builder.addCase(createDesignationRole.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(createDesignationRole.fulfilled, (state, action) => {
        state.loading = false;
        state.designationRole.push(action.payload.results);
        state.error = "";
      });
      builder.addCase(createDesignationRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
      
      builder.addCase(updateDesignationRole.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(updateDesignationRole.fulfilled, (state, action) => {
        state.loading = false;
        state.designationRole[action.payload.results.localData]=action.payload.results;
        state.error = "";
      });
      builder.addCase(updateDesignationRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

      builder.addCase(deleteDesignationRole.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(deleteDesignationRole.fulfilled, (state, action) => {
               console.log(action.payload);
        state.loading = false;
        state.designationRole.splice(action.payload.localData, 1);
        state.error = "";
 
      });
      builder.addCase(deleteDesignationRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

              /* Worker Role Permission */
      
      builder.addCase(fetchRolePermissionList.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(fetchRolePermissionList.fulfilled, (state, action) => {
        state.loading = false;
       console.log(action.payload.results)
        state.rolePermission= action.payload.results;
        state.role= action.payload.role;
        state.module= action.payload.module; 
        state.error = "";
      });
      builder.addCase(fetchRolePermissionList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

      
      builder.addCase(createRolePermission.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(createRolePermission.fulfilled, (state, action) => {
        state.loading = false;
        state.rolePermission.push(action.payload.results);
        state.error = "";
 
      });
      builder.addCase(createRolePermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });
      
      builder.addCase(updateRolePermission.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(updateRolePermission.fulfilled, (state, action) => {
        state.loading = false;
        state.rolePermission[action.payload.results.localData]=action.payload.results;

        console.log(action.payload.results.localData)
        state.error = "";
      });
      builder.addCase(updateRolePermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });

      builder.addCase(deleteRolePermission.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(deleteRolePermission.fulfilled, (state, action) => {
        state.loading = false;
        state.rolePermission.splice(action.payload.localData, 1);
        state.error = "";
 
      });
      builder.addCase(deleteRolePermission.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
      });



    },
  });
  
// export const { updateBellCount } = IdentitySlice.actions;

export default identitySlice.reducer

 
