import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { BASE_URL } from "../../BaseUrl";
import { Image, Button } from "react-bootstrap";
import CustomDataTable from "../../components/CustomDataTable";
import ServiceForm from "./ServiceForm";
import AddFormModal from "./AddFormModal";  
import ManageCategoryService from './ManageCategoryService';
import Layout from "../../components/Layout"; 
import { useNavigate } from 'react-router-dom';
import {fetchActiveServiceItem} from "../../reducers/CategorySlice";
import { addChildInServiceItem} from "../../reducers/NavDataSlice";
import { pageTitle } from "../../reducers/DashboardSlice";
import LoadingComponent from "../../modal/LoadingComponent";
import CardViewComponent from "../../component/items/CardViewComponent";
 

const ServiceList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [response, setResponse] = useState(false);
  const [customization, setCustomization] = useState(false);
  const [manageShow, setManageShow] = useState(false);
  const [ selected, setSelected ] = useState({
        textId: null,
        title: null
      })
  const [query, setQuery] = useState('');
  const handleClose = () => {
    setShow(false); setManageShow(false);  
    // setServiceData(() => ({update: false}));
  }
  const handleShow = () => setShow(true);

  const customizationClose = () => setCustomization(false);

  // const service = useSelector((state) => state.service);
  const { activeItems, nav, loading } = useSelector((state) => state.category);
  const dispatch = useDispatch();
  const navTree={'Item Listung':'service'};

 
  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData); 

    // dispatch(createService(data));
      //  handleClose(true);
 
  };

// dispatch(breadcrumbTree({"title":'/tt',"tiii2":'/tt/t22'}));
 

  useEffect(() => {
    const res= dispatch(fetchActiveServiceItem());
      res.then((results) => {

          // setResponse(true)
          
          //
          // const data={}
          // // results.payload.pageBreadcrumb.forEach(currentObject => {
          // //     const key = Object.keys(currentObject)[0];
          // //     data[key]=currentObject[key];
          // // });
          dispatch(pageTitle({"title":"Item Listing","link":'service'}));
          
      })
      res.catch((error) => {
          console.error('Promise rejected:', error);
      });

     

  }, [dispatch,location]);
  
   if (nav && activeItems.length>0) {
    // console.log('addChildInServiceItem call at service', nav)
    dispatch(addChildInServiceItem({ 'nav': activeItems, 'rank': 2 }));
  }

   
  return (
    <Layout page_title={"Item Listing"}  
    breadcrumb={[{"name":'Item Listing',"link":'service'}]} 
    pageType={'item'}
    component={
        <>  
        <div className='top-btn-area'>

         {/* {!show && <button onClick={handleShow}  type="button" className="add-new-btn"> Add Service Item </button>} */}
          
          {!loading && activeItems.length>0 &&  activeItems.map((item) => (
         <CardViewComponent item={item} path={`${location.pathname}/${item.textId}`} />

        ))}
      </div>

           
          
          {/* {query && <span>{query} | dd</span>} */}
          {manageShow && <ManageCategoryService active={selected} close={handleClose} /> }
          {/* {customization && <AddFormModal close={customizationClose} customeData={customeData} />} */}
          {/* {show && <ServiceForm data={serviceData} close={handleClose} show={show}/> } */}
          {/* <CustomModal customizationClose={customizationClose} customeData={customeData}/> */}




            {loading && <LoadingComponent message={''}/>}
          {/* {!service.loading && activeItems && !show? <CustomDataTable data={activeItems} columns={columns} /> : null} */}
        </>
      }
    ></Layout>
  );
};

export default ServiceList;
