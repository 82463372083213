//  import { Form, Button } from "react-bootstrap";
import {Form, Button} from "react-bootstrap";
import {useDispatch} from "react-redux";
import React from "react";
// import React, {useState, useEffect} from "react";
//  import axios from "axios";
// import {BASE_URL} from "../BaseUrl";
// import {createServiceCustomization, fetchServicesCustomization} from "../reducers/ServiceCustomizationSlice";
import {useFormik} from "formik";
// import {createCityZip, updateCityZip} from "../reducers/CityZipSlice";
// import {zipSchema} from "../schemas/zip";
import {workerPriceDeclinePostApi, workerPriceDeclineUpdate} from "../reducers/TeamMemberSlice";
// import {breadcrumbTree} from "../reducers/LocalDataSlice";

const PriceDeclineModal = ({data, close, resetStatus}) => {
    const dispatch = useDispatch();



    const {values, handleChange, handleSubmit} = useFormik({
        initialValues: {...data, reviewStatus:'Decline'},
        onSubmit: (values, action) => {
            // console.log('values', values)
            const res = dispatch(data.priceRejectedNote?workerPriceDeclineUpdate(values):workerPriceDeclinePostApi(values));
            res.then((results) => {
                 console.log('results', results)
                if(!results.error) {
                     console.log('values', results)
                     
                    close(false);
                    resetStatus(true)
                }
                
            })
            res.catch((error) => {
                console.error('Promise rejected:', error);
            });
        },
    });
    // console.log("🚀 data:", data)
    // console.log("🚀 ~ file: CityZipForm.jsx:21 ~ CityZipForm ~ data:", values)

    return (
        <>
            <Form id="emailSent" className="custom-modal" onSubmit={handleSubmit}>
                <div className="custom-dialog close-modal" onClick={()=>close(false)}> </div>

               

                <div className="modal-content">

                    <div className="modal-header"><h2>Explain why the price was rejected.</h2>
                        <div className="close-modal" onClick={()=>close(false)}>&times;</div>
                    </div>
                    <div className="modal-body">
                       
                        <div className="mb-3">
                              <textarea className="form-control" name='priceRejectedNote' id="priceRejectedNote"
                                        rows="6" value={values.priceRejectedNote} onChange={handleChange}
                                        placeholder={values.priceRejectedNote}></textarea>
                        </div>


                    </div>

                    <div className="modal-footer">
                        <button className="btn bg-white rounded-pill border-red px-4" onClick={()=>close(false)}>Cancel</button>
                        &nbsp; &nbsp;
                        <Button type="submit" className="btn btn-success rounded-pill px-4"> Send </Button>
                    </div>
                </div>
            </Form>
        </>
    );
};


export default PriceDeclineModal;

