import React, { useState} from 'react'
import {BASE_URL} from "../../BaseUrl";
const ServiceInfo = ({data}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const truncatedText = isExpanded ? data.details : data.details.length>150?data.details.substring(0, 150) + '...':data.details;
    // console.log(data.details)
    // console.log(truncatedText)

    return (

        <>

            <div className="service-description">
                 <img className="left-content" src={data ? `${BASE_URL}${data.image}`:null} alt=''/>
                <div className="right-content">

                    <div className="text-zinc-900 text-xl font-semibold font-['Inter']">{data && data.title}</div>
                    <div className="description">{truncatedText}</div>
                     {data && data.details.length > 150 && (
                        <button onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <>Show Less <i class="fa fa-angle-up" aria-hidden="true"></i> </>: <>Show More <i class="fa fa-angle-down" aria-hidden="true"></i></>}
                        {/* {isExpanded ? 'Show More' : 'Show} */}
                        </button>
                    )}
                </div>

               
                <div className="both"></div>
            </div>

        </>

    );
};

export default ServiceInfo;