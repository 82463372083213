// import {Form, Modal, Button, Image} from "react-bootstrap";
// import Layout from '../../components/Layout';
// import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
// import zone from "../../data/zone";
// import {BASE_URL} from "../../BaseUrl";
// import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
// import {useDispatch, useSelector} from 'react-redux'
// import React, { useState } from "react";
 
import newRequestIcon from "../../assets/images/new-request.png";
import approvedIcon from "../../assets/images/approved-icon.png";
import declinedIcon from "../../assets/images/declined-icon.png";
import blockedIcon from "../../assets/images/blocked-icon.png";
// import '../../assets/tostify/ReactToastify.css';
// import './statusCard.css';
// import {NameIcon} from "../../utility/StringImageIcon";
const StatusCardComponent = ({data}) => {
    // const [Declined, setActive] = useState(false);
    // const dispatch = useDispatch();
    // const [thumbImage, setImage] = useState(false);
    // const {item, service } = useParams();
    // const navigate = useNavigate();
    // const [clickCount, setClickCount] = useState(0);

    // const handleToggle= () => setActive(!active);


    // const user_data = data.user_data;
    // const payment_summary = data.payment_summary;
    // const paymentArray = data.paymentArray;
//     const address = worker.address.replaceAll("'",'"');
//     const addressArr = JSON.parse(address)

// console.log('worker1:', worker.address)
// console.log('worker2:', addressArr)
console.log('worker address', data)

    return (
        <>
        <div className="status-cards">
            <div className="card">
                <div className="card-content">
                    <h3>New Requests</h3>
                    <span className="count">{data.total_new_request} </span> 
                </div>
                {/* {newRequestIcon?<img className="img" src={newRequestIcon} alt="New Requests Icon"/>: NameIcon('approvedIcon')} */}
                {newRequestIcon && <img className="img" src={newRequestIcon} alt="New Requests Icon"/>}
            </div>
            <div className="card">
                <div className="card-content">
                    <h3>Approved</h3>
                    <span className="count">{data.total_approved} </span> 
                </div>
                {approvedIcon && <img className="img" src={approvedIcon} alt="New Requests Icon"/>}
            </div>
             <div className="card">
                <div className="card-content">
                    <h3>Declined</h3>
                    <span className="count">{data.total_declined} </span> 
                </div>
                {declinedIcon && <img className="img" src={declinedIcon} alt="Declined"/>} 
            </div>
             <div className="card">
                <div className="card-content">
                    <h3>Blocked </h3>
                    <span className="count">{data.total_blocked} </span> 
                </div>
                
                {blockedIcon && <img className="img" src={blockedIcon} alt="blockedIcon"/>}
            </div>
        </div>

        </>

    );
};

export default StatusCardComponent;
