import React, { useState} from 'react';
// import {Link} from "react-router-dom";
// import CustomerOrderDataTable from "../../dataTables/CustomerOrderDataTable";
import {BASE_URL} from "../../BaseUrl";
import moment from "moment";
// import {Form} from "react-bootstrap";
// import {filterDataTable3Col} from "../../utility/FilterDataTable";
import ReviewsDataTable from "../../dataTables/ReviewssDataTable";
import StarRatingFromUserComponent from "../ratings/StarRatingFromUserComponent";
import ActionModelComponent from "../ratings/ActionModalComponent";


const ReviewRatingComponent = ({data}) => {

    // const [query, setQuery] = useState('');
    const [rowData, setRowData] = useState(false);

    console.log('rowData', data)

    const columns = [
        {
            name: "Services",
            minWidth: '250px',
            className: 'review-column',
            sortable: true, selector: (row) => <>
                <div className="w-[100%] justify-start items-center gap-3 inline-flex">
                    <img className="w-11 h-11 my-1 rounded-lg" src={`${BASE_URL}${row.serviceImage}`} alt=''/>
                    <div className=" w-[100px] text-black text-sm font-medium font-['Inter']">{row.serviceTitle}

                    </div>
                </div>
            </>
        },
        {
            name: "Reviewer",
            minWidth: '250px',
            sortable: true,
            selector: (row) => <>
                <div className="w-[239px] justify-start items-center gap-3 inline-flex">
                    {/*<img className="w-11 h-11 rounded-[100px]" src="https://via.placeholder.com/44x44"/>*/}
                    <img className="w-11 h-11 my-1 rounded-full" src={`${BASE_URL}${row.userImage}`} alt=''/>
                    <div className="flex-col justify-center items-start inline-flex">
                        <div className="text-black text-sm font-medium font-['Inter']">{row.userName}</div>
                        <div
                            className="text-stone-500 text-xs font-normal font-['Inter'] leading-snug">hassanjahid@gmail.com
                        </div>
                    </div>
                </div>
            </>
        },
        {
            name: "Review",
            sortable: true,
            minWidth: '250px',
            selector: (row) => <>
                <div className="w-[100%] py-1 flex-col justify-start items-start gap-1 inline-flex">
                    <StarRatingFromUserComponent rating={row.rateForWorkerByEndUser}/>
                    {/*<div className="justify-start items-start gap-2 inline-flex"></div>*/}
                    <div className="self-stretch text-stone-500 text-sm font-medium font-['Inter'] leading-snug">

                        {row.commentForWorkerByEndUser}
                    </div>
                </div>
            </>
        },
        {
            name: "Provider Name",
            minWidth: '200px',
            sortable: true, selector: (row) => row.providerName
        },
        {
            name: "Review Date",
            minWidth: '120px',
            sortable: true,
            selector: (row) =>moment(new Date(row.reviewRatingDate)).format("DD MMM YYYY,  h:mm A ") 
        },
        {
            name: "Status",
            sortable: true,
            minWidth: '120px',
            selector: (row) => <span className={`status ${row.reviewRatingStatus}`}> {row.reviewRatingStatus}</span>
        },
        {
            name: "Action", minWidth: '10px', sortable: true, selector: (row) => <>
                <div className="text-center w-[30px]" onClick={() => setRowData(row)}>
                    <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </div>
                {row.endUserOrderTimeId === rowData.endUserOrderTimeId && <div className='relative w-[100%]'>
                    <ActionModelComponent data={row} close={setRowData}/>
                    <div className='fixed bg-transparent w-[100%] h-[100%] top-0 left-0 z-40'
                         onClick={() => setRowData('')}></div>
                </div>}
            </>
        },
    ]


    return (
        <>

            <div className='data-table-wrapper reviewsDataTable'>




                <ReviewsDataTable data={data} columns={columns}/>
            </div>


        </>

    );
}

export default ReviewRatingComponent;