import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { fetchArea, storeState, storeCity } from "../../reducers/AreaSlice";
import { pageTitle } from "../../reducers/DashboardSlice";
import TestingZone from "../../component/area/TestingZone";

const ServiceZoneComponent = ({ item, close }) => {  
  const {loading, address} = useSelector((state) => state.area);
  const [data, setData] = useState(false); 
  
  const dispatch = useDispatch();

  console.log('item *****************',item)
   
  useEffect(() => {
    dispatch(pageTitle({"title":item.serviceTitle}));
    const res = dispatch(fetchArea(item.serviceTextId));
    res.then((results) => {
          if(results.error){}else{
          setData(results.payload)
          dispatch(storeCity(results.payload.cityArray.filter(item => item.isChecked ===true)))
          dispatch(storeState(results.payload.stateArray))
          }
      })
      res.catch((error) => {
          // console.error('Promise rejected:', error);
      });
  }, [dispatch, item])

  return (<>
     {data && <TestingZone address={address} data={data} serviceTextId={item.serviceTextId} close={close}/>}
     </>
  );
};

export default ServiceZoneComponent;
