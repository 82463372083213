import {Form} from "react-bootstrap";
import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
import Layout from '../../components/Layout';
// import zone from "../../data/zone";
import {BASE_URL} from "../../BaseUrl";
import { useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from "react";
// import {CKEditor} from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import {categorySchema} from "../../schemas/category";
import {useFormik} from "formik";
import {createCategory, successConfirmation} from '../../reducers/CategorySlice'
import {fetchServices} from "../../reducers/ServiceItemSlice";

import LoadingComponent from "../../modal/LoadingComponent";
import ErrorComponent from "../../modal/ErrorComponent";
import SuccessComponent from "../../modal/SuccessComponent";

const CategoryAddForm = ({serviceItemTextId, categoryTextId, close, newItemAddStatus}) => {

    // const [breadcrumb, setBreadcrumb] = useState(false);
    // const [itemAddStatus, setItemAddStatus] = useState(false);
    // const fetchCategoryData = useSelector((state) => state.category)
    const fetchServiceData = useSelector((state) => state.service)
    const dispatch = useDispatch();
    // const [editorData, setEditorData] = useState('');
    const [thumbIcon, setIcon] = useState(false);
    const [thumbImage, setImage] = useState(false);
    const {item, category} = useParams();
    const navigate = useNavigate();

    const [successModal, setSuccessModal] = useState(false);
    const handleSuccess = () => dispatch(successConfirmation());
    const openSuccessModal = (url) => setSuccessModal(url ? url : false);
    const closeSuccessModal = (url) => {
        handleSuccess()
        console.log('successModal', successModal)
        navigate(successModal);
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const imageType = /^image\//;
        if (imageType.test(file.type)) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setImage(e.target.result);
            };

            reader.readAsDataURL(file);
        } else {
            alert('Please select an image file.');
        }
    };
    const handleIconChange = (event) => {
        const file = event.target.files[0];
        const imageType = /^image\//;
        if (imageType.test(file.type)) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setIcon(e.target.result);
            };

            reader.readAsDataURL(file);
        } else {
            alert('Please select an image file.');
        }
    };


    // const handleChangea = (event, editor) => {
    //     const details = editor.getData();
    //     setEditorData(details);
    //     setValues({...values, itemDescription: details})
    // };
    const {values, errors, handleBlur, handleChange, handleSubmit, setValues} = useFormik({

        initialValues: {
            serviceItemTextId: item,
            categoryTextId: category,
            title: null,
            rank: null,
            status: null,
            itemDescription: null,
            image: null,
            icon: null,
        },

        onSubmit: (values, action) => {

            const res = dispatch(createCategory(values));
            res.then(results => {
                results.payload && openSuccessModal(`/service/${item}/${category}`)
            }).catch(error => {
                alert('error ---- false')
            });

        },
        //  validationSchema: categorySchema,

    });
    // console.log("🚀 values:", values)

    useEffect(() => {
        dispatch(fetchServices(`${category}`))
        document.title = ''
        // dispatch(breadcrumbTree({
        //     'Add New Category ': 'service',
        //     // [title]: category
        // }));

    }, [dispatch, category])
    return (
        <><Layout component={<>
            <Form onSubmit={handleSubmit} encType="multipart/form-data" className="form-data">
                <div className="section-two-column add-service">

                    <div className="left-block w100-200">


                        <div className="field-row"><label className="label">Category Name</label>
                            <Form.Control type="text" name="title" placeholder="Category Name" value={values.title}
                                          onChange={handleChange} onBlur={handleBlur}></Form.Control>
                            {errors.title && <p className="red">{errors.title}</p>}
                            {/* <Form.Control type="hidden" name="categoryTextId" value={data.categoryTextId} onChange={handleChange} onBlur={handleBlur}></Form.Control> */}
                        </div>

                        <div className="field-row"><label className="label">Category Description</label>
                            <textarea className="form-control description" name="itemDescription"
                                      value={values.itemDescription} onChange={handleChange} rows="8"
                                      cols="50"></textarea>
                            {/*<CKEditor editor={ClassicEditor} details={editorData} onChange={handleChangea}  />*/}

                        </div>

                    </div>
                    <div className="right-block w100-200">
                        <div className="category">
                            <strong className="title">Category Image</strong>
                            
                              {!thumbImage && !values.image &&<span className="info">Add image for the Category </span>}

                            <div className="img-view" style={{backgroundImage: `url(${thumbImage?thumbImage:(`${BASE_URL}${values.image}`)})`}}> 
                                <label id="drop-area" className="drop-image">
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                     
                                <input type="file" hidden name="image" onChange={(e) => {
                                    handleImageChange(e);
                                    setValues({...values, image: e.target.files[0],});
                                    return handleChange
                                }} accept="image/*"/>
                                </label> 
                                    
                                {!thumbImage && !values.image && <>
                                    <div className="canvas"><img src={uploadImgIcon} alt=""/></div>
                                    <span className="drag-drop">Drop image here, or browse</span><br />
                                    <span className="support-image">Support: JPG, JPEG, PNG</span>
                                </>}


                            </div>


                        </div>

                        {/* <div className="category">
                            <strong className="title">Category Icon</strong>
                             {!thumbImage && !values.image &&<span className="info">Add Icon for the  Category</span>}
                            <label id="drop-area">
                               
                                <div className="field-row">
                                    <input type="file" hidden name="image" onChange={(e) => {
                                        handleIconChange(e);
                                        setValues({...values, icon: e.target.files[0],});
                                        return handleChange
                                    }} accept="image/*"/>
                                    <div className="canvas"><img src={thumbIcon ? thumbIcon : uploadImgIcon} alt=""/>
                                    </div>

                                    
                                    {!thumbImage && !values.icon && <>
                                        <div className="canvas"><img src={uploadImgIcon} alt=""/></div>
                                        <span className="drag-drop">Drop image here, or browse</span><br />
                                        <span className="support-image">Support: JPG, JPEG, PNG</span>
                                    </>}
                                </div>
                            </label>
                        </div> */}

                        <div className="category">
                            <strong className="title">Category icon</strong>
                            
                              {!thumbIcon && !values.icon &&<span className="info">Add image for the icon </span>}

                            <div className="img-view" style={{backgroundImage: `url(${thumbIcon?thumbIcon:(`${BASE_URL}${values.icon}`)})`}}> 
                                <label id="drop-area" className="drop-image">
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                     
                                <input type="file" hidden name="image" onChange={(e) => {
                                    handleIconChange(e);
                                    setValues({...values, icon: e.target.files[0],});
                                    return handleChange
                                }} accept="image/*"/>
                                </label> 
                                    
                                {!thumbIcon && !values.icon && <>
                                    <div className="canvas"><img src={uploadImgIcon} alt=""/></div>
                                    <span className="drag-drop">Drop image here, or browse</span><br />
                                    <span className="support-image">Support: JPG, JPEG, PNG</span>
                                </>}

                            </div>


                        </div>
                    </div>

                    



                    <div className="save-option">
                        <button><span className="draft-icon">
              <img src={uploadImgIcon} alt=""/>
              </span>Save as Draft
                        </button>
                        <button className="save-btn">Save</button>
                    </div>

                </div>
            </Form>
            {fetchServiceData.loading && <LoadingComponent message={''}/>}
            {fetchServiceData.error && <ErrorComponent close={handleSuccess}/>}
            {successModal && <SuccessComponent close={closeSuccessModal}/>}
        </>
        }></Layout>
        </>
    );
};

export default CategoryAddForm;
