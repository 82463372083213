import {Form } from "react-bootstrap";
 
import uploadImgIcon from "../../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
 
import {BASE_URL} from "../../../BaseUrl";
import { useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch,  } from 'react-redux'
import React, {  useState} from "react";
// import {CKEditor} from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {useFormik} from "formik";
 
import { createService} from "../../../reducers/ServiceItemSlice";
import { successConfirmation} from '../../../reducers/CategorySlice';
import ServicePlanAddComponent from "../../../component/ServicePlanAddComponent";
import LoadingComponent from "../../../modal/LoadingComponent";
import ErrorComponent from "../../../modal/ErrorComponent";
import SuccessComponent from "../../../modal/SuccessComponent";
import SelectServiceItemComponent from "../../../component/SelectServiceItemComponent";


const EditServiceForm = ({initialData, configuration_data, loading, error, serviceDetails}) => { 
    // const [breadcrumb, setBreadcrumb] = useState(false);
    const { state } = useLocation();
    const [activePlan, setActivePlan] = useState(state.pricingBy);
    // const [servicePlan, setServicePlan] = useState([]);
    const [selected, setSelected] = useState();
    const dispatch = useDispatch();
    // const [editorData, setEditorData] = useState('');
    // const [thumbIcon, setIcon] = useState(false);
    const [thumbImage, setImage] = useState(false);
   

    // const createNewItem = (planName, details) => {
    //     return { planName, details };
    // };

    // const addServicePlanItem = (planName, details) => {
    //     const newItem = createNewItem(planName, details);
    //     setServicePlan((prevServicePlan) => [...prevServicePlan, newItem]);
    // };


    //  {serviceDetails && setSelected(serviceDetails.service_data.areaSizeUnit)}

    // let location = useLocation();
    // console.log('serviceDetails', serviceDetails)

   
    // const loca = location.pathname.split("/");
    // const token = localStorage.getItem("token")
    const {item, service = 'root'} = useParams();
    const navigate = useNavigate();
    const [clickCount, setClickCount] = useState(1);

    const [successModal, setSuccessModal] = useState(false);
    const handleSuccess = ()=>dispatch(successConfirmation());
    // const handleServicePlan = (e)=>{
    //     if(e.target.checked) {
    //         console.log('if section',e.target.checked)
    //     }else{
    //         console.log('else section',e.target.checked)
      
            
    //     }
    //     setServicePlan((prevServicePlan) => [...prevServicePlan, e.target.value]);
    //     setValues({
    //                 ...values,  service_plan: servicePlan
                     
    //             });
    //             console.log('eeeeee', servicePlan, e.target.value)
    //             // return handleChange()
    // };
    const openSuccessModal = (url) => setSuccessModal(url ? url : false);
    const closeSuccessModal = (url) => {
        handleSuccess()
        // console.log('successModal', successModal)
        navigate(successModal);
    };
    // console.log('item:', item)
    // console.log('service:', service)

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const imageType = /^image\//;
        if (imageType.test(file.type)) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setImage(e.target.result);
            };

            reader.readAsDataURL(file);
        } else {
            alert('Please select an image file.');
        }
    };
    // const  data = {...state, areaSizeUnit: serviceDetails.service_data.areaSizeUnit,
    //     // sub_items: serviceDetails.service_data.areaSizeUnit,
    // };

//  const oldData= { ...serviceDetails}
//  console.log(oldData)

    const {values, errors,  handleChange, handleSubmit, setValues} = useFormik({

        // initialValues:initialData,
             initialValues: {
            serviceItemTextId: item,
            categoryTextId: service,
            title: serviceDetails.service_data.title,
            rank: null,
            status: null,
            shortDescription: serviceDetails.service_data.shortDescription,
            service_plan: null,
            sub_items: null,
            pricing_by: 'perUnit',
            image: serviceDetails.service_data.image,
            areaSizeUnit: null,
        },
       

        onSubmit: async (values, action) => {

            const res = dispatch(createService(values));
            res.then((results)=> {
                openSuccessModal(`/service/${service ? `${item}/${service}` : ''}`);
            }).catch(error => {
                console.error('Error:', error);
            });

        },
        //  validationSchema: categorySchema,

    });
    console.log("🚀 values:", values)
 

 

    return (
       
            <>

                <Form onSubmit={handleSubmit} encType="multipart/form-data" className="form-data">
                    <div className="section-two-column add-service">
                        <div className="left-block">


                            <div className="field-row"><label className="label">Service Name</label>
                                <Form.Control type="text" name="title" placeholder="Service Name" value={values.title}
                                              onChange={handleChange}></Form.Control>
                                {errors.title && <p className="red">{errors.title}</p>}
                            </div>

                            <div className="field-row"><label className="label">Item Description</label>
                                <textarea className="form-control description" name="shortDescription"
                                          value={values.shortDescription}
                                          onChange={handleChange} rows="4" cols="50"></textarea>
                            </div>


                            <div className="pricing-tab">
                           
                                <div className="form-item">
                                    <div className="input-area">
                                        <strong className="field-label">Pricing By</strong>
                                        <div className="form-group tabs-title">
                                            <label htmlFor='perUnit'
                                                   className={` form-check-inline ${activePlan === 'perUnit' && 'active'}`}>
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    id="perUnit"
                                                    checked={activePlan === 'perUnit'}
                                                    name="pricingBy"
                                                    value="perUnit"
                                                    onChange={(e) => {
                                                        setActivePlan('perUnit');
                                                        setValues({
                                                            ...values,
                                                            pricingBy: e.target.value,
                                                            areaSizeUnit: null
                                                        });
                                                    }}/>


                                                <span className="form-check-label">Per Unit</span>
                                            </label>



                                            <label htmlFor='areaSize'
                                                   className={`form-check-inline areaSize ${activePlan === 'areaSize' && 'active'}`}>
                                                <input className="form-check-input" type="radio" id='areaSize'
                                                       name='pricing_by' value='areaSize'
                                                       checked={activePlan === 'areaSize'}
                                                       onChange={(e) => {
                                                           setActivePlan('areaSize');
                                                           setValues({...values, pricing_by: e.target.value});
                                                       }}/>
                                                <span className="form-check-label">Area / Size</span>
                                            </label>

                                            <label htmlFor='bundle'
                                                   className={`form-check-inline ${activePlan === 'bundle' && 'active'}`}>

                                                <input className="form-check-input" type="radio" id='bundle'
                                                       name='pricing_by' value='bundle'
                                                       checked={activePlan === 'bundle'}
                                                       onChange={(e) => {
                                                           setActivePlan('bundle');
                                                           setValues({
                                                               ...values,
                                                               pricing_by: e.target.value,
                                                               areaSizeUnit: null
                                                           });
                                                       }}/>
                                                <span className="form-check-label">Bundle</span>
                                            </label>
                                        </div>
                                       
                                        {activePlan === 'areaSize' && <div className='size-type'>
                                            <label htmlFor='sqft' className={`form-check-inline ${selected ==='sqft' && 'selecetd'}`}>
                                                <input className="form-check-input" type="radio" id='sqft'
                                                       name='areaSizeUnit' value='sqft'
                                                       checked={selected ==='sqft'}
                                                       onChange={(e)=>{
                                                        setSelected('sqft');
                                                        setValues({
                                                               ...values,
                                                               areaSizeUnit: e.target.value
                                                           });
                                                       }}/>

                                                <span className="form-check-label">Sqft</span>
                                            </label>
                                            <label htmlFor='Sqmt' className={`form-check-inline ${selected==='Sqmt' && 'selecetd'}`}>
                                                <input className="form-check-input" type="radio" id='Sqmt'
                                                       name='areaSizeUnit' value='Sqmt'
                                                       checked={selected==='Sqmt'}
                                                       onChange={(e)=>{
                                                        setSelected('Sqmt');
                                                        setValues({
                                                               ...values,
                                                               areaSizeUnit: e.target.value
                                                           });
                                                        
                                                       }}/>
                                                <span className="form-check-label">Sqmt</span>
                                            </label>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="dragged-form-item">

                                <div className="form-item">
                                    <div className="input-area">
                                        <div className="add-new-level">
                                            <strong className="field-label">Service Plan</strong>
                                            <span className='add-new-plan' onClick={() => {
                                                setClickCount((prevCount) => prevCount + 1);
                                            }}><i className="fa fa-plus-circle"></i> Add New</span>
                                        </div>

                                        <div className="level bronze"> 
                                            {configuration_data &&
                                                configuration_data.service_plan.map((item) => {
                                                    return item.isDefault && (
                                                        <div className="service-plan" key={item.textId}>
                                                            <div className="form-group">
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="service_plan"
                                                                        id={item.textId}
                                                                        value={item.textId}
                                                                        onChange={handleChange}
                                                                    />  
                                                                    <label className="form-check-label"
                                                                           htmlFor={item.textId}>
                                                                        {item.title}
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div className="input-area">
                                                                <strong
                                                                    className="field-label desc">Description <span>*</span></strong>
                                                                <textarea
                                                                    id={item.textId}
                                                                    name={item.textId}
                                                                    placeholder="Type description here..."
                                                                    onChange={handleChange}
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    );
                                                })}

                                            {clickCount && Array.from({length: clickCount}).map((_, index) => (
                                                <ServicePlanAddComponent key={index} handleChange={handleChange} setValues={setValues} values={values} />
                                            ))}

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="right-block">
                            <div className="items-category">
                                <div className='title'>Select Service Items</div>
                                <div className="form-item category-items">

                                    {serviceDetails && configuration_data.sub_items && configuration_data.sub_items.map((item, index) => (
                                        <>
                                            <SelectServiceItemComponent item={item} checked={serviceDetails.itemArray[index]} index={index} handleChange={handleChange}/>
                                             
                                        </>
                                    ))}
                                </div>
                            </div>

                            <div className="category">
                                <strong className="title">Service Item Image</strong>
                                {!thumbImage && !values.image &&<span className="info">Add image for the service item</span>}
                                
                                <div className="img-view" style={{backgroundImage: `url(${thumbImage?thumbImage:(`${BASE_URL}${values.image}`)})`}}> 
                                {/* <div className="img-view" style={{backgroundImage: `url(${thumbImage?thumbImage:values.image?(`${BASE_URL}${values.image}`): uploadImgIcon})`}}>  */}
                                    <label id="drop-area" className="drop-image">
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                        
                                    <input type="file" hidden name="image" onChange={(e) => {
                                        handleImageChange(e);
                                        setValues({...values, image: e.target.files[0],});
                                        return handleChange
                                    }} accept="image/*"/>
                                    </label>

                                    
                                        {!thumbImage && !values.image && <><div className="canvas"><img src={uploadImgIcon} alt="thumbImage"/></div>
                                            <span className="drag-drop">Drop image here, or browse</span><br />
                                        <span className="support-image">Support: JPG, JPEG, PNG</span></>}
                                    
                            </div>
                       
                        </div>

                            {/* <div className="category">
                                <strong className="title">Service Image</strong>
                                <span className="info">Add image for the service </span>
                                <label id="drop-area">
                                    <input type="file" hidden name="image" onChange={(e) => {
                                        handleImageChange(e);
                                        setValues({...values, image: e.target.files[0],});
                                        return handleChange
                                    }} accept="image/*"/>
                                    <div className="img-view">

                                        {state.categoryTextId && !thumbImage ?<div className="canvas"><img src={values.image?(`${BASE_URL}${values.image}`): uploadImgIcon} alt=""/></div>:
                                            <div className="canvas"><img src={thumbImage ? thumbImage : uploadImgIcon} alt=""/></div>}
                                        

                                        <span className="drag-drop">Drop image here, or browse</span>
                                        <span className="support-image">Support: JPG, JPEG, PNG</span>
                                    </div>
                                </label>

                            </div> */}


                        </div>


                        <div className="save-option">
                            <button><span className="draft-icon"> <img src={uploadImgIcon} alt=""/> </span>Save as Draft</button>
                            <button className="save-btn">Save</button>
                        </div>

                    </div>
                </Form>
                {loading && <LoadingComponent message={''}/>}
                {error && <ErrorComponent close={handleSuccess}/>}
                {successModal && <SuccessComponent close={closeSuccessModal}/>}
            </>

    );
};

export default EditServiceForm;
