import React, {useEffect, useState} from "react";
import { Form } from "react-bootstrap";
import * as moment from 'moment';
// import CityForm from "./CityForm";
// import ManageZipForm from "./ManageZipForm";
import {useDispatch, useSelector} from "react-redux";
// import { citySchema } from "../../schemas/city";
// import { userSchema } from "./schemas/city";

import {fetchOrder } from "../../reducers/OrderSlice";

import OrderListComponent from "../../component/order/OrderListComponent";
import Layout from "../../components/Layout";
// import {Link} from "react-router-dom";

import { getUniqueData } from "../../utility/FilterDataTable";
// import ExportFile from "../../component/utility-component/ExportFile";
// import ReviewsDataTable from "../../dataTables/ReviewssDataTable";
// import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
// import DatePicker from "react-datepicker";
import { DateRangePicker } from 'react-date-range';
// import NotDataFound from "../../components/NotDataFound";
 

const OrderListPage = () => {
    // const [show, setShow] = useState(false);
    // const [zipShow, setZipShow] = useState(false);
    // const [query, setQuery] = useState('');
    // const [searchQuery, setSearchQuery] = useState('');
    const [filterBy, setFilterBy] = useState('');
    const [filterByProdider, setFilterByProdider] = useState('');
    // const [filterData, setFilterData] = useState('Order Date');
    const [startDate, setStartDate] = useState(false);
    const [endDate, setEndDate] = useState(false);
    // const [alertSuccess, setAlertSuccess] = useState(false);
    const [selectedDate, setSelectedDate] = React.useState(false);
    const [activeCalender, setActiveCalender] = React.useState(false);
    // const handleShow = () => setShow(true);
    const today = new Date()

    const year = today.getFullYear();
    const month = today.getMonth(); // No adjustment needed here

    const day = String(today.getDate()).padStart(2, '0'); // Ensure two-digit day
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthName = monthNames[month];

    const handleSelect=(date) =>{
        setStartDate(date.selection.startDate)
        setEndDate(date.selection.endDate)
        // setSelectedDate(`${startDate}-${endDate}`)
        setSelectedDate(`${moment(new Date(date.selection.startDate)).format("DD MMM YYYY")} - ${moment(new Date(date.selection.endDate)).format("DD MMM YYYY")}`)
        // setActiveCalender(false)
 

    }
    const selectionRange = {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: 'selection',
    }

    // const searchColumn = [
    //     {"name": "Order Date", "code": "orderDate"},
    //     {"name": "EndUser TextId", "code": "endUserTextId"},
    //     {"name": "TextId", "code": "textId"},
    //     // {"name": "Order Amount", "code": "amount"},
    //     // {"name": "Completed Time", "code": "completedTime"},
    //     {"name": "Order status", "code": "status"}
    // ]


    const { order } = useSelector((state) => state.order);
    const dispatch = useDispatch();
  
 

    // const handleClose = () => { setShow(false) setCityData(() => ({ update: false }))};

//  if(typeof success!='undefined' && success===true) {
//   setTimeout(dispatch(removeSuccessMessage(false)), 800);
//   }
    let filteredData =order;
    if(selectedDate){
    // order = order.filter(item=>item.textId.toLowerCase().includes(query.toLowerCase()))
        filteredData= order.filter(item => {
        // Parse both start and end date strings (assuming UTC format)
        const startDateObj = new Date(startDate + " UTC");
        const endDateObj = new Date(endDate + " UTC");

        // Parse the order date string from the item
        const orderDateObj = new Date(item.orderDate);

        // Check if order date is within the start and end date range (inclusive)
        return orderDateObj >= startDateObj && orderDateObj <= endDateObj;
    });
    }


    // order = order.filter(item=>item.textId.toLowerCase().includes(query.toLowerCase()))
    let filteredProvider = filteredData.filter(item => item['workerName']?.toLowerCase().includes(filterByProdider.toLowerCase()))
    let uniqueData = filteredProvider.filter(item => item['status']?.toLowerCase().includes(filterBy.toLowerCase()))
 

    // const uniqueData = tableData2;
  
    // const uniqueData = order

    useEffect(() => {
        const res = dispatch(fetchOrder());
        res.then((results) => {

        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
        setStartDate(`${year}-${monthName}-${day}`)
        setEndDate(`${year}-${monthName}-${day}`)
        // setSelectedDate((` ${day} ${monthName} ${year}`))
        // dispatch(breadcrumbTree({ 'Order Management': ''}));
    }, [dispatch, day, monthName,year]);




 


// Remove duplicates

    return (
        <Layout 
            page_title={'Order Management'} 
            breadcrumb={{'Order Management':''}} 
            component={
                <>
                    <OrderListComponent data={uniqueData} />
                </>
            } 
            top_content={
                <div className="search-header-area">
                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                
                    <div
                         className="w-[350px] ">
                            <div className="w-full px-[15px] py-[10px] m-[10px] bg-white rounded-[6px] border border-gray-200 justify-between items-center inline-flex cursor-pointer" onClick={()=>setActiveCalender(true)}>
                                <div className="">{selectedDate || activeCalender?selectedDate:` ${day} ${monthName} ${year}`} </div>
                                <div className="w-4 h-4 ml-[1px] relative"><i className="fa fa-calendar" aria-hidden="true"></i></div>
                            </div>
                         {activeCalender &&
                        // <Calendar className='absolute top-[40px] z-2 border-1'
                        //           ranges={[selectionRange]}
                        //     onChange={handleSelect}
                        // />
                        <div className='relative date-wrapper'>
                            <div className='offset-layer ' onClick={()=> setActiveCalender(false)}></div>
                        <DateRangePicker  
                                         ranges={[selectionRange]}
                                         onChange={handleSelect}/>
                        </div>
                    }
                    </div>
                   

                    <Form.Select aria-label="Default select example" className="search-type" name="searchType"
                                 value={filterByProdider} onChange={(e) => {
                        setFilterByProdider(e.target.value);
                    }}>
                        <option value=''>Filter By Provider</option>
                        {Object.values(getUniqueData('workerName', filteredData)).map(key => (<>{key &&
                            <option key={key} value={key}>{key}</option>}</>))}

                    </Form.Select>
                    <Form.Select aria-label="Default select example" className="search-type" name="searchType"
                                 value={filterBy} onChange={(e) => {
                                    setFilterBy(e.target.value); 
                    }}>
                        <option value=''>Filter By status</option>
                        {Object.values(getUniqueData('status', filteredData)).map(key => (<>{key &&
                            <option key={key} value={key}>{key}</option>}</>))} 
                    </Form.Select>
                </div>
          
            }
        ></Layout>
    );
};

export default OrderListPage;
