// DroppableComponent.js
import React from 'react';
import { useDrop } from 'react-dnd';
import { useEffect, useState } from 'react'
import { dropElement } from '../../reducers/FormBuilderSlice';
import {areaSize} from '../../utility/Measurement'
import { useDispatch, useSelector } from 'react-redux'
import { deleteArea } from './../../reducers/AreaSlice';

const DroppableComponent = (data) => {
  const [droppedItems, setDroppedItems] = useState([]);
  const [alertMessage, setAlertMessage] = useState(false);
  const {dropitems} = useSelector((state) => state.formBuilderData)
  const dispatch = useDispatch()
  // console.log('dropitems:', dropitems)
  const [{isOver}, drop] = useDrop({
    accept: 'ROOT', // Define the same type here for compatibility
    drop: (item) => {
      item.rel = null;
      if(item.btnName==='labelField'){ 
        item.fieldset = 'fieldset';
        item.controls = [];
        item.rank = 100;
        item.textId = '';
        // item.measurement = areaSize;
        item.ungroupService = 'enable';
        item.deleteAttributeList = [];
        dispatch(dropElement(item))}
          else{
            setAlertMessage(true)
            console.log('not allow');
            setTimeout(()=>{
              setAlertMessage(false)

            },1000)
        }
      // if(item.rel==='root'){ dispatch(dropElement(item))}
      // dispatch(dropElement(item))
 
 
        setDroppedItems((prevItems) => [...prevItems, item]); 
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });
  const removeItem = (indexToRemove) => {
    setDroppedItems((prevItems) =>
      prevItems.filter((_, index) => index !== indexToRemove)
    );
  };
  return (
    <div className=''>
    <div className={`${alertMessage?'alert alert-danger':''}`}>{alertMessage && `Not Allowed!!`}</div>
    <div ref={drop} className='drop-area'> 
     {isOver ? 'Drop here' : 'Drag items here'}
     
     
    </div></div>
  );
};

export default DroppableComponent;
