import React, {useState} from 'react';
import {Card, Button} from 'react-bootstrap';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {BASE_URL} from "../../BaseUrl";
import {itemRankUpdateApi, serviceItemUpdateStatus, updateItemStatus, deleteRootItem, fetchServiceItem , fetchServiceSubItem } from "../../reducers/CategorySlice";
import {useDispatch} from "react-redux";
import SuccessComponent from "../../modal/SuccessComponent";
import {getStatusName} from '../../utility/GetStatusName';

 

const CardNavigationComponent = ({item, editUrl, viewUrl, index, edit, configurationUrl, root}) => {
    const [showCardNavigation, setShowCardNavigation] = useState(false);
    let {state} = useLocation();
    const dispatch = useDispatch();
    const [successModal, setSuccessModal] = useState(false);
    const navigate = useNavigate();

    console.log('param::: ',item)
    const handleRankUpdate = (categoryId, rank) => {
        if (rank && categoryId) {
            // console.log('categoryTextId:', categoryId, 'rank:', rank, state)
            const res =dispatch(itemRankUpdateApi({textId:categoryId, rank: rank}))
            res.then((results) => {   
            if(!results.error){
                if(item.attributeGroupTextId ==='root'){
                    dispatch(fetchServiceItem())
                }
                else{ dispatch(fetchServiceSubItem(item.attributeGroupTextId))}
            }
        }).catch((error) => {
            console.error('Promise rejected:', error);
        });

        }
    }
    const handleStatus = (id, status) => {
        // console.log(id, status)
        setShowCardNavigation(false)
        
        const res=dispatch(serviceItemUpdateStatus({textId: id, status: status}))
        res.then((results) => {   
            setSuccessModal(true)  
            dispatch(updateItemStatus({arr: root, index: index, status:status}))
        })
            res.catch((error) => {
            console.error('Promise rejected:', error);
        });
    }
    const closeSuccessModal = (url) => {
        setSuccessModal(false)
    };
 
    

    return (
        <>
            <div className="card"> 
                {item.isShowInAppTop==='Y'&&<span className="is-app-in-top"><i className="fa fa-check" aria-hidden="true"></i></span>}
                <Link className='link' to={viewUrl}><img
                    src={item.image && `${BASE_URL}${item.image}`} className='icon' alt=''/> 
                    <span className='title'>{item.title}</span>
                </Link>


                <span className="fa-solid fa-ellipsis-vertical status-action-btn"
                      onClick={() => setShowCardNavigation(!showCardNavigation)}>
                </span>
                <hr/>
                
                <div className={`status-text ${item.status}`}>{getStatusName(item.status)} </div>
                
                <input type='number' className='qty'
                       onBlur={(e) => handleRankUpdate(item.textId, e.target.value)}
                       defaultValue={item.rank}/>

                {showCardNavigation && <>

                    <div className="card-nav">
                        {/* <div className="item view"><Link to={viewUrl}>
                            <i className="fa-solid fa-circle-question"></i> <strong>View
                            Details</strong></Link>
                        </div> */}
                         <div className="item edit" onClick={() => {
                       
                            navigate(editUrl , {
                                state: item
                            })
                        }}><i className="fa-solid fa-pen"></i> <strong>Edit Details</strong></div>
                        
                        <div className="item edit" onClick={() => {
                            navigate(configurationUrl , {  state: item })
                        }}><i className="fa-solid fa-pen"></i> <strong>{item.status==='N'? 'Add':'Edit'} Configuration</strong></div>

                       

                        {/* {item.status!=='D' && item.status!=='N' &&<div className="item draft" onClick={() => handleStatus(item.textId, 'D')}><i
                            className="fa-sharp fa-solid fa-envelope-open"></i>
                            <strong>Draft</strong></div>} */}

                        {item.status==='Y'&&<div className="item pause" onClick={() => handleStatus(item.textId, 'P')}><i
                            className="fa-solid fa-pause"></i> <strong>Pause</strong></div>}

                        {item.status==='P'&&<div className="item unpause" onClick={() => handleStatus(item.textId, 'Y')}><i
                            className="fa-solid fa-pause"></i> <strong>Unpause</strong>
                        </div>}
                         <div className="item unpause" 
                        onClick={() => {
                        const approval = window.confirm("Are you sure you want to delete?")
                        if (approval === true){
                            dispatch(deleteRootItem(item.textId))
                            window.location.reload()
                        }
                        }}><i className="fa fa-trash" aria-hidden="true"></i> <strong>Delete Item </strong>
                        </div>

                        {/* <Button variant="danger" onClick={() => {
          const approval = window.confirm("Are you sure you want to delete?")
          if (approval === true){
            dispatch(deletePermission(row.id))
            window.location.reload()
          }
        }}>Delete</Button> */}
                    </div>

                </>
                }
            </div>

            {showCardNavigation &&
                <div className="modal-bg" onClick={() => setShowCardNavigation(!showCardNavigation)}></div>}
            {successModal && <SuccessComponent close={closeSuccessModal}/>}
        </>

    );
};
export default CardNavigationComponent;
