import { configureStore } from "@reduxjs/toolkit";
import ServiceReducer from "../reducers/ServiceSlice";
import authReducer from "../reducers/AuthSlice";
import areaReducer from "../reducers/AreaSlice";
import serviceItemReducer from "../reducers/ServiceItemSlice";
import categoryReducer from "../reducers/CategorySlice";
import cityReducer from "../reducers/CitySlice";
import cityZipReducer from "../reducers/CityZipSlice";
import teamMemberListReducer from "../reducers/TeamMemberSlice";
import pendingTeamMemberListReducer from "../reducers/PendingTeamMemberSlice";
import preApprovedTeamMemberListReducer from "../reducers/PreApprovedTeamMemberSlice";
import approvedTeamMemberListReducer from "../reducers/ApprovedTeamMemberSlice";
import adminModuleReducer from "../reducers/AdminModule";
import adminRoleReducer from "../reducers/AdminRole";
import adminPermissionReducer from "../reducers/AdminPermission";
import userReducer from "../reducers/UserSlice";
import orderReducer from "../reducers/OrderSlice";
import serviceCustomizationReducer from "../reducers/ServiceCustomizationSlice";
import formControlReducer from "../reducers/FormControlSlice";
// import FieldsetBuilderReducer from "../reducers/FieldsetBuilderSlice";
import categoryServiceReducer from "../reducers/CategoryServiceSlice";
import franchiseReducer from "../reducers/FranchiseSlice";
import AttributeReducer from "../reducers/AttributeSlice";
import AttributeGroupReducer from "../reducers/AttributeGroupSlice";
import FormBuilderReducer from "../reducers/FormBuilderSlice";
import LocalDataReducer from "../reducers/LocalDataSlice";
import NavDataReducer from "../reducers/NavDataSlice";
import nurReducer from "../reducers/NurSlice";
import ServiceRequestReducer from "../reducers/ServiceRequestSlice";
import CategoryRequestReducer from "../reducers/CategoryRequestSlice";
import AreaRequestReducer from "../reducers/AreaRequestSlice";
import DashboardReducer from "../reducers/DashboardSlice";
import CustomersReducer from "../reducers/CustomersSlice";
import NotificationReducer from "../reducers/NotificationSlice";
import FaqReducer from "../reducers/FaqSlice";
import IdentityReducer from "../reducers/IdentitySlice";


export default configureStore({
  reducer: {
    auth: authReducer,
    service: ServiceReducer,
    serviceItem: serviceItemReducer,
    area: areaReducer,
    category: categoryReducer,
    city: cityReducer,
    cityZip: cityZipReducer,
    teamMembers: teamMemberListReducer,
    pendingTeamMembers: pendingTeamMemberListReducer,
    preApprovedTeamMembers: preApprovedTeamMemberListReducer,
    approvedTeamMembers: approvedTeamMemberListReducer,
    adminModule: adminModuleReducer,
    adminRole: adminRoleReducer,
    adminPermission: adminPermissionReducer,
    formControl: formControlReducer,
    serviceForm: serviceCustomizationReducer,
    categoryService: categoryServiceReducer,
    user: userReducer,
    order: orderReducer,
    // fieldsetBuilder: FieldsetBuilderReducer,
    franchise: franchiseReducer,
    attribute: AttributeReducer,
    attributeGroup: AttributeGroupReducer,
    formBuilderData: FormBuilderReducer,
    localData: LocalDataReducer,
    navData: NavDataReducer,
    serviceRequest: ServiceRequestReducer,
    categoryRequest: CategoryRequestReducer,
    areaRequest: AreaRequestReducer,
    dashboard: DashboardReducer,
    customers: CustomersReducer,
    notification: NotificationReducer,
    faq: FaqReducer,
    identity: IdentityReducer,



    nurData: nurReducer,

  },
});
