import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../BaseUrl";

const initialState = {
    loading: false,
    services: [],
    data: [],
    success: false,
    error: '',
    index: 0
} 
const url = `${BASE_URL}/service/`

const token = localStorage.getItem("token")

const config = {
 
    headers: {
        Authorization: `Token ${token}`,
        "content-type": "multipart/form-data",
        // 'Cache-Control': 'no-cache',
        // 'Pragma': 'no-cache',
        // 'Expires': '0',
    }
}
 
 


export const fetchServiceData = createAsyncThunk(
  "create/serviceData", async (formData) => { 
    return axios
    .get(`${url}${formData}/ServicesAreaManagement`, config)
    .then((response) => response.data)
  }
)

export const fetchItemWiseService = createAsyncThunk(
  "create/ItemWiseService", async (textId) => {
    console.log('fire the fetchItemWiseService ')
    return axios
    .get(`${url}serviceItemWiseService/${textId}`, config)
    .then((response) => response.data)
  }
)


 
export const updateService = createAsyncThunk(
  "update/service", async (formData) => {
    return axios
    .put(`${url}${formData.textId}`, formData, config)
    .then((response) => { 
    response.data.localData = formData.localData;
    return response.data; 
     })
  }
)

export const deleteService = createAsyncThunk(
  "delete/service", async (formData, config) => {
    return axios
    .delete(`${url}${formData.textId}`, config)
    .then((response) => { 
    // response.data.localData = formData.localData;
    return formData; 
     })
  }
)





const serviceSlice = createSlice({
    name: "service",
    initialState,
    reducers: {
        successConfirmation(state, action) {
            state.success = false;
            state.error = false;
        },
    },
    extraReducers: (builder) => {


      builder.addCase(fetchServiceData.pending, (state) => {
        state.loading = true; 
      });

      builder.addCase(fetchServiceData.fulfilled, (state, action) => {
        state.data=action.payload; 
        state.success= true
        state.loading = false;
      });
      builder.addCase(fetchServiceData.rejected, (state, action) => {
        state.loading = false;  
        state.error = action.error;
        state.success= false;
        state.data = [];
      });
      
      builder.addCase(fetchItemWiseService.pending, (state) => {
        state.loading = true; 
      });

      builder.addCase(fetchItemWiseService.fulfilled, (state, action) => {
        state.services=action.payload.service_list;
        state.success= true
        state.loading = false;
      });
      builder.addCase(fetchItemWiseService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.success= false;
        state.data = [];
      });

      builder.addCase(updateService.pending, (state) => {
        // state.loading = true;
        state.error = "";
      });
      builder.addCase(updateService.fulfilled, (state, action) => {
        state.loading = false;
        state.services[action.payload.localData] = action.payload;
        state.error = "";
        state.success= true 
      });

    builder.addCase(updateService.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.success= false 
    });

      builder.addCase(deleteService.fulfilled, (state, action) => {
        state.loading = false;
        state.services.splice(action.payload.localData, 1);
        state.error = ""; 
      });

    builder.addCase(deleteService.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error; 
    }); 

    },
  });

export const {
    successConfirmation
} = serviceSlice.actions;

export default serviceSlice.reducer

